import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { withRouter } from 'react-router-dom'
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'
import config from '../../config'
import { getapiAwait, postapiAwait } from '../../helper'
import { Row, Col, Button, FormGroup, Input, Label } from 'reactstrap'
import { createSubscription } from '../../views/functions/stripeF'

const SubscribeForm = ({ location, clientSecret, showMsg, rowId, setSubscribeModal, setIsSubscriptionSuccess }) => {
  // Get the lookup key for the price from the previous page redirect.
  console.log('location: ', location)
  const [priceLookupKey, setPriceLookupKey] = useState(null) //location.state.priceLookupKey
  const [name, setName] = useState('Jenny Rosen')
  const [messages, _setMessages] = useState('')
  const [subscription, setSubscription] = useState()

  const user = reactLocalStorage.getObject('user')

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`)
  }

  React.useEffect(() => {})

  // Initialize an instance of stripe.
  const stripe = useStripe()
  const elements = useElements()

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return ''
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault()

    const cardElement = elements.getElement(CardElement)
    console.log('clientSecret: ', clientSecret)
    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      },
    })

    if (error) {
      // show error and collect new card details.
      setMessage(error.message)
      showMsg(error.message, 'warning')
      return
    }
    // setPaymentIntent(paymentIntent)
    console.log('payment INtent: ', paymentIntent)

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      const updatedInfo = await postapiAwait('stripescustom/setsubscriptionsuccess', { row_id: rowId })
      console.log('updatedInfo: ', updatedInfo)
      showMsg('Subscription created successfully.', 'success')
      setSubscribeModal(false)
      localStorage.setItem('tracker_payment', JSON.stringify({ status: 'subscription_success' }))
      setIsSubscriptionSuccess(true)
    } else {
      showMsg('Something wrong. Please try again.', 'danger')
    }

    // if (subError) {
    //   // show error and collect new card details.
    //   console.log('subError: ', subError)
    //   setMessage(subError.message)
    //   return
    // }
    // console.log('subscription: ', subscription)
    // setMessage(`Subscription created with status: ${subscription.status}`)
    // setSubscription(subscription)

    // This sample only supports a Subscription with payment
    // upfront. If you offer a trial on your subscription, then
    // instead of confirming the subscription's latest_invoice's
    // payment_intent. You'll use stripe.confirmCardSetup to confirm
    // the subscription's pending_setup_intent.
    // switch (subscription.status) {
    //   case 'active':
    //     // Redirect to account page
    //     setMessage('Success! Redirecting to your account.')
    //     break

    //   case 'incomplete':
    //     setMessage('Please confirm the payment.')

    //     // Handle next actions
    //     //
    //     // If the status of the subscription is `incomplete` that means
    //     // there are some further actions required by the customer. In
    //     // the case of upfront payment (not trial) the payment is confirmed
    //     // by passing the client_secret of the subscription's latest_invoice's
    //     // payment_intent.
    //     //
    //     // For trials, this works a little differently and requires a call to
    //     // `stripe.confirmCardSetup` and passing the subscription's
    //     // pending_setup_intent's client_secret like so:
    //     //
    //     //   const {error, setupIntent} = await stripe.confirmCardSetup(
    //     //     subscription.pending_setup_intent.client_secret
    //     //   )
    //     //
    //     // then handling the resulting error and setupIntent as we do below.
    //     //subscriptionId && clientSecret &&
    //     // This sample does not support subscriptions with trials. Instead, use these docs:
    //     // https://stripe.com/docs/billing/subscriptions/trials
    //     const { error } = await stripe.confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret)

    //     if (error) {
    //       setMessage(error.message)
    //     } else {
    //       setMessage('Success! Redirecting to your account.')
    //       setSubscription({ status: 'active' })
    //     }
    //     break

    //   default:
    //     setMessage(`Unknown Subscription status: ${subscription.status}`)
    // }
  }

  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label>Full Name</Label>
            <Input
              type='text'
              value={name}
              style={{
                width: '100%',
                color: '#32325d',
                borderColor: 'hsl(0,0%,80%)',
              }}
              onChange={({ target: { value } }) => {
                setName(value)
              }}
              min={1}
            />
            <CardElement className='tracker-stripe-card' />
            <Button color='secondary' onClick={handleSubmit} disabled={!clientSecret}>
              Submit
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>{messages}</Col>
      </Row>
    </>
  )
}

const Subscribe = (props) => {
  let stripePromise = null
  const [publishableKey, setPublishableKey] = useState(config.stripe_publishable_key)
  const [stripe_acct_id, set_stripe_acct_id] = useState(null)

  if (publishableKey) {
    stripePromise = loadStripe(publishableKey)
    console.log('stripePromise: ', stripePromise)
  } else {
    setPublishableKey(config.stripe_publishable_key)
  }

  return (
    <Elements stripe={stripePromise}>
      <SubscribeForm {...props} />
    </Elements>
  )
}

export default withRouter(Subscribe)
