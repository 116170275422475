import React, { PureComponent, useEffect, useState } from 'react'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Row,
    Col,
    Tooltip,
    Spinner,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from 'reactstrap'

import NotificationAlert from 'react-notification-alert';

import { callextendedapisAwait } from './../../helper';


const ApproveDenyEstimate = ({ props }) => {

    const notificationAlertRef = React.useRef(null);
    const [loading, setLoading] = useState(false);

    const showNotificationMsg = (notifyMessage, type) => {
        let options = {}
        options = {
            place: 'tr',
            message: notifyMessage,
            type: type,
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 7,
        }
        notificationAlertRef.current.notificationAlert(options)
    }

    useEffect(async () => {


        let action = new URLSearchParams(window.location.search).get("action");
        let estimateId = new URLSearchParams(window.location.search).get("estimateId");

        console.log('props.location.search: ', action, estimateId);

        switch (action) {
            case "approve":

                showNotificationMsg('successfully approved.', 'success');
                break;

            case "deny":

                showNotificationMsg('successfully denied.', 'success');
                break;
        }




        const resp = await callextendedapisAwait('estimatescustom/approveDisapprove/', { id: estimateId, action: action });
        console.log("async componentDidMount(id) {->resp.data: ", resp.data);

        // setTimeout(() => {
        //     window.location.replace(window.location.host);
        // }, 1000);

    });


    return (
        <>
            {loading && <Spinner color='light' />}
            {!loading && (

                <NotificationAlert ref={notificationAlertRef} />
            )}
        </>
    )
}

export default ApproveDenyEstimate;