import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
} from 'reactstrap'
import Select from 'react-select'
import ReactTable from 'components/ReactTable/ReactTable.js'
import axios from 'axios'

import { callapi, postapi, callextendedapisAwait, patchApiAwait, insertActivityLog, postapiAwait } from '../../helper.js'
import config from '../../config.js'
import { Spinner } from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import { reactLocalStorage } from 'reactjs-localstorage'

import { initStripe, handlePaymentForDIDReservation } from '../functions/stripeF'

export default function DirectInwardDialing(props) {
  const [countryLoading, set_countryLoading] = useState(true)
  const [regionLoading, set_regionLoading] = useState(false)
  const [cityLoading, set_cityLoading] = useState(false)
  const [didLoading, set_didLoading] = useState(false)
  const [notPaidTelephonyId, set_notPaidTelephonyId] = useState(null)
  const [stripeAccId, set_stripeAccId] = useState(null)
  const [countryOptions, set_countryOptions] = useState([])
  const [country, setCountry] = useState({
    label: '',
    value: ''
  })
  const [region, setRegion] = useState({label: '', value: ''})
  const [city, setCity] = useState({ label: '', value: '' })
  const [did, setDid] = useState({ label: '', value: '' })
  const [CDRNumber, setCDRNumber] = useState({ label: '', value: '' })

  const [regionOptions, setRegionOptions] = useState([{label: '', value: ''}])
  const [cityOptions, setCityOptions] = useState([{ label: '', value: '' }])
  const [didOptions, setDidOptions] = useState([{ label: '', value: '' }])
  const [CDRsNumberOptions, setCDRsNumberOptions] = useState([{ label: '', value: '' }])
  const [rawRegionData, setRawRegionData] = useState([])
  const [rawCityData, setRawCityData] = useState([])
  const [rawDidData, setRawDidData] = useState([])
  
  const notificationAlertRef = React.useRef(null)
  
  useEffect(() => {
    initStripe().then((_id) => {
      set_stripeAccId(_id)
    })
    const didNotPaid = JSON.parse(localStorage.getItem('did_not_paid'))
    if (didNotPaid) {
      set_notPaidTelephonyId(didNotPaid.telephonyId)
    }
    const urlParams = new URLSearchParams(window.location.search)
    const stripe_status = urlParams.get('action')
    if (stripe_status && didNotPaid?.telephonyId && stripe_status === didNotPaid.telephonyId) {
      updatePaidDid(didNotPaid.telephonyId)
    }
    // getAvailableCities()
    getAllCDRsNumber()
    getCountrisInfo()
  }, [])

  useEffect(() => { 
    console.log('sm raw regionData: ', rawRegionData)
    setRegionOptions(rawRegionData.map((regionItem) => ({
        label: regionItem.attributes.name,
        value: regionItem.id
      })))
  }, [rawRegionData])

  useEffect(() => { 
    setCityOptions([
      {
        value: 'all',
        label: 'All'
      },
      ...rawCityData.map((cityItem) => ({
        label: cityItem.attributes.name,
        value: cityItem.id
      }))
    ])
  }, [rawCityData])

  useEffect(() => {
    if (city.value) getAvailableDids()
  }, [city])

  useEffect(() => {
    if (region.value) getAvailableCities()
  }, [region])

  useEffect(() => {
    if (country.value) getAvailableRegion()
  }, [country])
  
  const updatePaidDid = async (telephonyId) => {
    const { data: paidUpRes } = await postapiAwait('telephony/updatepaiddid', { telephonyId })
    if (paidUpRes?.affectedRows) {
      localStorage.removeItem('did_not_paid')
      set_notPaidTelephonyId(null)
      showNotificationMsg('DID paid successfully.', 'success')
      props.history.replace('/admin/telephony')
    }
  }


  const getCountrisInfo = async () => {
    const { data: _countries } = await postapiAwait('telephony/countriescustom', {})
    const _filteredData = _countries.data
        .filter((_item) => _item.attributes.iso === 'CA' || _item.attributes.iso === 'US')
        .map((_item) => ({ value: _item.id, label: _item.attributes.name}))
    console.log('filtered countries: ', _filteredData)
    set_countryOptions(_filteredData)
    setCountry(_filteredData[0])
    set_countryLoading(false)
  }

  const getAvailableDids = async () => {
    set_didLoading(true)
    if (city.value === 'all') {
      const { data: availableDidsRes } = await postapiAwait('telephony/availabledidcustombyregion', { regionId: region.value })
      console.log('all available dids by region: ', availableDidsRes?.data)
      if (availableDidsRes?.data?.length > 0) {
        setDidOptions(availableDidsRes.data.map((didItem) => ({label: didItem.attributes.number, value: didItem.id})))
        setRawDidData(availableDidsRes.data)
      }
    } else {
      const { data: availableDidsRes } = await postapiAwait('telephony/availabledidcustom', {cityId: city.value})
      if (availableDidsRes?.data?.length > 0) {
        setDidOptions(availableDidsRes.data.map((didItem) => ({label: didItem.attributes.number, value: didItem.id})))
        setRawDidData(availableDidsRes.data)
      }
    }
    
    set_didLoading(false)
  }

  const getAvailableCities = async () => {
    set_cityLoading(true)
    const { data: citiesRes } = await postapiAwait('telephony/citiescustom', { regionId: region.value })
    console.log('sm citiesRes.data: ', citiesRes?.data)
    setRawCityData(citiesRes?.data || [])
    set_cityLoading(false)
  }

  const getAvailableRegion = async () => {
    set_regionLoading(true)
    const { data: regionsRes } = await postapiAwait('telephony/regioncustom', { countryId: country.value })
    console.log('sm regionsRes.data: ', regionsRes?.data)
    setRawRegionData(regionsRes?.data || [])
    set_regionLoading(false)
  }

  
  const createReservation = async (didNumber) => {
    const postData = {
      didId: did.value,
      userId: config.user.id,
      companyId: config.user.companyId,
      country: 'USA',
      city: city.label,
      did_number: did.label
    }
    const { data: reservationRes } = await postapiAwait('telephony/createreservationcustom', postData)
    if (reservationRes?.reservationData?.data?.id && reservationRes?.telephonyInsertData?.status === 'success') {
      localStorage.setItem('did_not_paid', JSON.stringify({
        telephonyId: reservationRes?.telephonyInsertData?.telephonyId
      }))
      set_notPaidTelephonyId(reservationRes?.telephonyInsertData?.telephonyId)
    }
  }

  const getAllCDRsNumber = async () => {
    const { data: cdrRes } = await callextendedapisAwait('telephony/getCDRExports', {})
    console.log('sm cdrRes: ', cdrRes)
  }

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (notificationAlertRef !== null && notificationAlertRef.current !== null) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }
  
  
  return (
    <div className='content'>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Row>
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            <Row>
              <Col className='pr-md-1' md='6'>
                <h3>Reserve Available DIDs</h3>
                <FormGroup>
                  <Label for='exampleSelect'>
                    Select Country <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Select
                    value={countryOptions.filter((option) => option.value === country.value )}
                    onChange={(selected) => setCountry(selected)}
                    options={countryOptions}
                    isDisabled={notPaidTelephonyId}
                    isLoading={countryLoading}
                  />
                </FormGroup>
              </Col>
              <Col className='pr-md-1' md='6'>
                <h3>Download Call Detail Records (CDRs)</h3>
                <FormGroup>
                  <Label for='exampleSelect'>
                    Select a number for CDRs <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Select
                    value={CDRsNumberOptions.filter((option) => option.value === CDRNumber.value )}
                    onChange={(selected) => setCDRNumber(selected)}
                    options={CDRsNumberOptions}
                    // isDisabled={notPaidTelephonyId}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className='pr-md-1' md='6'>
                <FormGroup>
                  <Label for='exampleSelect'>
                    Select Region
                    <span style={{ color: 'red' }}>*</span>
                    {regionLoading && <span style={{marginLeft: '0.5rem'}}><Spinner color='white' size='sm'/></span>}
                  </Label>
                  <Select
                    value={regionOptions.filter((option) => option.value === region.value )}
                    onChange={(selected) => setRegion(selected)}
                    options={regionOptions}
                    isDisabled={notPaidTelephonyId}
                    isLoading={regionLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className='pr-md-1' md='6'>
                <FormGroup>
                  <Label for='exampleSelect'>
                    Select City
                    <span style={{ color: 'red' }}>*</span>
                    {cityLoading && <span style={{marginLeft: '0.5rem'}}><Spinner color='white' size='sm'/></span>}
                  </Label>
                  <Select
                    value={cityOptions.filter((option) => option.value === city.value )}
                    onChange={(selected) => setCity(selected)}
                    options={cityOptions}
                    isDisabled={notPaidTelephonyId}
                    isLoading={cityLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className='pr-md-1' md='6'>
                <FormGroup>
                  <Label for='exampleSelect'>
                    Select DID
                    <span style={{ color: 'red' }}>*</span>
                    {didLoading && <span style={{marginLeft: '0.5rem'}}><Spinner color='white' size='sm'/></span>}
                  </Label>
                  <Select
                    value={didOptions.filter((option) => option.value === did.value )}
                    onChange={(selected) => setDid(selected)}
                    options={didOptions}
                    isDisabled={!city || !city.value || notPaidTelephonyId || didLoading}
                    isLoading={didLoading}
                  />
                  <Button
                    id='ant-btn-back'
                    type='button'
                    className={`ant-btn-primary ant-btn-back customer-back`}
                    icon='back'
                    size='default'
                    disabled={!did.value && !notPaidTelephonyId}
                    style={{marginTop: '1rem'}}
                    onClick={() => {
                      if (!notPaidTelephonyId) {
                        createReservation(did.value)
                      } else {
                        handlePaymentForDIDReservation(stripeAccId, notPaidTelephonyId, showNotificationMsg)
                      }
                      
                    }}
                  >
                    <span>{ notPaidTelephonyId ? 'Please Pay $10 for Reserved DID' : 'Reserve'}</span>
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </div>
  )
}
