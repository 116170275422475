import VectorMap from 'views/maps/VectorMap.js'
import GoogleMaps from 'views/maps/GoogleMaps.js'
import FullScreenMap from 'views/maps/FullScreenMap.js'
import ReactTables from 'views/tables/ReactTables.js'
import RegularTables from 'views/tables/RegularTables.js'
import ExtendedTables from 'views/tables/ExtendedTables.js'
import Wizard from 'views/forms/Wizard.js'
import ValidationForms from 'views/forms/ValidationForms.js'
import ExtendedForms from 'views/forms/ExtendedForms.js'
import RegularForms from 'views/forms/RegularForms.js'
import Calendar from 'views/Calendar.js'
import Widgets from 'views/Widgets.js'
import Charts from 'views/Charts.js'
import Dashboard from 'views/Dashboard.js'
import SuperAdmin from 'views/pages/SuperAdmin.js'
import Buttons from 'views/components/Buttons.js'
import SweetAlert from 'views/components/SweetAlert.js'
import Notifications from 'views/components/Notifications.js'
import Grid from 'views/components/Grid.js'
import Typography from 'views/components/Typography.js'
import Panels from 'views/components/Panels.js'
import Icons from 'views/components/Icons.js'
import Pricing from 'views/pages/Pricing.js'
import Register from 'views/pages/Register.js'
import Timeline from 'views/pages/Timeline.js'
import User from 'views/pages/User.js'
import Login from 'views/pages/Login.js'
import Rtl from 'views/pages/Rtl.js'
import Lock from 'views/pages/Lock.js'
//--
import Estimates from 'views/pages/estimates.js'
import Job from 'views/pages/Job'
import Invoice from 'views/pages/Invoice'
import Users from 'views/pages/Users'
import Customers from 'views/pages/Customers'
import Settings from 'views/pages/Settings'
import Schedules from 'views/pages/Schedules'
import CompanySetup from 'views/pages/CreateCompany'
import FleetTracking from 'views/pages/FleetTracking'
import DirectInwardDialing from 'views/pages/DirectInwardDialing'

import Profile from 'views/pages/Profile'

import { reactLocalStorage } from 'reactjs-localstorage'
import ForgotPassword from 'views/pages/ForgotPassword'
import ResetPassword from 'views/pages/ResetPassword'

const user = reactLocalStorage.getObject('user')

const routes = [
  {
    path: '/super-admin',
    name: 'Super Admin',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-laptop',
    component: SuperAdmin,
    layout: '/admin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/estimates',
    name: 'Estimates',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-notes',
    component: Estimates,
    layout: '/admin',
  },
  {
    path: '/jobs',
    name: 'Jobs',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-bullet-list-67',
    component: Job,
    layout: '/admin',
  },
  {
    path: '/invoices',
    name: 'Invoices',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-paper',
    component: Invoice,
    layout: '/admin',
  },
  {
    path: '/cutomers',
    name: 'Customers',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-single-02',
    component: Customers,
    layout: '/admin',
  },
  {
    path: '/users',
    name: 'Users',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-badge',
    component: Users,
    layout: '/admin',
  },
  {
    path: '/fleet',
    name: 'Fleet',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-bus-front-12',
    component: FleetTracking,
    layout: '/admin',
  },
  {
    path: '/telephony',
    name: 'Telephony',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-headphones',
    component: DirectInwardDialing,
    layout: '/admin',
  },
  {
    path: '/newcompanysetup',
    name: 'New Company Setup',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-single-02',
    component: CompanySetup,
    layout: '/auth',
    redirect: true,
  },
  // {
  //   path: '/companysetup',
  //   name: 'Company Setup',
  //   rtlName: 'لوحة القيادة',
  //   icon: 'tim-icons icon-single-02',
  //   component: CompanySetup,
  //   layout: '/admin',
  // },
  {
    path: '/settings',
    name: 'Settings',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-settings-gear-63',
    component: CompanySetup,
    layout: '/admin',
  },
  {
    path: '/schedule',
    name: 'Schedule',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-calendar-60',
    component: Schedules,
    layout: '/admin',
  },
  {
    path: '/login',
    name: 'Login',
    rtlName: 'هعذاتسجيل الدخول',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/register',
    name: 'Register',
    rtlName: 'تسجيل',
    component: Register,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password?',
    rtlName: 'تسجيل',
    component: ForgotPassword,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/reset-password',
    name: 'Reset Password?',
    rtlName: 'تسجيل',
    component: ResetPassword,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/myprofile',
    name: 'Profile',
    rtlName: 'تسجيل',
    component: Profile,
    layout: '/admin',
    redirect: true,
  },
  {
    path: '/stripe_return',
    name: 'StripeReturn',
    rtlName: 'تسجيل',
    component: Profile,
    layout: '/admin',
    redirect: true,
  },
  // {
  //   path: "/pricing",
  //   name: "Schedules",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-pin",
  //   component: Pricing,
  //   layout: "/admin",
  // },

  // {
  //   collapse: true,
  //   name: "Pages",
  //   rtlName: "صفحات",
  //   icon: "tim-icons icon-image-02",
  //   state: "pagesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       rtlName: "عالتسعير",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: Pricing,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       rtlName: "صودعم رتل",
  //       mini: "RS",
  //       rtlMini: "صو",
  //       component: Rtl,
  //       layout: "/rtl",
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       rtlName: "تيالجدول الزمني",
  //       mini: "T",
  //       rtlMini: "تي",
  //       component: Timeline,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       rtlName: "هعذاتسجيل الدخول",
  //       mini: "L",
  //       rtlMini: "هعذا",
  //       component: Login,
  //       layout: "/auth",
  //     },

  //     {
  //       path: "/lock-screen",
  //       name: "Lock Screen",
  //       rtlName: "اقفل الشاشة",
  //       mini: "LS",
  //       rtlMini: "هذاع",
  //       component: Lock,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/user-profile",
  //       name: "User Profile",
  //       rtlName: "ملف تعريفي للمستخدم",
  //       mini: "UP",
  //       rtlMini: "شع",
  //       component: User,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   rtlName: "المكونات",
  //   icon: "tim-icons icon-molecule-40",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Multi Level Collapse",
  //       rtlName: "انهيار متعدد المستويات",
  //       mini: "MLT",
  //       rtlMini: "ر",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           rtlName: "وصفت",
  //           mini: "B",
  //           rtlMini: "ب",
  //           component: Buttons,
  //           layout: "/admin",
  //         },
  //       ],
  //     },
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       rtlName: "وصفت",
  //       mini: "B",
  //       rtlMini: "ب",
  //       component: Buttons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       rtlName: "نظام الشبكة",
  //       mini: "GS",
  //       rtlMini: "زو",
  //       component: Grid,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       rtlName: "لوحات",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: Panels,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       rtlName: "الحلو تنبيه",
  //       mini: "SA",
  //       rtlMini: "ومن",
  //       component: SweetAlert,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       rtlName: "إخطارات",
  //       mini: "N",
  //       rtlMini: "ن",
  //       component: Notifications,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       rtlName: "الرموز",
  //       mini: "I",
  //       rtlMini: "و",
  //       component: Icons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       rtlName: "طباعة",
  //       mini: "T",
  //       rtlMini: "ر",
  //       component: Typography,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   rtlName: "إستمارات",
  //   icon: "tim-icons icon-notes",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       rtlName: "أشكال عادية",
  //       mini: "RF",
  //       rtlMini: "صو",
  //       component: RegularForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       rtlName: "نماذج موسعة",
  //       mini: "EF",
  //       rtlMini: "هوو",
  //       component: ExtendedForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       rtlName: "نماذج التحقق من الصحة",
  //       mini: "VF",
  //       rtlMini: "تو",
  //       component: ValidationForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       rtlName: "ساحر",
  //       mini: "W",
  //       rtlMini: "ث",
  //       component: Wizard,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   rtlName: "الجداول",
  //   icon: "tim-icons icon-puzzle-10",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       rtlName: "طاولات عادية",
  //       mini: "RT",
  //       rtlMini: "صر",
  //       component: RegularTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       rtlName: "جداول ممتدة",
  //       mini: "ET",
  //       rtlMini: "هور",
  //       component: ExtendedTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       rtlName: "رد فعل الطاولة",
  //       mini: "RT",
  //       rtlMini: "در",
  //       component: ReactTables,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       rtlName: "خرائط جوجل",
  //       mini: "GM",
  //       rtlMini: "زم",
  //       component: GoogleMaps,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       rtlName: "خريطة كاملة الشاشة",
  //       mini: "FSM",
  //       rtlMini: "ووم",
  //       component: FullScreenMap,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       rtlName: "خريطة المتجه",
  //       mini: "VM",
  //       rtlMini: "تم",
  //       component: VectorMap,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "الحاجيات",
  //   icon: "tim-icons icon-settings",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "الرسوم البيانية",
  //   icon: "tim-icons icon-chart-bar-32",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "التقويم",
  //   icon: "tim-icons icon-time-alarm",
  //   component: Calendar,
  //   layout: "/admin",
  // },
]

export default routes
