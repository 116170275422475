import React, { useState, useEffect, createRef } from 'react'
// import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  CardImg,
  Container,
} from 'reactstrap'
import Select from 'react-select'
import ReactTable from 'components/ReactTable/ReactTable.js'

import {
  getapiAwait,
  postapi,
  postapiAwait,
  toBase64,
  callextendedapisAwait,
} from '../../helper.js'
import config from '../../config.js'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func } from 'prop-types'
import { isConstructorDeclaration } from 'typescript'
import { reactLocalStorage } from 'reactjs-localstorage'

import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js'

import NotificationAlert from 'react-notification-alert'

import PlacesAutocomplete from 'react-places-autocomplete'
import axios from 'axios'

export default function CreateCompany({ history }) {
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyWebsiteAddress, setCompanyWebsiteAddress] = useState(null)
  const [companyEmail, setCompanyEmail] = useState(null)
  const [companyAddress, setCompanyAddress] = useState(null)
  const [companyLogo, setCompanyLogo] = useState(null)
  const [imgPreview, setImgPreview] = useState(null)
  const [modal, setModal] = useState(false)

  const [gstTaxNum, setGstTaxNum] = useState(null)
  const [gstTaxValue, setGstTaxValue] = useState(null)
  const [pstTaxNum, setPstTaxNum] = useState(null)
  const [pstTaxValue, setPstTaxValue] = useState(null)

  const [settingsId, setSettingsId] = useState('-1')

  const [stripeConnectAccountId, setStripeConnectAccountId] = useState(null)

  const [loading, setLoading] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingStripe, setLoadingStripe] = useState(false)

  const [toast, setToast] = useState(false)
  const [toastHeader, setToastHToastHeader] = useState('')
  const [toastBody, setToastBoToastBody] = useState('')

  const notificationAlertRef = React.useRef(null)

  const user = reactLocalStorage.getObject('user')

  function toogleToast(type, header = '', body = '') {
    setToast(type)
    setToastHToastHeader(header)
    setToastBoToastBody(body)
    setTimeout(() => {
      setToast(false)
    }, 2000)
  }

  async function onSaveCompany(e) {
    // event.preventDefault();

    e.preventDefault()
    console.log(
      'value is here  ',
      companyName,
      companyWebsiteAddress,
      companyEmail,
      companyAddress,
      companyLogo
    )

    let emailIsValid = validateEmail(companyEmail)

    console.log('emailIsValid: ', emailIsValid)

    if (!companyName) {
      showNotificationMsg('Please enter the company name!', 'warning')
    } else if (!companyWebsiteAddress) {
      showNotificationMsg(
        'Please enter the company website address!',
        'warning'
      )
    } else if (!companyEmail || !emailIsValid) {
      showNotificationMsg('Please enter a valid email!', 'warning')
    }
    // else if (!companyAddress) {
    //   showNotificationMsg('Please enter the company address!', 'warning')
    // }
    // else if (!companyLogo) {
    //   showNotificationMsg('Please enter the company logo!', 'warning')
    // }
    else {
      setLoadingSave(true)

      // let user = reactLocalStorage.getObject('user')

      let settingId = settingsId

      let website = companyWebsiteAddress

      let address = companyAddress
      let logo = companyLogo
      let userId =
        user.companyId === '-1'
          ? new URLSearchParams(window.location.search).get('uid')
          : user.id

      let res = null

      if (user.companyId !== '-1') {
        let postData = {
          id: user.companyId,
          userId,
          settingId,
          companyName,
          website,
          companyEmail,
          address,
          logo,
          gstTaxNum,
          gstTaxValue,
          pstTaxNum,
          pstTaxValue,
        }

        console.log('onSaveCompany->if->postData: ', postData)
        res = await postapiAwait('companyInfo/insertupdate', postData)
      } else {
        let postData = {
          userId,
          settingId,
          companyName,
          website,
          companyEmail,
          address,
          logo,
          gstTaxNum,
          gstTaxValue,
          pstTaxNum,
          pstTaxValue,
        }

        console.log('onSaveCompany->else->postData: ', postData)
        res = await postapiAwait('companyInfo/insertupdate', postData)
      }

      console.log('company insert: ', res)

      if (user.companyId === '-1') {
        showNotificationMsg('Company Setup done Successfully', 'success')

        user.companyId = res.data.companyId
        reactLocalStorage.setObject('user', user)

        history.push('/admin/dashboard')
        window.location.reload()
      } else {
        showNotificationMsg('Company Updated Successfully', 'success')
        setLoadingSave(false)
        history.push('/admin/dashboard')
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    async function getCompanyInfoById() {
      let urlParams = new URLSearchParams(window.location.search)
      let account_id = urlParams.get('account_id')

      if (account_id && account_id.length > 0) {
        let response = await postapiAwait(`stripescustom/saveaccountid`, {
          id: user.companyId,
          account_id: account_id,
        })
      }
      console.log('sm companyId: ', companyId)
      if (!companyId && user.companyId !== '-1') {
        setCompanyId(user.companyId)
      }
      if (companyId || user.companyId) {
        let resCompany = await getapiAwait(
          `companyInfocustom/getCompanyInfoById/${user.companyId || companyId}`,
          {}
        )
        console.log('sm getCompanyInfoRes: ', resCompany)
        if (resCompany.data.length > 0 && resCompany.data[0]) {
          resCompany = resCompany.data[0]

          console.log('resCompany: ', resCompany)
          setCompanyName(resCompany.companyName)
          setCompanyAddress(resCompany.address)
          setCompanyEmail(resCompany.companyEmail)
          setCompanyLogo(resCompany.logo)
          setImgPreview(resCompany.logo)

          setCompanyWebsiteAddress(resCompany.website)
          setSettingsId(resCompany.settingId)
          setStripeConnectAccountId(resCompany.stripe_acct_id)

          setGstTaxNum(resCompany.gst_tax_number)
          setGstTaxValue(resCompany.gst_tax_value)
          setPstTaxNum(resCompany.pst_tax_number)
          setPstTaxValue(resCompany.pst_tax_value)

          //                 address: "Gulshan, Dhaka"
          // companyEmail: "alamrezoanul@fluxit.tech"
          // companyName: "My Company 1"
          // created_at: "2021-05-30T01:21:58.000Z"
          // id: "3759d744-7b72-4f40-8830-b5f78b4251cb"
          // logo: "data:image/jpeg;base64,/9j/4QstRXhpZgAATU0AKgAAAA
          // settingId: "-1"
          // website: "www.fluxit.tech"
        }
      }
      if (!companyId && user.companyId === '-1') {
        setCompanyId('-1')
      }
      setLoading(false)
    }
    getCompanyInfoById()
    // console.log('history.location: ', history.location)
  }, [stripeConnectAccountId])
  const options = [
    { value: 'admin', label: 'Admin' },
    { value: 'employee', label: 'Employee' },
  ]
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }

  const handleConnectButtonClick = async () => {
    let response = await postapiAwait('stripescustom/createstandardaccount', {})
    console.log(
      'connectwithstripe->createstandardaccount->response.data: ',
      response.data
    )

    const payload = {
      account: response.data.id,
      refresh_url:
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/settings`
          : `http://${window.location.host}/admin/settings`,
      return_url:
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/settings?account_id=${response.data.id}`
          : `http://${window.location.host}/admin/settings?account_id=${response.data.id}`,
    }

    response = await postapiAwait('stripescustom/createaccountlink', payload)
    console.log(
      'connectwithstripe->createaccountlink->response.data: ',
      response.data
    )

    window.location.href = response.data.url
  }

  async function handleDisconnectStripeClick() {
    let response = await postapiAwait(`stripescustom/disconnect`, {
      id: user.companyId,
    })
    console.log(
      '`disconnectWithStripe->stripescustom/disconnect->response: ',
      response.status
    )
    if (response.status === 200) {
      setStripeConnectAccountId(null)
      showNotificationMsg('Stripe disconnected successfully.', 'info')
    } else {
      showNotificationMsg('Something wrong! Failed to Disconnect.', 'info')
    }
  }

  function toggle() {
    setModal(false)
  }

  function onOk() {
    handleDisconnectStripeClick()
    setModal(false)
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  return loading ? (
    <div className='content'>
      <Spinner color='light' />
    </div>
  ) : (
    <div className={`${companyId === '-1' ? '' : 'content'} company-setup`}>
      <div>
        <Modal isOpen={modal} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <i className='tim-icons icon-alert-circle-exc' /> Do you want to
            disconnect Stripe?
          </ModalHeader>
          <ModalBody>Your Stripe information will be deleted.</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={onOk}>
              Ok
            </Button>{' '}
            <Button
              color='secondary'
              onClick={() => {
                setModal(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container>
        {history.location.pathname === '/auth/newcompanysetup' && (
          <img
            alt=''
            src='https://ct.capterra.com/capterra_tracker.gif?vid=2192979&vkey=b73340233268c33f7bea8f8b2d99687d'
          />
        )}
        <Row
          style={{
            maxWidth: '100%',
            marginLeft: '0px',
            marginRight: '0px',
          }}
        >
          <Col>
            <Card
              style={{
                marginTop: '30px',
                paddingBottom: '20px',
              }}
            >
              <CardHeader>
                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'start',
                  }}
                >
                  {!stripeConnectAccountId && (
                    <Button
                      className='btn-fill connect-with-stripe'
                      color='primary'
                      style={{
                        backgroundImage: 'initial',
                        backgroundColor: '#ec250d',
                      }}
                      type='button'
                      onClick={async (e) => {
                        e.preventDefault()
                        setLoadingStripe(true)

                        await handleConnectButtonClick()
                      }}
                    >
                      {loadingStripe && (
                        <Spinner
                          color='white'
                          size='sm'
                          className='mr-2'
                          style={{ position: 'absolute', left: '35px' }}
                        />
                      )}
                      <span>Connect With</span>
                    </Button>
                  )}
                  {stripeConnectAccountId && stripeConnectAccountId.length > 0 && (
                    <Button
                      className='btn-fill'
                      color='primary'
                      style={{
                        backgroundImage: 'initial',
                        backgroundColor: '#ec250d',
                      }}
                      type='button'
                      onClick={(e) => {
                        e.preventDefault()
                        setModal(true)
                      }}
                    >
                      <span>Disconnect Stripe</span>
                    </Button>
                  )}
                </div>
                <h3
                  className='title'
                  style={{ marginTop: 30, textAlign: 'center' }}
                >
                  Setup your Company
                </h3>
              </CardHeader>
              <CardBody style={{ margin: '0 40px' }}>
                <Form>
                  <Row>
                    <Col className='pr-md-1' md={6}>
                      <FormGroup>
                        <label>Company Name</label>
                        <Input
                          // ref={(_ref) => refCompanyName = _ref}
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          placeholder='Company Name'
                          // defaultValue="Mike"
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md={6}>
                      <FormGroup>
                        <label>Website Address</label>
                        <Input
                          id='inp_company_website_address'
                          value={companyWebsiteAddress}
                          onChange={(e) =>
                            setCompanyWebsiteAddress(e.target.value)
                          }
                          placeholder='http(s)://www.xyz.com'
                          // defaultValue="Mike"
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md={6}>
                      <FormGroup>
                        <label>Company Email address</label>
                        <Input
                          value={companyEmail}
                          onChange={(e) => setCompanyEmail(e.target.value)}
                          placeholder='mike@email.com'
                          // defaultValue="Mike"
                          type='email'
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md={6}>
                      <FormGroup>
                        <label>Company Address</label>
                        {/* <Input
                        id='inp_company_address'
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        placeholder='Home Address'
                        type='text'
                      /> */}
                        <PlacesAutocomplete
                          value={companyAddress}
                          onChange={(addr) => setCompanyAddress(addr)}
                          onSelect={(addr) => setCompanyAddress(addr)}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <Input
                                {...getInputProps({
                                  placeholder: 'Address',
                                  className: 'location-search-input1',
                                })}
                              />
                              <div className='autocomplete-dropdown-container1'>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item'
                                  return (
                                    <div
                                      className='input-suggestion'
                                      {...getSuggestionItemProps(
                                        suggestion,
                                        {}
                                      )}
                                    >
                                      <i className='fas fa-map-marker-alt'></i>{' '}
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </FormGroup>
                    </Col>
                  </Row>
                  {(user.role === 'admin' || user.role === 'super admin') && (
                    <>
                      <label>Taxes</label>
                      <Row>
                        <Col className='pr-md-1' md={4}>
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              defaultValue='GST'
                              type='text'
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col className='pr-md-1' md={4}>
                          <FormGroup>
                            <label>Number</label>
                            <Input
                              value={gstTaxNum}
                              onChange={(e) => setGstTaxNum(e.target.value)}
                              placeholder='e.g. 1230000203981'
                              type='text'
                            />
                          </FormGroup>
                        </Col>
                        <Col className='pr-md-1' md={4}>
                          <FormGroup>
                            <label>Value</label>
                            <Input
                              value={gstTaxValue}
                              onChange={(e) => setGstTaxValue(e.target.value)}
                              placeholder='%'
                              type='number'
                              min='0'
                              max='100'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-md-1' md={4}>
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              defaultValue='PST'
                              type='text'
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col className='pr-md-1' md={4}>
                          <FormGroup>
                            <label>Number</label>
                            <Input
                              value={pstTaxNum}
                              onChange={(e) => setPstTaxNum(e.target.value)}
                              placeholder='e.g. 1230000203981'
                              type='text'
                            />
                          </FormGroup>
                        </Col>
                        <Col className='pr-md-1' md={4}>
                          <FormGroup>
                            <label>Value</label>
                            <Input
                              value={pstTaxValue}
                              onChange={(e) => setPstTaxValue(e.target.value)}
                              placeholder='%'
                              type='number'
                              min='0'
                              max='100'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col
                      className='pr-md-1'
                      md='12'
                      style={{ 'text-align': 'center' }}
                    >
                      <FormGroup>
                        <label>Company Logo</label>
                        <br></br>
                        <span class='btn btn-primary btn-round btn-file'>
                          <span class='fileinput-new'>Select Logo</span>
                          <Input
                            // value={companyLogo}
                            onChange={async (e) => {
                              console.log('file: ', e.target.files[0])

                              const base64Img = await toBase64(
                                e.target.files[0]
                              )
                              setCompanyLogo(base64Img)

                              setImgPreview(base64Img)
                            }}
                            placeholder='Company Logo'
                            name='companyLogo'
                            id='companyLogo'
                            // defaultValue="Mike"
                            type='file'
                            accept='image/*'
                          />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='text-center'>
                    <Col
                      className='pr-md-1'
                      md='4'
                      style={{ 'text-align': 'center' }}
                    ></Col>
                    <Col
                      className='pr-md-1'
                      md='4'
                      style={{ 'text-align': 'center' }}
                    >
                      <FormGroup>
                        <CardImg src={imgPreview} />
                      </FormGroup>
                    </Col>
                    <Col
                      className='pr-md-1'
                      md='4'
                      style={{ 'text-align': 'center' }}
                    ></Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter style={{ textAlign: 'center' }}>
                <FixedPlugin />
                <Button
                  className='btn-fill btn-company-save'
                  color='primary'
                  type='submit'
                  onClick={(e) => {
                    onSaveCompany(e)
                  }}
                >
                  {loadingSave && (
                    <Spinner color='white' size='sm' className='mr-2' />
                  )}
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

/*

login --> initMap --> getGeofencesByPoint --> _getGeofencesInPoint --> handleZonesInPointResult
      --> init --> initUIData  --> getUnitMarker --> getGeofencesByUnitId


*/
