import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import ReactTable from 'components/ReactTable/ReactTable.js'
import NotificationAlert from 'react-notification-alert'

import {
  callapi,
  callextendedapisAwait,
  insertActivityLog,
  callExtendedApiPostAwait,
  delapi,
  getapiAwait,
  callExtendedApiPatchAwait,
} from '../../helper.js'
import config from '../../config.js'
import cloneDeep from 'lodash/cloneDeep'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func } from 'prop-types'
import ResProcess from './ResProcess.js'
import ReactTooltip from 'react-tooltip'
import { reactLocalStorage } from 'reactjs-localstorage'
import UpcomingNotes from 'views/components/UpcomingNotes.js'

export default function Invoice(props) {
  const [loading, setLoading] = useState(false)
  const [invoiceData, setInvoiceData] = useState([])
  const [data, setData] = React.useState([])
  const [invoiceLength, setInvoiceLength] = React.useState(0)
  const [invoice_id, setInvoice_id] = React.useState(null)
  const [view_state, setView_state] = React.useState('datatable')
  const [modal, setModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [customerName, setCustomerName] = React.useState('')

  const user = reactLocalStorage.getObject('user')
  const notificationAlertRef = React.useRef(null)

  async function fetchInvoiceData() {
    setLoading(true)
    var resp = []
    if (view_state === 'template') {
      resp = await callextendedapisAwait(
        'invoicetemplatescustom/templatesByCustomer',
        {
          companyId: user.companyId,
        }
      )
      console.log('invoicetemplatescustom/templatesByCustomer: ', resp.data)
    } else {
      if (user.role === 'admin' || user.role === 'super admin') {
        resp = await callextendedapisAwait(
          'invoicescustom/invoiceAllWithCustomer/',
          { companyId: user.companyId }
        )
      } else {
        resp = await callextendedapisAwait(
          'invoicescustom/invoiceMyAll/' + user.id,
          {}
        )
      }
    }
    let dataCalculation = new ResProcess(resp.data)
    let dataTableDataArr = dataCalculation.invoiceAll
    console.log(
      'async getAllEstimates() {->dataTableDataArr: ',
      dataTableDataArr
    )
    checkInvoiceIsPaid(dataTableDataArr)
    setLoading(false)
    setInvoiceData(dataTableDataArr)
    setInvoiceLength(dataTableDataArr.length)
  }
  function checkInvoiceIsPaid(dataTableDataArr) {
    const search = props.location.search
    const params = new URLSearchParams(search)
    const paid_status = params.get('action')
    const paid_item_id = params.get('item_id')
    // console.log('paid_status: ', paid_status)
    // console.log('paid_item_id: ', paid_item_id)
    const redirectToEdit = reactLocalStorage.getObject('paidInvoiceId')
    if (redirectToEdit.length > 0) {
      const paidItem = dataTableDataArr.find(
        (item) => item.invoice_id === redirectToEdit
      )
      reactLocalStorage.setObject('paidInvoiceId', '')
      console.log('redirect to edit: ', redirectToEdit)
      setInvoice_id(paidItem.invoice_id)
      setCustomerName(paidItem.first_name ? paidItem.first_name + ' ' + paidItem.last_name : '')
      setView_state('edit_invoice')
      setLoading(false)
    }
    if (paid_status !== 'stripe_success' || paid_item_id === '') {
      return
    } else {
      const paidItem = dataTableDataArr.find(
        (item) => item.invoice_id === paid_item_id
      )
      reactLocalStorage.setObject('paidInvoiceId', paid_item_id)
      markAsPaidHandler(paid_item_id)
    }
  }

  async function markAsPaidHandler(paidItemId) {
    console.log('invoicescustom item id before /markAsPaid: ', paidItemId)
    var response1 = await callExtendedApiPatchAwait(
      'invoicescustom/markAsPaid',
      {
        id: paidItemId,
      }
    )
    response1 = response1.data

    console.log('invoicescustom/markAsPaid: ', response1)
    // message.success('Successfully marked as paid.')
    // showNotificationMsg('Successfully marked as paid', 'success')

    insertActivityLog('invoice', paidItemId, 'Invoice marked as paid.')

    window.location.replace('/admin/invoices')
  }

  function reRenderTableData() {
    fetchInvoiceData()
  }

  useEffect(() => {
    fetchInvoiceData()
  }, [])

  useEffect(() => {
    if (view_state === 'template') fetchInvoiceData()
  }, [view_state])
  useEffect(() => {
    console.log('invoice length has changed ', invoiceLength)
    const data = invoiceData.map((prop, key) => {
      return {
        _id: key,
        id: prop.invoice_id,
        name: prop.first_name ? prop.first_name + ' ' + prop.last_name : '',
        total: prop.invoice_total,
        status: prop.status,
        invoiceId: prop.human_readable_id,
        actions: (
          <div className='actions-right'>
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                editInvoice(obj.id)
              }}
              color='warning'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-edit'
              data-tip='Edit'
            >
              <i className='tim-icons icon-pencil' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-edit'
              className='tooltip-for-edit'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                setDeleteId(obj.id)
                setModal(!modal)
                console.log('data is deleted id', obj.id)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-delete'
              data-tip='Delete'
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-delete'
              className='tooltip-for-delete'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
          </div>
        ),
      }
    })
    setData(data)
  }, [invoiceData, modal, invoiceLength])

  function editInvoice(invoice_id) {
    setInvoice_id(invoice_id)
    let { name: getTargetCustomerName } = data.find(
      (dataItem) => dataItem.id === invoice_id
    )
    setCustomerName(getTargetCustomerName || '')
    setView_state(
      view_state === 'template' ? 'edit_from_template' : 'edit_invoice'
    )
  }
  const toggle = () => setModal(!modal)
  function onOk() {
    setModal(!modal)
    var newdata = invoiceData
    console.log('InvoiceData ', invoiceData)

    if (deleteId) {
      let deleteinvoice = null
      if (view_state === 'template') {
        deleteinvoice = delapi(
          'invoicetemplatescustom/deletebyid/' + deleteId + '?'
        )
      } else {
        deleteinvoice = delapi('invoices/' + deleteId + '?')
      }

      // promise after called api and check for the api status
      deleteinvoice.then((myData) => {
        if (myData.count === 0) {
          showNotificationMsg('Sorry! Cannot delete invoice.', 'danger')
        } else {
          newdata.find((o, i) => {
            if (o.invoice_id === deleteId) {
              newdata.splice(i, 1)
              setDeleteId(null)
              return true
            }
            return false
          })
          showNotificationMsg('Invoice has been deleted.', 'success')
          setInvoiceData(newdata)
          setInvoiceLength(newdata.length)
          console.log('data is n', invoiceData)
        }
      })
    }
  }

  function prepareForEdit(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    }
    let targetInvoiceId = targetRow.getAttribute('data-id')
    // console.log('targetInvoiceId ', targetInvoiceId)
    // console.log('data ', data)

    let invoiceItem = data.find(
      (item) => targetInvoiceId.toString() === item.id.toString()
    )
    // console.log('Target Invoice Item: ', invoiceItem)
    editInvoice(invoiceItem.id)
  }

  function setMousePointer(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    } else {
      targetRow = e.target.parentElement.parentElement.parentElement
    }
    if (targetRow && !targetRow.style.cursor) {
      targetRow.style.cursor = 'pointer'
      targetRow.style.userSelect = 'none'
    }
  }

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (
      notificationAlertRef !== null &&
      notificationAlertRef.current !== null
    ) {
      notificationAlertRef.current.notificationAlert(options)
    } else {
      console.log('Notification not shown')
    }
  }
  if (loading) {
    return (
      <>
        <div
          className='content'
          style={{ textAlign: 'center', transition: 'none' }}
        >
          <Spinner color='white' size='sm' className='mr-2' />
        </div>
      </>
    )
  } else {
    if (view_state === 'datatable') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this invoice?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the invoice information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div
            className='content invoice'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader style={{ textAlign: 'right' }}>
                    {/* <CardTitle tag="h4">React Table</CardTitle> */}
                    <Button
                      color='primary'
                      onClick={() => setView_state('create_invoice')}
                    >
                      + Add New
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div className='invoice-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'invoiceId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Total',
                            accessor: 'total',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state === 'template') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this invoice template?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the invoice information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div
            className='content invoice'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <div>
                      <span>
                        <h4>Click a row to select a template</h4>
                      </span>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        id='ant-btn-back'
                        type='button'
                        className={`ant-btn-primary ant-btn-back btn`}
                        icon='back'
                        size='default'
                        onClick={(e) => {
                          setView_state('create_invoice')
                        }}
                      >
                        <span>Back</span>
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className='invoice-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'invoiceId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Total',
                            accessor: 'total',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state === 'edit_invoice') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type
                your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button color='primary' onClick={() => {}}>
                  Add Note
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='content'>
            <LineItemsForm
              mode='edit'
              for='invoice'
              item_id={invoice_id}
              itemName={customerName}
              setView_state={setView_state}
              showNotificationMsg={showNotificationMsg}
              fetchInvoiceData={fetchInvoiceData}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    } else if (view_state === 'edit_from_template') {
      return (
        <>
          <div className='content'>
            <LineItemsForm
              from='template'
              mode='edit'
              for='invoice'
              item_id={invoice_id}
              itemName={customerName}
              setView_state={setView_state}
              showNotificationMsg={showNotificationMsg}
              fetchInvoiceData={fetchInvoiceData}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    } else if (view_state === 'create_invoice') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type
                your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button color='primary' onClick={() => {}}>
                  Add Note
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='content'>
            <LineItemsForm
              mode='new'
              for='invoice'
              setView_state={setView_state}
              item_id={invoice_id}
              showNotificationMsg={showNotificationMsg}
              fetchInvoiceData={fetchInvoiceData}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    } else {
      return (
        <div>
          <h1>This header rendered if view_state not set</h1>
        </div>
      )
    }
  }
}
