import React, { useState, useEffect } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2'
// react plugin for creating vector maps
import { VectorMap } from 'react-jvectormap'
import ReactDatetime from 'react-datetime'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap'
import { Spinner } from 'reactstrap'
import {
  callapi,
  callextendedapisAwait,
  insertActivityLog,
  delapi,
} from '../helper'

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from 'variables/charts.js'
import Demo from 'variables/demo.js'
import { reactLocalStorage } from 'reactjs-localstorage'
import UpcomingNotes from './components/UpcomingNotes'

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
}

const moment = extendMoment(Moment)

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [bigChartData, setbigChartData] = React.useState('data1')
  const [chart1Data, setchart1Data] = React.useState({})

  const [openEstimate, setopenEstimate] = React.useState([])
  const [openJob, setopenJob] = React.useState([])
  const [openInvoice, setopenInvoice] = React.useState([])
  const [estimateData, setEstimateData] = React.useState([])
  const [jobData, setJobData] = React.useState([])
  const [invoiceData, setInvoiceData] = React.useState([])
  const [totalCustomer, settotalCustomer] = React.useState(0)
  const [totalUser, settotalUser] = React.useState(0)
  const [startDate, setstartdate] = React.useState(null)
  const [endDate, setendDate] = React.useState(null)
  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      // const fetchData = async () => {
      const user = reactLocalStorage.getObject('user')
      console.log('uSER FROM DAHSBORAD: ', user)

      const estimateRes = await callextendedapisAwait(
        'estimatescustom/estimateAllWithDate/',
        { companyId: user.companyId }
      )
      const jobRes = await callextendedapisAwait('jobscustom/jobAllWithDate/', {
        companyId: user.companyId,
      })
      const invoiceRes = await callextendedapisAwait(
        'invoicescustom/invoiceAllWithDate/',
        { companyId: user.companyId }
      )
      const customerRes = await callextendedapisAwait(
        'customers/allCustomersByCompany',
        { companyId: user.companyId }
      )
      const totalCustomer = customerRes.data.length

      const userRes = await callextendedapisAwait(
        `userscustom/getUsersforCompany/${user.companyId}`,
        {}
      )
      const totalUser = userRes.data.length
      // }
      setEstimateData(estimateRes.data)
      setJobData(jobRes.data)
      setInvoiceData(invoiceRes.data)
      console.log('estimateRes resp.data: ', estimateRes.data)
      console.log('jobRes resp.data: ', jobRes.data)
      console.log('invoiceRes resp.data: ', invoiceRes.data)
      console.log('customerRes resp.data: ', customerRes.data)
      console.log('userRes resp.data: ', userRes.data)
      let demo = new Demo(estimateRes.data, jobRes.data, invoiceRes.data)
      let openEstimate = demo.estimateAllInfo.open_estimate
      let openJob = demo.jobAllInfo.open_job
      let openInvoice = demo.invoiceAllInfo.open_invoice
      console.log('demo: ', demo)
      console.log('demo openEstimate: ', openEstimate)
      console.log('demo openJob: ', openJob)
      console.log('demo openInvoice: ', openInvoice)
      setopenEstimate(openEstimate)
      setopenJob(openJob)
      setopenInvoice(openInvoice)
      setLoading(false)
      setchart1Data(demo.chart1Data)
      settotalCustomer(totalCustomer)
      settotalUser(totalUser)
      // fetchData();
    }
    fetchData()
  }, [])
  const setBgChartData = (name) => {
    setbigChartData(name)
  }

  const formatDateTimeToLocal = (dateTime) => {
    let d = new Date(dateTime),
      year = d.getFullYear(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (hour.length < 2) hour = '0' + hour
    if (minute.length < 2) minute = '0' + minute

    return `${year}-${month}-${day} ${hour}:${minute}`
  }

  const fetchChartData = () => {
    const filteredEstimates = estimateData
      .filter(
        (estimateItem) =>
          startDate <= estimateItem.estimate_created_at &&
          estimateItem.estimate_created_at <= endDate
      )
      .map((estimateItem) => ({
        ...estimateItem,
        estimate_created_at: formatDateTimeToLocal(
          estimateItem.estimate_created_at
        ),
      }))
    const filteredJobs = jobData
      .filter(
        (jobItem) =>
          startDate <= jobItem.job_created_at &&
          jobItem.job_created_at <= endDate
      )
      .map((jobItem) => ({
        ...jobItem,
        job_created_at: formatDateTimeToLocal(jobItem.job_created_at),
      }))
    const filteredInvoices = invoiceData
      .filter(
        (invoiceItem) =>
          startDate <= invoiceItem.invoice_created_at &&
          invoiceItem.invoice_created_at <= endDate
      )
      .map((invoiceItem) => ({
        ...invoiceItem,
        invoice_created_at: formatDateTimeToLocal(
          invoiceItem.invoice_created_at
        ),
      }))
    // console.log('filteredEstimates are: ', filteredEstimates)
    // console.log('filteredJobs are: ', filteredJobs)
    // console.log('filteredInvoices are: ', filteredInvoices)
    let demo = new Demo(filteredEstimates, filteredJobs, filteredInvoices)
    let openEstimate = demo.estimateAllInfo.open_estimate
    let openJob = demo.jobAllInfo.open_job
    let openInvoice = demo.invoiceAllInfo.open_invoice
    setopenEstimate(openEstimate)
    setopenJob(openJob)
    setopenInvoice(openInvoice)
    setchart1Data(demo.chart1Data)
  }

  if (loading) {
    return (
      <>
        <div
          className='content'
          style={{ textAlign: 'center', transition: 'none' }}
        >
          <Spinner color='white' size='sm' className='mr-2' />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='4'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h5'>Start Date</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <ReactDatetime
                      inputProps={{
                        className: 'form-control',
                        placeholder: 'Date Picker Here',
                      }}
                      timeFormat={false}
                      dateFormat='YYYY-MM-DD'
                      isValidDate={(current) => {
                        return current.isBefore(moment())
                      }}
                      value={() => {
                        if (moment(startDate).isValid()) {
                          return moment(startDate).format('YYYY-MM-DD')
                        } else {
                          return null
                        }
                      }}
                      onChange={(date) => {
                        if (moment(date).isValid()) {
                          const demoStartDate = moment(date)
                            .toISOString()
                            .toString()
                          setstartdate(demoStartDate)
                        }
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h5'>End Date</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <ReactDatetime
                      inputProps={{
                        className: 'form-control',
                        placeholder: 'Date Picker Here',
                      }}
                      timeFormat={false}
                      dateFormat='YYYY-MM-DD'
                      isValidDate={(current) => {
                        return current.isBefore(moment())
                      }}
                      value={() => {
                        if (moment(endDate).isValid()) {
                          return moment(endDate).format('YYYY-MM-DD')
                        } else {
                          return null
                        }
                      }}
                      onChange={(date) => {
                        // console.log('date is here - end: ', demoEndDate)
                        if (moment(date).isValid()) {
                          const demoEndDate = moment(date)
                            .toISOString()
                            .toString()
                          setendDate(demoEndDate)
                        }
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col
              md='auto'
              className='filter-by-date'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                color='primary'
                id='0'
                // size="sm"
                tag='label'
                onClick={() => {
                  fetchChartData()
                }}
              >
                Filter by Date
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <Card className='card-chart'>
                <CardHeader>
                  <Row>
                    <Col className='text-left' sm='6'>
                      <h5 className='card-category'>Work</h5>
                      <CardTitle tag='h2'>Performance</CardTitle>
                    </Col>
                    <Col sm='6'>
                      <ButtonGroup
                        className='btn-group-toggle float-right'
                        data-toggle='buttons'
                      >
                        <Button
                          color='info'
                          id='0'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: bigChartData === 'data1',
                          })}
                          onClick={() => setBgChartData('data1')}
                        >
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                            Estimates
                          </span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-single-02' />
                          </span>
                        </Button>
                        <Button
                          color='info'
                          id='1'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: bigChartData === 'data2',
                          })}
                          onClick={() => setBgChartData('data2')}
                        >
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                            Jobs
                          </span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-gift-2' />
                          </span>
                        </Button>
                        <Button
                          color='info'
                          id='2'
                          size='sm'
                          tag='label'
                          className={classNames('btn-simple', {
                            active: bigChartData === 'data3',
                          })}
                          onClick={() => setBgChartData('data3')}
                        >
                          <span className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                            Invoices
                          </span>
                          <span className='d-block d-sm-none'>
                            <i className='tim-icons icon-tap-02' />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className='chart-area'>
                    <Line
                      data={chart1Data[bigChartData]}
                      options={chart1Data.options}
                      // data={chartExample1[bigChartData]}
                      // options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg='4' md='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col xs='5'>
                      <div style={{ fontSize: '1.8em' }}>
                        {/* <i className="tim-icons icon-chat-33" /> */}
                        <i className='tim-icons icon-notes' />
                      </div>
                    </Col>
                    <Col xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Open Estimates</p>
                        <CardTitle tag='h3'>{openEstimate}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className='stats'>
                    <i className='tim-icons icon-refresh-01' /> Update Now
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg='4' md='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col xs='5'>
                      <div style={{ fontSize: '1.8em' }}>
                        {/* <i className="tim-icons icon-shape-star" /> */}
                        <i className='tim-icons  icon-bullet-list-67' />
                      </div>
                    </Col>
                    <Col xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Open Jobs</p>
                        <CardTitle tag='h3'>{openJob}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className='stats'>
                    {/* <i className="tim-icons icon-sound-wave" /> Last Research */}
                    <i className='tim-icons icon-watch-time' /> Begining to
                    Today
                  </div>
                </CardFooter>
              </Card>
            </Col>

            <Col lg='4' md='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col xs='5'>
                      <div style={{ fontSize: '1.8em' }}>
                        {/* <i className="tim-icons icon-molecule-40" /> */}
                        <i className='tim-icons icon-paper' />
                      </div>
                    </Col>
                    <Col xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Open Invoices</p>
                        <CardTitle tag='h3'>{openInvoice}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className='stats'>
                    <i className='tim-icons icon-watch-time' /> Begining to
                    Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg='6' md='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col xs='5'>
                      <div style={{ fontSize: '1.8em' }}>
                        <i className='tim-icons icon-badge' />
                      </div>
                    </Col>
                    <Col xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Users</p>
                        <CardTitle tag='h3'>{totalUser}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className='stats'>
                    {/* <i className="tim-icons icon-trophy" /> Customers feedback */}
                    <i className='tim-icons icon-watch-time' /> Begining to
                    Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg='6' md='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col xs='5'>
                      <div style={{ fontSize: '1.8em' }}>
                        <i className='tim-icons icon-single-02' />
                      </div>
                    </Col>
                    <Col xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Customers</p>
                        <CardTitle tag='h3'>{totalCustomer}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className='stats'>
                    {/* <i className="tim-icons icon-trophy" /> Customers feedback */}
                    <i className='tim-icons icon-watch-time' /> Begining to
                    Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {!loading && <UpcomingNotes />}
        </div>
      </>
    )
  }
}

export default Dashboard
