import cloneDeep from "lodash/cloneDeep";
class ResProcess {
    constructor(data) {
        this.estimatesAll = this.EstimateCal(data)
        this.jobAll = this.JobCal(data)
        this.invoiceAll = this.InvoiceCal(data)
    }
    EstimateCal(data) {
        var dataTableDataArr = [];
        data.map(async (item, i) => {
            var j = 0;
            var objItem = {};
            for (; j < dataTableDataArr.length; ++j) {
                const item2 = dataTableDataArr[j];
                if (item.estimate_id === item2.estimate_id) {
                    break;
                }
            }
            if (j === dataTableDataArr.length) {
                var obj = cloneDeep(item);
                
                console.log("EstimateCal->obj: ", obj);

                objItem.estimate_id = obj.estimate_id;
                objItem.companyId = obj.companyId;
                objItem.human_readable_id = obj.human_readable_id;
                objItem.first_name = obj.first_name;
                objItem.last_name = obj.last_name;
                objItem.estimate_total = obj.estimate_total;
                objItem.estimate_assinged_userIds = obj.estimate_assinged_userIds;
                objItem.job_for_estimate = obj.job_for_estimate;

                if (obj.estimate_approved === 1) {
                    obj.status = "Approved";
                } else if (obj.estimate_disapproved === 1) {
                    obj.status = "Disapproved";
                } else if (obj.estimate_sent_to_customer === 1) {
                    obj.status = "Sent to Customer";
                } else {
                    obj.status = "No Action Taken";
                }
                objItem.status = obj.status;
                dataTableDataArr.push(objItem);
            }
        });
        return dataTableDataArr;
    }
    JobCal(data) {
        var dataTableDataArr = [];
        data.map((item, i) => {
            var j = 0;
            for (; j < dataTableDataArr.length; ++j) {
                const item2 = dataTableDataArr[j];
                if (item.job_id === item2.job_id) {
                    break;
                }
            }

            if (j === dataTableDataArr.length) {
                var obj = cloneDeep(item);
                if (obj.job_completed === 1) {
                    obj.status = "Completed";
                } else {
                    obj.status = "Not Completed";
                }

                dataTableDataArr.push(obj);
            }
        });
        return dataTableDataArr;
    }
    InvoiceCal(data) {
        var dataTableDataArr = [];
        data.map((item, i) => {
            var j = 0;
            for (; j < dataTableDataArr.length; ++j) {
                const item2 = dataTableDataArr[j];
                if (item.invoice_id === item2.invoice_id) {
                    break;
                }
            }
            if (j === dataTableDataArr.length) {
                var obj = cloneDeep(item);
                if (obj.invoice_paid === 1) {
                    obj.status = "Paid";
                } else {
                    obj.status = "Not Paid";
                }

                dataTableDataArr.push(obj);
            }
        });
        return dataTableDataArr;
    }
}
export default ResProcess;