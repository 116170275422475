import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
} from 'reactstrap'

import ReactTable from 'components/ReactTable/ReactTable.js'

import {
  callapi,
  callextendedapisAwait,
  insertActivityLog,
  callExtendedApiPostAwait,
  delapi,
  getapiAwait,
} from '../../helper.js'
import cloneDeep from 'lodash/cloneDeep'
import { Spinner } from 'reactstrap'
import AddEditEstimate from './addEditEstimate.js'
import LineItemsForm from './LineItems.js'
import ResProcess from './ResProcess.js'
import { reactLocalStorage } from 'reactjs-localstorage'
import ReactTooltip from 'react-tooltip'
import NotificationAlert from 'react-notification-alert'
import { createNonNullChain } from 'typescript'
import UpcomingNotes from 'views/components/UpcomingNotes.js'
// export default function estimates() {
const Estimates = () => {
  const [loading, setLoading] = useState(false)
  const [estimateRes, setEstimateRes] = useState([])
  const [data, setData] = React.useState([])
  const [estimate_id, setEstimate_id] = React.useState(null)
  const [customerName, setCustomerName] = React.useState('')
  const [selectedEstimateIds, setSelectedEstimateIds] = React.useState([])
  const [view_state, setView_state] = React.useState('datatable')
  const [modal, setModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [showMessageType, setShowMessageType] = useState({})

  const notificationAlertRef = React.useRef(null)
  //   const [message, setM] = React.useState('datatable')

  // const [mode, setMode] = React.useState("list");
  const user = reactLocalStorage.getObject('user')
  const sendEstimateToCustomer = async (id) => {
    // const _this = this

    console.log('id: ', id)

    var _baseUrl =
      process.env.REACT_APP_NODE_ENV === 'production'
        ? `https://${window.location.host}`
        : `http://${window.location.host}`

    const resp = await callextendedapisAwait(
      'estimatescustom/sendEstimateToCustomer/',
      { id: id, baseurl: _baseUrl }
    )
    console.log('async sendEstimateToCustomer(id) {->resp.data: ', resp.data)

    showNotificationMsg('Successfully Sent to Customer.', 'success')

    const resp2 = insertActivityLog('estimate', id, 'Sent to Customer')

    // reactComponentDidMount()
  }

  async function reactComponentDidMount() {
    setLoading(true)
    let resp = null
    if (view_state === 'template') {
      resp = await callextendedapisAwait(
        'estimatetemplatescustom/templatesByCustomer/',
        { companyId: user.companyId }
      )
    } else {
      resp = await callextendedapisAwait(
        'estimatescustom/estimateAllWithCustomer/',
        { companyId: user.companyId }
      )
    }
    console.log('async getAllEstimates() {->resp.data: ', resp.data)
    console.log('assign user ids: here', resp.data.estimate_assinged_userIds)
    let dataCalculation = new ResProcess(resp.data)
    let dataTableDataArr = dataCalculation.estimatesAll
    console.log(
      'async getAllEstimates() {->dataTableDataArr: ',
      dataTableDataArr
    )
    setEstimateRes(dataTableDataArr)
    setLoading(false)

    if (showMessageType.msg) {
      showNotificationMsg(showMessageType.msg, showMessageType.type)
      setShowMessageType({})
    }

    checkIfCalledFromSchedule(dataTableDataArr)

    //data
    const data = dataTableDataArr.map((prop, key) => {
      return {
        _id: key,
        id: prop.estimate_id,
        name: prop.first_name ? prop.first_name + ' ' + prop.last_name : '',
        total: prop.estimate_total,
        status: prop.status,
        estimateId: (
          <div>
            {view_state !== 'template' && (
              <Input
                type='checkbox'
                style={{
                  marginLeft: '0',
                }}
              />
            )}
            <span
              style={{
                marginLeft: '25px',
              }}
              data-id={prop.estimate_id}
            >
              {prop.human_readable_id}{' '}
            </span>
            {/* <span>Job</span> */}
            {prop.job_for_estimate != null && (
              <span
                style={{
                  textDecoration: 'underline',
                  color: 'blue',
                  fontSize: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => editJob(prop.job_for_estimate)}
              >
                Job
              </span>
            )}
          </div>
        ),
        actions: (
          <div className='actions-right'>
            {view_state !== 'template' && (
              <Button
                onClick={() => {
                  let obj = data.find((o) => o._id === key)
                  // console.log('Clicked row is here--', key)
                  // alert("You've clicked LIKE button on \n{ \nId: " + obj.id)
                  sendEstimateToCustomer(obj.id)
                }}
                color='warning'
                size='sm'
                className={
                  classNames('btn-icon btn-link like', {
                    'btn-neutral': key < 5,
                  }) + ' btn-warning'
                }
                data-for='tooltip-for-email'
                data-tip='Email'
              >
                <i className='tim-icons icon-email-85' />
              </Button>
            )}
            <ReactTooltip
              id='tooltip-for-email'
              className='tooltip-for-email'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                console.log(obj)
                editEstimate(obj, 'edit-btn')
              }}
              color='warning'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-edit'
              data-tip='Edit'
            >
              <i className='tim-icons icon-pencil' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-edit'
              className='tooltip-for-edit'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                setDeleteId(obj.id)
                setModal(!modal)
                console.log('data is deleted id', obj.id)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-delete'
              data-tip='Delete'
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-delete'
              className='tooltip-for-delete'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
          </div>
        ),
      }
    })
    setData(data)
    console.log('reactComponentDidMount->data: ', data)
  }

  function checkIfCalledFromSchedule(dataTableDataArr) {
    const estimateIdFromSchedule = reactLocalStorage.getObject('fromSchedule')
    reactLocalStorage.setObject('fromSchedule', '')
    console.log('Estimate id from schedule: ', estimateIdFromSchedule)
    if (estimateIdFromSchedule && estimateIdFromSchedule.length > 0) {
      const targetEstimateItem = dataTableDataArr.find(
        (item) => item.estimate_id === estimateIdFromSchedule
      )
      // console.log('targetEstimateItem: ', targetEstimateItem)
      // console.log('targetEstimateItem: ', estimateIdFromSchedule)
      if (targetEstimateItem.first_name) {
        setEstimate_id(estimateIdFromSchedule)
        let getTargetCustomerName = `${targetEstimateItem.first_name} ${targetEstimateItem.last_name}`
        setCustomerName(getTargetCustomerName)
        setView_state('edit_estimate')
      }
    }
  }

  function reRenderTableData() {
    reactComponentDidMount()
  }
  useEffect(() => {
    reactComponentDidMount()
  }, [])

  useEffect(() => {
    if (view_state === 'template') reactComponentDidMount()
  }, [view_state])

  //Edit_estimate function
  function editEstimate(selectedEstimate, calledFrom) {
    if (calledFrom === 'edit-btn') {
      setEstimate_id(selectedEstimate.id)
      // console.log("editEstimate->id: ", id);
      setCustomerName(selectedEstimate.name || '')
    } else if (calledFrom === 'row-click') {
      setEstimate_id(selectedEstimate.estimate_id)
      // console.log("editEstimate->id: ", id);
      setCustomerName(
        selectedEstimate.first_name ? (selectedEstimate.first_name || '') + ' ' + (selectedEstimate.last_name || '') : ''
      )
    }

    console.log('editEstimate->selectedEstimate: ', selectedEstimate)
    // let { name: getTargetCustomerName } = data.find(
    //   (dataItem) => dataItem.id === id
    // )

    setView_state(
      view_state === 'template' ? 'edit_from_template' : 'edit_estimate'
    )
    // setMode("edit")
    // console.log('id ', id);
  }
  function editJob(job_for_estimate) {
    setEstimate_id(job_for_estimate)
    setView_state('edit_job')
  }

  function prepareForEdit(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    } else {
      targetRow = e.target.parentElement.parentElement.parentElement
    }
    // console.log(
    //   'targetRow.firstChild.firstChild.firstChild.nextSibling.getAttribute: ',
    //   targetRow.firstChild.firstChild.firstChild.nextSibling.getAttribute(
    //     'data-id'
    //   )
    // )
    // let targetEstimateId =
    //   targetRow.firstChild.firstChild.firstChild.nextSibling.getAttribute(
    //     'data-id'
    //   )

    let targetEstimateId = targetRow.getAttribute('data-id')
    // console.log('targetRow: ', targetRow)
    let estimateItem = estimateRes.find(
      (item) => targetEstimateId.toString() === item.estimate_id.toString()
    )
    // console.log('estimateItem  ', estimateItem)
    editEstimate(estimateItem, 'row-click')
  }

  function setMousePointer(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    } else {
      targetRow = e.target.parentElement.parentElement.parentElement
    }
    if (targetRow && !targetRow.style.cursor) {
      targetRow.style.cursor = 'pointer'
      targetRow.style.userSelect = 'none'
    }
  }
  function addToDuplicateList(e) {
    let targetEstimateId = e.target.nextSibling.getAttribute('data-id')
    let isFound = selectedEstimateIds.find((item) => item === targetEstimateId)
    console.log('isfound : ' + isFound)
    if (isFound) {
      let newItems = selectedEstimateIds.filter(
        (item) => item !== targetEstimateId
      )
      setSelectedEstimateIds(newItems)
    } else {
      setSelectedEstimateIds([...selectedEstimateIds, targetEstimateId])
    }
  }

  function getDuplicateList() {
    console.log('selectedEstimateIds: ', selectedEstimateIds)
    const duplicateItems = selectedEstimateIds.map((sId) =>
      estimateRes.find((item) => sId.toString() === item.estimate_id.toString())
    )
    console.log('estimateIds for duplicate:  ', duplicateItems)
    if (duplicateItems.length < 1 || duplicateItems === null) {
      showNotificationMsg('Select an Estimate first.', 'info')
    } else {
      duplicateEstimate(duplicateItems)
    }
  }

  async function duplicateEstimate(duplicateItems) {
    duplicateItems.map(async (estimateItem) => {
      console.log('duplicate calling->item: ', estimateItem)

      var response1 = await callExtendedApiPostAwait(
        'estimatescustom/duplicate',
        { id: estimateItem.estimate_id }
      )
      console.log(
        'duplicateEstimate = () => {->response1.data: ',
        response1.data
      )
    })
    showNotificationMsg('Selected Estimates Duplicated Successfully.', 'info')
    setSelectedEstimateIds([])
    // reactComponentDidMount()
    setTimeout(() => {
      reRenderTableData()
    }, 2000)
  }
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }
  const toggle = () => setModal(!modal)
  function onOk() {
    setModal(!modal)
    //
    // call appi to delete plan

    let delEstimate = null
    if (view_state === 'template') {
      delEstimate = delapi(
        'estimatetemplatescustom/deletebyid/' + deleteId + '?'
      )
    } else {
      delEstimate = delapi('estimates/' + deleteId + '?')
    }

    // promise after called api and check for the api status
    delEstimate.then((myData) => {
      if (myData.count === '0') {
        showNotificationMsg('Sorry! Cannot delete the estimate.', 'warning')
      } else {
        showNotificationMsg('Estimate has been deleted.', 'success')
        var newdata = data
        newdata.find((o, i) => {
          if (o.id === deleteId) {
            newdata.splice(i, 1)
            setDeleteId(null)
            return true
          }
          return false
        })
        console.log('data is n', data)
        console.log('dataNew is n', newdata)
        setData(newdata)
      }
    })
  }

  if (loading) {
    return (
      <>
        <div
          className='content '
          style={{ textAlign: 'center', transition: 'none' }}
        >
          <Spinner color='white' size='sm' className='mr-2' />
        </div>
      </>
    )
  } else {
    if (view_state == 'datatable') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this estimate?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the estimate information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div
            className='content estimate'
            onClick={(e) => {
              if (
                e.target.tagName === 'INPUT' &&
                e.target.type === 'checkbox'
              ) {
                addToDuplicateList(e)
              } else if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader style={{ textAlign: 'right' }}>
                    {/* <CardTitle tag="h4">React Table</CardTitle> */}
                    <Button
                      color='primary'
                      className='ant-btn-back btn btn-secondary'
                      onClick={() => setView_state('create_estimate')}
                    >
                      + Add New
                    </Button>
                    <Button
                      color='primary'
                      className='ant-btn-back btn btn-secondary'
                      onClick={getDuplicateList}
                    >
                      <i className='tim-icons icon-single-copy-04' /> Duplicate
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div className='estimate-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'estimateId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Total',
                            accessor: 'total',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state === 'template') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this Template?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the Template information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button
                  color='primary'
                  onClick={() => {
                    onOk()
                  }}
                >
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div
            className='content estimate'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <div>
                      <span>
                        <h4>Click a row to select a template</h4>
                      </span>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        id='ant-btn-back'
                        type='button'
                        className={`ant-btn-primary ant-btn-back btn`}
                        icon='back'
                        size='default'
                        onClick={(e) => {
                          setView_state('create_estimate')
                        }}
                      >
                        <span>Back</span>
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className='estimate-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'estimateId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Total',
                            accessor: 'total',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state == 'edit_estimate') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type
                your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button color='primary' onClick={() => {}}>
                  Add Note
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='content'>
            <LineItemsForm
              mode='edit'
              for='estimate'
              item_id={estimate_id}
              itemName={customerName}
              setView_state={setView_state}
              setShowMessageType={setShowMessageType}
              componentDidMount={reactComponentDidMount}
              showNotificationMsg={showNotificationMsg}
              setModal={setModal}
              reRenderTableData={reRenderTableData}
            />
          </div>
        </>
      )
    } else if (view_state === 'edit_from_template') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>

          <div className='content'>
            <LineItemsForm
              from='template'
              mode='edit'
              for='estimate'
              item_id={estimate_id}
              itemName={customerName}
              setView_state={setView_state}
              setShowMessageType={setShowMessageType}
              componentDidMount={reactComponentDidMount}
              showNotificationMsg={showNotificationMsg}
              setModal={setModal}
              reRenderTableData={reRenderTableData}
            />
          </div>
        </>
      )
    } else if (view_state == 'create_estimate') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type
                your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button color='primary' onClick={() => {}}>
                  Add Note
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div className='content'>
            <LineItemsForm
              mode='new'
              for='estimate'
              item_id={estimate_id}
              setView_state={setView_state}
              view_state={view_state}
              setShowMessageType={setShowMessageType}
              componentDidMount={reactComponentDidMount}
              showNotificationMsg={showNotificationMsg}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    } else if (view_state == 'edit_job') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div className='content'>
            <LineItemsForm
              mode='edit'
              for='job'
              item_id={estimate_id}
              setView_state={setView_state}
              setShowMessageType={setShowMessageType}
              componentDidMount={reactComponentDidMount}
              showNotificationMsg={showNotificationMsg}
              reRenderTableData={reRenderTableData}
            />
          </div>
        </>
      )
    }
  }
}
export default Estimates
