import Geocode from 'react-geocode';

var utility = {
    convertQueryStringToJson: (qs) => {
        if (qs.includes('?')) {
            qs = qs.substring(1);
        }

        var temps = qs.split('&');

        var rets = [];
        temps.map((temp, i) => {
            var temps2 = temp.split('=');

            rets.push({
                [temps2[0]]: temps2[1]
            });
        });
        return rets;
    },
    getCustomerLatLng: (customer) => {
        return new Promise(async (resolve, reject) => {
            var customerAddress = '';
            if (customer.addresses) {
                console.log('customer.addresses ', customer.addresses)
                Object.keys(customer.addresses[0]).map((key, index) => {
                    if (key === 'id' || key === 'latlng') {
                    } else {
                        customerAddress += customer.addresses[0][key] + ','
                    }
                })
                console.log('customer Address: ', customerAddress)
            }
            // Geocode.fromAddress(customerAddress).then(
            //     async response => {
            //         const { lat, lng } = response.results[0].geometry.location;
            // Get latitude & longitude from address.
            // Geocode.fromAddress(customerAddress).then(
            //     response => {
            //         const { lat, lng } = response.results[0].geometry.location;
            //         console.log("lat and lng of customer addresss: ",lat, lng);
            //     },
            //     error => {
            //         console.error(error);
            //     }
            // );

            //-----------------

            // if (customerAddress !== null || "undefined") {

            try {
                const response = await Geocode.fromAddress(customerAddress)
                let geoAddress = response.results[0].geometry.location
                console.log('Geo address for customer is: ', geoAddress);
                resolve(geoAddress);
            } catch (error) {
                console.log(
                    'Error occurred in get geo address for customer, error: ',
                    error
                );

                resolve({
                    lat: null,
                    lng: null,
                });
            }
        });
    },
};

export default utility;