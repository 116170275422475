import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert'

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Footer from 'components/Footer/Footer.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js'
import { reactLocalStorage } from 'reactjs-localstorage'

import routes from 'routes.js'

import { callextendedapisAwait } from 'helper'

var ps

const Admin = (props) => {
  const [activeColor, setActiveColor] = React.useState('red')
  const [sidebarMini, setSidebarMini] = React.useState(true)
  const [opacity, setOpacity] = React.useState(0)
  const [sidebarOpened, setSidebarOpened] = React.useState(false)
  const mainPanelRef = React.useRef(null)
  const notificationAlertRef = React.useRef(null)
  const location = useLocation()
  const authkey = reactLocalStorage.getObject('authkey')
  const user = reactLocalStorage.getObject('user')

  const [outterLink, setOutterLink] = useState('')
  const [text, setText] = useState('')
  const [logo, setLogo] = useState('')

  let themeMode = reactLocalStorage.getObject('theme')

  if (Object.entries(authkey).length === 0) {
    props.history.push('/auth/login')
  } else if (user.companyId == '-1') {
    props.history.push(`/auth/newcompanysetup?uid=${user.id}`)
  }

  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0
    }
  }, [location])

  const showCompanyLogoAndNameWithLink = async () => {
    const user = reactLocalStorage.getObject('user')
    const authkey = reactLocalStorage.getObject('authkey')

    if (Object.entries(authkey).length > 0) {
      const resp = await callextendedapisAwait(
        `companyInfocustom/getCompanyInfoById/${user.companyId}`,
        {}
      )

      console.log('showCompanyLogoAndNameWithLink->resp.data: ', resp.data)

      if (resp.data.length > 0) {
        const companyInfo = resp.data[0]

        setOutterLink(companyInfo.website)

        setText(companyInfo.companyName)
        setLogo(companyInfo.logo)
      }
    }
  }
  React.useEffect(() => {
    showCompanyLogoAndNameWithLink()

    if (themeMode === 'dark') {
      document.body.classList.remove('white-content')
    }

    let innerMainPanelRef = mainPanelRef
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.classList.add('perfect-scrollbar-on')
      document.documentElement.classList.remove('perfect-scrollbar-off')
      ps = new PerfectScrollbar(mainPanelRef.current)
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener('ps-scroll-y', showNavbarButton)
      let tables = document.querySelectorAll('.table-responsive')
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i])
      }
    }
    window.addEventListener('scroll', showNavbarButton)
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
        document.documentElement.classList.add('perfect-scrollbar-off')
        document.documentElement.classList.remove('perfect-scrollbar-on')
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            'ps-scroll-y',
            showNavbarButton
          )
      }
      window.removeEventListener('scroll', showNavbarButton)
    }
  }, [])
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1)
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0)
    }
  }
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }

      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            handleActiveClick={handleActiveClick}
          />
        )
      } else {
        return null
      }
    })
  }
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  const handleActiveClick = (color) => {
    setActiveColor(color)
  }
  const handleMiniClick = () => {
    let notifyMessage = 'Sidebar mini '
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false)
      notifyMessage += 'deactivated...'
    } else {
      setSidebarMini(true)
      notifyMessage += 'activated...'
    }
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: 'info',
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
    document.body.classList.toggle('sidebar-mini')
  }
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened)
    document.documentElement.classList.toggle('nav-open')
  }
  const closeSidebar = () => {
    setSidebarOpened(false)
    document.documentElement.classList.remove('nav-open')
  }
  return (
    <div className='wrapper'>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className='navbar-minimize-fixed' style={{ opacity: opacity }}>
        <button
          className='minimize-sidebar btn btn-link btn-just-icon'
          onClick={handleMiniClick}
        >
          <i className='tim-icons icon-align-center visible-on-sidebar-regular text-muted' />
          <i className='tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted' />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={activeColor}
        setActiveColor={setActiveColor}
        logo={{
          outterLink: outterLink,
          text: text,
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
        handleActiveClick={handleActiveClick}
      />
      <div className='main-panel' ref={mainPanelRef} data={activeColor}>
        <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from='*' to='/admin/dashboard' />
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf('full-screen-map') !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
      {/* <Route path='/admin/settings' exact>
        {' '}
        <FixedPlugin
          activeColor={activeColor}
          sidebarMini={sidebarMini}
          handleActiveClick={handleActiveClick}
          handleMiniClick={handleMiniClick}
        />{' '}
      </Route> */}
    </div>
  )
}

export default Admin
