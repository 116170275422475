import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
} from 'reactstrap'
import Select from 'react-select'
import ReactTable from 'components/ReactTable/ReactTable.js'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

import { getapiAwait, postapiAwait } from '../../helper.js'
import config from '../../config.js'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func } from 'prop-types'
import { isConstructorDeclaration } from 'typescript'
import NotificationAlert from 'react-notification-alert'
import { reactLocalStorage } from 'reactjs-localstorage'
import { LayoutCloseNavigationButton } from 'react-md'
import Subscribe from 'components/payment/Subscribe.js'
import CheckoutForm from 'components/payment/Checkout.js'
import {
  checkIfSubscribed, initStripe,
  createSubscriptionPlan,
  createSubscription,
  handlePaymentForFleetTrackers,
  insertTrackerBuyInfo
} from '../functions/stripeF'

export default function ComingSoon(props) {
  const globalLocation = useLocation()
  const globalHistory = useHistory()

  const [loading, setLoading] = useState(true)
  const [mapLoading, setMapLoading] = useState(true)
  const [wialonAccessToken, setWialonAccessToken] = useState(reactLocalStorage.getObject('WAT'))
  const [loginMsg, setLoginMsg] = useState('')
  const [userInfo, setUserInfo] = useState('')
  const [allunits, setAllunits] = useState('')
  const [unitValue, setUnitValue] = useState(0)
  const [unitOptions, setUnitOptions] = useState([{ label: '—', value: 0 }])
  const [resourceValue, setResourceValue] = useState(0)
  const [resourceOptions, setResourceOptions] = useState([{ label: 'All', value: 0 }])
  const [geofenceValue, setGeofenceValue] = useState(null)
  const [geofenceOptions, setGeofenceOptions] = useState([])
  const [msgLog, setMsgLog] = useState('')
  const [latlngValue, setLatlngValue] = useState('')
  const [currentPosition, setCurrentPosition] = useState({})
  const [tableResults, setTableResults] = useState('')
  const [traceInfo, setTraceInfo] = useState([])
  const [geofenceType, setGeofenceType] = useState('circle')
  const [subscriptionOptions, setsubscriptionOptions] = useState([{ label: null, value: null }])

  const notificationAlertRef = React.useRef(null)
  const user = reactLocalStorage.getObject('user')
  //----------------------------------------------------------------
  const [sparkNanoAdded, setSparkNanoAdded] = useState(false)
  const [trackPortAdded, setTrackPortAdded] = useState(false)
  const [livewireAdded, setLivewireAdded] = useState(false)
  const [checkoutModal, setCheckoutModal] = useState(false)
  const [subscribeModal, setSubscribeModal] = useState(false)
  const [selectedTrackers, setSelectedTrackers] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState({ value: null, label: null })
  const [stripeAccId, setStripeAccId] = useState(null)
  const [subscriptionPriceInfo, setsubscriptionPriceInfo] = useState([])
  const [subscriptionId, setsubscriptionId] = useState(null)
  const [clientSecret, setclientSecret] = useState(null)
  const [rowId, setRowId] = useState(null)
  const [isSubscriptionSuccess, setIsSubscriptionSuccess] = useState(false)
  const [isSubscribedBefore, setIsSubscribedBefore] = useState(false)

  // Start configuring the map
  // global variables

  const [currentPoint, setCurrentPoint] = useState(null)
  const [currentUnitId, setCurrentUnitId] = useState(null)
  const [map, setMap] = useState(null)
  const [unitEventID, setUnitEventID] = useState(null)
  const [mapWaypts, setMapWaypts] = useState([])
  const [polyline, setPolyline] = useState([])
  const [enableNewCircle, setEnableNewCircle] = useState(false)
  const [enableNewRectangle, setEnableNewRectangle] = useState(false)
  const [circle_zone, setCircle_zone] = useState(false)
  const [rectangle_zone, setRectangle_zone] = useState(false)
  const [circleRadius, setCircleRadius] = useState(500)
  const [geofenceName, setGeofenceName] = useState('')
  const [isRadiusDisabled, setIsRadiusDisabled] = useState(true)
  const [rectInitialPoint, setRectInitialPoint] = useState(null)
  const [rawGeofences, setRawGeofences] = useState([])
  const [fleetState, setFleetState] = useState('loading')

  const [unitPayment, set_unitPayment] = useState('checkout')
  const [totalUnitAmount, set_totalUnitAmount] = useState(0)

  const geofenceTypeRef = React.useRef()
  const enableNewCircleRef = React.useRef()
  const enableNewRectangleRef = React.useRef()
  const circle_zoneRef = React.useRef()
  const rectangle_zoneRef = React.useRef()
  const rectInitialPointRef = React.useRef()

  geofenceTypeRef.current = geofenceType
  enableNewCircleRef.current = enableNewCircle
  enableNewRectangleRef.current = enableNewRectangle
  circle_zoneRef.current = circle_zone
  rectangle_zoneRef.current = rectangle_zone
  rectInitialPointRef.current = rectInitialPoint

  const geofenceTypeOptions = [
    { label: 'Circle', value: 'circle' },
    { label: 'Rectangle', value: 'rectangle' },
    { label: 'Polygon', value: 'polygon' },
  ]

  var mapCustom,
    marker,
    markerByUnit = {}

  function initMap() {
    // create a map in the "map" div, set the view to a given place and zoom
    mapCustom = window.L.map('map', {
      // disable zooming, because we will use double-click to set up marker
      doubleClickZoom: false,
      // }).setView([53.9, 27.55], 10)
    }).setView([40.71388811680337, -74.00397398240538], 10)

    setMap(mapCustom)

    // add an OpenStreetMap tile layer
    window.L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      // copyrights
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | &copy; <a href="http://gurtam.com">Gurtam</a>',
    }).addTo(mapCustom)

    // handle mouse double-click event

    console.log('map: ', mapCustom)
    mapCustom.on('dblclick', function (e) {
      // hide hint
      // $('#doubleclick-hint').hide()
      document.getElementById('doubleclick-hint').style.display = 'none'

      // deselect unit, because we are selected location manually
      // $('#units').val('0')
      setUnitValue('0')
      console.log('e from map dblclick: ', e)
      getGeofencesByPoint(e.latlng.lat, e.latlng.lng)
    })
    mapCustom.on('click', mapOnClick)
    setMapLoading(false)
  }

  const mapOnClick = (e) => {
    if (geofenceTypeRef.current === 'circle') {
      if (enableNewCircleRef.current && !circle_zoneRef.current) {
        setEnableNewCircle(false)
        newCircle(e.latlng.lat, e.latlng.lng)
      } else if (!!circle_zoneRef.current) {
        circle_zoneRef.current.setLatLng({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        })
      }
    } else if (geofenceTypeRef.current === 'rectangle') {
      if (!rectInitialPointRef.current && enableNewRectangleRef.current) {
        setRectInitialPoint({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        })
      } else if (!!rectInitialPointRef.current && enableNewRectangleRef.current) {
        console.log('rect Top-left: ', rectInitialPointRef.current)
        console.log('rect bottom-right: ', e.latlng)
        setEnableNewRectangle(false)
        newRectangle(rectInitialPointRef.current, e.latlng)
      }
    }
  }

  function newCircle(lat, lng) {
    // var r = parseInt($('#r').val()) || 500
    var r = circleRadius || 500
    setCircle_zone(
      window.L.circle([lat, lng], r, {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
      }).addTo(map ? map : mapCustom)
    )
    // $('#r').removeAttr('disabled')
    setIsRadiusDisabled(false)
  }

  function targetCircle(lat, lng, radius) {
    if (circle_zoneRef.current) map ? map.removeLayer(circle_zoneRef.current) : mapCustom.removeLayer(circle_zoneRef.current)
    const showCircle = window.L.circle([lat, lng], radius, {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.5,
    }).addTo(map ? map : mapCustom)
    setCircle_zone(showCircle)
    if (map) map.setView({ lat, lng })
    else mapCustom.setView({ lat, lng })
  }

  function newRectangle(initial, final) {
    console.log({ ...initial }, { ...final })
    const bounds = [
      [initial.lat, initial.lng],
      [final.lat, final.lng],
    ]
    setRectangle_zone(
      window.L.rectangle(bounds, {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
      }).addTo(map ? map : mapCustom)
    )
  }

  function setRadius(radius) {
    if (parseInt(radius) && circle_zoneRef.current && typeof circle_zoneRef.current.setRadius == 'function') {
      circle_zoneRef.current.setRadius(radius)
    }
  }

  function saveCircle() {
    // create circle geofence using entered data
    // var res = $('#res').val(),
    //   name = $('#n').val() // get parameters from corresponding fields
    var res = resourceValue,
      name = geofenceName // get parameters from corresponding fields
    // simple validation, exit if invalid
    if (res === 0) {
      msg('Select resource')
      showNotificationMsg('Please select a resource first.', 'info')
      return
    } // resource validation
    if (!name) {
      msg('Enter name')
      showNotificationMsg('Please enter a geofence name.', 'info')
      return
    } // name validation
    if (!circle_zoneRef.current) {
      msg('Create circle')
      showNotificationMsg('Please create a new circle.', 'info')
      return
    } // circle on map validation

    var Q = circle_zoneRef.current.getLatLng() // get circle center
    var R = parseInt(circle_zoneRef.current.getRadius(), 10) // get circle radius (must be int)
    console.log('get radius :', R)

    // construct object using entered data
    var obj = {
      n: name, //geofence name
      t: 3, // type (3 - circle)
      f: 0, // flags
      w: R, // radius
      c: 2566914048, // color
      p: [{ x: Q.lng, y: Q.lat, r: R }], // points (for circle, center coords and radius)
    }

    var resource = window.wialon.core.Session.getInstance().getItem(res) // get resource by id
    console.log('saveCircle - resource: ', resource)
    console.log('saveCircle - dataFlag: ', window.wialon.item.Item.dataFlag)
    resource.createZone(
      obj, // create geofence
      function (code, data) {
        // create geofence callback
        if (code) {
          msg(window.wialon.core.Errors.getErrorText(code))
          console.log('createZone callback - error: ', window.wialon.core.Errors.getErrorText(code))
          return
        } // exit if error code
        msg("<b>'" + data.n + "'</b> geofence created successfully") // print create succeed message
        showNotificationMsg(data.n + ' geofence created successfully', 'success')
        getGeofenceZones(resourceValue)
      }
    )

    // $('#r').attr('disabled', 'disabled')
    setIsRadiusDisabled(true)
    // delete geofence
    map.removeLayer(circle_zoneRef.current)
    setCircle_zone(null)
  }

  function showUnitForTrace() {
    // show selected unit on map
    // var val = $('#units').val() // get selected unit id
    // if (!val) return // exit if no unit selected
    var sess = window.wialon.core.Session.getInstance() // get instance of current Session
    // specify what kind of data should be returned
    var flags = window.wialon.item.Unit.dataFlag.lastMessage
    var unit = null
    if (unitValue) {
      // check if we already have previous unit
      unit = sess.getItem(unitValue)
      // console.log('trace target unit: ', unit)

      sess.updateDataFlags(
        // remove previous item from current session
        [{ type: 'id', data: unitValue, flags: flags, mode: 2 }], // item specification
        function (code) {
          if (code) {
            msg(window.wialon.core.Errors.getErrorText(code))
            return
          }
          // console.log('trace 1 unitEventId: ', unitEventID)
          if (unitEventID) unit.removeListenerById(unitEventID) // unbinding event from this unit
        }
      )
    }

    setUnitEventID(null) // empty event ID
    setMapWaypts([]) // remove all old checkpoints if they were here

    sess.updateDataFlags(
      // load item with necessary flags to current session
      [{ type: 'id', data: unitValue, flags: flags, mode: 1 }], // item specification
      function (code) {
        if (code) {
          msg(window.wialon.core.Errors.getErrorText(code))
          // console.log(
          //   'trace add listener error: ',
          //   window.wialon.core.Errors.getErrorText(code)
          // )
          return
        }

        unit = window.wialon.core.Session.getInstance().getItem(unitValue) // get unit by id
        if (!unit) return // exit if no unit
        var position = unit.getPosition() // get unit position
        // console.log('trace 2 position: ', position)
        if (!position) return // exit if no position
        if (map) {
          // check if map created and we can detect position of unit

          // console.log('trace 2 map: ', map)

          var icon = window.L.icon({
            iconUrl: unit.getIconUrl(32),
            iconAnchor: [16, 16],
          })
          console.log('trace 2 icon: ', icon)
          if (!marker) {
            marker = window.L.marker({ lat: position.y, lng: position.x }, { icon: icon }).addTo(map)
          } else {
            marker.setLatLng({ lat: position.y, lng: position.x })
            marker.setIcon(icon)
          }
          map.setView({ lat: position.y, lng: position.x })

          if (!polyline) {
            setPolyline(
              window.L.polyline([{ lat: position.y, lng: position.x }], {
                color: 'blue',
              }).addTo(map)
            )
          }

          //				if (polyline) polyline.setMap(null); // if there is alreday path of map - remove it
          //				mapWaypts.push(latlon); // adding point to path array
        }

        msg('You chose unit: ' + unit.getName())

        setUnitEventID(unit.addListener('messageRegistered', showTracingData)) // register event when we will receive message
      }
    )
  }

  function showTracingData(event) {
    var data = event.getData() // get data from event

    if (!data.pos) return // exit if no position

    var appended = '' // here we will put all required information
    var position = {
      // get unit position
      x: data.pos.x,
      y: data.pos.y,
    }
    appended += 'Position (x: ' + data.pos.x + '; y: ' + data.pos.y + ')' // printing position
    if (data.t) appended += ', time ' + window.wialon.util.DateTime.formatTime(data.t, 1) // printing event time (return user time)
    msg(appended) // writing to log message about this event
    setTraceInfo([...traceInfo, appended])

    if (map) {
      // check if map created

      marker.setLatLng({ lat: position.y, lng: position.x })

      if (polyline) {
        polyline.addLatLng({ lat: position.y, lng: position.x })
      }
      map.setView({ lat: position.y, lng: position.x })
    }
  }

  // Unit markers constructor
  function getUnitMarker(unit) {
    // check for already created marker
    var marker = markerByUnit[unit.getId()]
    if (marker) return marker

    var unitPos = unit.getPosition()

    // don't create marker if unit haven't a position
    if (!unitPos) return null

    marker = window.L.marker([unitPos.y, unitPos.x], {
      clickable: true,
      draggable: false,
      icon: window.L.icon({
        iconUrl: unit.getIconUrl(32),
        iconAnchor: [16, 16], // set icon center
      }),
    })
    marker.on('click', function (e) {
      // select unit in UI
      console.log('msg from unit marker: ', marker)
      setUnitValue(unit.getId())
      getGeofencesByUnitId(unit.getId())
    })

    // save marker
    markerByUnit[unit.getId()] = marker

    return marker
  }

  const msg = (text) => setMsgLog(msgLog + `<p><small>${text}</small></p>`)

  function onlyTwoSignsAfterComma(n) {
    return Math.round(n * 100) / 100
  }

  // show area in feets or meters (miles or kilometers)
  function prettyPrintArea(area, measureUnits) {
    if (typeof measureUnits !== 'number') measureUnits = 0

    var multiplier, small, big

    if (measureUnits === 1 || measureUnits === 2) {
      // feets/miles
      multiplier = 5280 * 5280
      small = ' ft²'
      big = ' mi²'
    } else {
      // meters/kilometers
      multiplier = 1000
      small = ' m²'
      big = ' km²'
    }

    if (area < multiplier) return Math.ceil(area) + small
    else return onlyTwoSignsAfterComma(area / multiplier) + big
  }

  // show distance in feets or meters (miles or kilometers)
  function prettyPrintDistance(distance, measureUnits) {
    if (typeof measureUnits !== 'number') measureUnits = 0

    var multiplier, small, big

    if (measureUnits === 1 || measureUnits === 2) {
      // feets/miles
      multiplier = 5280
      small = ' ft'
      big = ' mi'
    } else {
      // meters/kilometers
      multiplier = 1000
      small = ' m'
      big = ' km'
    }

    if (distance < multiplier) return Math.ceil(distance) + small
    else return onlyTwoSignsAfterComma(distance / multiplier) + big
  }

  const initTargetGeofence = (targetGeo) => {
    const cen_x = targetGeo.b.cen_x
    const cen_y = targetGeo.b.cen_y
    let radius = targetGeo.w
    targetCircle(cen_y, cen_x, radius)
  }

  function init() {
    // Execute after login succeed
    // get instance of current Session
    var session = window.wialon.core.Session.getInstance()

    // load Geofences Library
    session.loadLibrary('resourceZones')
    // load Icon Library
    session.loadLibrary('itemIcon')

    // specify what kind of data should be returned
    var unitFlags = window.wialon.item.Item.dataFlag.base | window.wialon.item.Unit.dataFlag.lastPosition,
      resourceFlags = window.wialon.item.Item.dataFlag.base | window.wialon.item.Resource.dataFlag.zones

    // load items to the current session
    session.updateDataFlags(
      // Items specification, request unit list and resource list
      [
        { type: 'type', data: 'avl_unit', flags: unitFlags, mode: 0 },
        { type: 'type', data: 'avl_resource', flags: resourceFlags, mode: 0 },
      ],
      function (error) {
        // updateDataFlags callback
        if (error) {
          // show error, if update data flags was failed
          msg(window.wialon.core.Errors.getErrorText(error))
          console.log('error: ', error, window.wialon.core.Errors.getErrorText(error))
          showNotificationMsg(window.wialon.core.Errors.getErrorText(error), 'danger')
        } else {
          msg('Units & Resources are loaded')
          showNotificationMsg('Units & Resources are loaded', 'success')
          initUIData()
        }
      }
    )
  }
  // Dexpoten plus
  // escape
  // will be called after updateDataFlags success
  function initUIData() {
    var session = window.wialon.core.Session.getInstance()

    var units = session.getItems('avl_unit'),
      resources = session.getItems('avl_resource')

    // var unitsEl = $('#units'),
    //   resourcesEl = $('#resources')
    console.log('units: ', units)
    if (units.length === 0) {
      setFleetState('buy-unit')
      const payment_status = JSON.parse(localStorage.getItem('tracker_payment'))
      if (payment_status?.status === 'payment_success') {
        setSubscribeModal(true)
      }
    }

    units.forEach(function (unit) {
      // add option to the select with unit id and name
      // unitsEl.append($('<option>').text(unit.getName()).val(unit.getId()))
      setUnitOptions([...unitOptions, { value: unit.getId(), label: unit.getName() }])

      var unitMarker = getUnitMarker(unit)
      if (unitMarker) {
        unitMarker.addTo(mapCustom)
        console.log('unitMarker: ', unitMarker)
      }

      // listen for new messages
      unit.addListener('changePosition', function (event) {
        // event is qx.event.type.Data
        // extract message data
        var pos = event.getData()

        // move or create marker, if not exists
        if (pos) {
          if (unitMarker) {
            unitMarker.setLatLng([pos.y, pos.x])
          } else {
            // create new marker
            unitMarker = getUnitMarker(unit)

            if (unitMarker) {
              unitMarker.addTo(mapCustom)
            } else {
              msg("Got message with pos, but unit don't have a position")
              showNotificationMsg("Got message with pos, but unit don't have a position", 'info')
            }
          }
        }
      })
    })

    resources.forEach(function (resource) {
      setResourceOptions([...resourceOptions, { label: resource.getName(), value: resource.getId() }])
    })

    setLoading(false)
  }

  const setPositionFromlatlng = () => {
    // extracts two numbers divided by non-digits
    var latlngRegex = /^[^\d]*?(-?\d+(?:\.\d+)?)[^\.\d]+?(-?\d+(?:\.\d+)?)[^\d]*$/

    console.log('latlng from callback: ', latlngValue)

    var groups = latlngRegex.exec(latlngValue)
    if (!groups) {
      // invalid string
      // positionEl.addClass('invalid')
      msg('Entered position is invalid!')
      showNotificationMsg('Entered position is invalid!', 'warning')
      return
    }

    var lat = parseFloat(groups[1]),
      lon = parseFloat(groups[2])

    if (isFinite(lat) && isFinite(lon) && -90 <= lat && lat <= 90 && -180 <= lon && lon <= 180) {
      // deselect unit, because we have selected location manually
      // $('#units').val('0')
      setUnitValue('0')

      getGeofencesByPoint(lat, lon)
    } else {
      // positionEl.addClass('invalid')
      msg('Entered position is invalid!')
      showNotificationMsg('Entered position is invalid!', 'warning')
    }
  }

  function getGeofencesByUnitId(unitId) {
    unitId = parseInt(unitId, 10)
    if (!isFinite(unitId)) {
      msg('Bad unit id')
      showNotificationMsg('Bad unit id', 'warning')
      return
    }

    // save for refreshes
    setCurrentUnitId(unitId)
    console.log('unitId: ', unitId)

    if (unitId === 0) {
      // $('#unit-refresh-button').hide()
      document.getElementById('unit-refresh-button').style.display = 'none'
      return
    }

    var session = window.wialon.core.Session.getInstance()

    var unit = session.getItem(unitId)
    if (!unit) {
      msg('Unit not found')
      showNotificationMsg('Unit not found', 'warning')
      // $('#unit-refresh-button').hide()
      document.getElementById('unit-refresh-button').style.display = 'none'
      return
    }

    // Show button for refreshing data by current unit position
    // $('#unit-refresh-button').show()
    document.getElementById('unit-refresh-button').style.display = ''

    var position = unit.getPosition()
    console.log('unit: ', unit)
    console.log('position: ', position)
    console.log('map: ', mapCustom)
    if (!position) {
      msg("Unit haven't a position")
      showNotificationMsg("Unit haven't a position", 'warning')
      // $('#results-table').hide()
      document.getElementById('results-table').style.display = 'none'
      // $('#no-results').hide()
      // document.getElementById('no-results').style.display = 'none'
      return
    }

    _getGeofencesInPoint(position.y, position.x)
  }

  function getGeofencesByPoint(lat, lon) {
    // save for refreshes
    setCurrentUnitId(null)

    // hide unit refresh button
    // $('#unit-refresh-button').hide()
    document.getElementById('unit-refresh-button').style.display = 'none'

    _getGeofencesInPoint(lat, lon)
  }

  // implementation
  function _getGeofencesInPoint(lat, lon) {
    setCurrentPosition({ lat: lat, lon: lon })

    console.log('map1: ', map)
    console.log('custommap: ', mapCustom)
    console.log('lat lon: ', lat, lon)
    // center map at the point
    if (map !== null && map) map.setView([lat, lon])
    else if (mapCustom !== null && mapCustom) mapCustom.setView([lat, lon])

    // five signs is precise enough
    var displayLat = Math.round(lat * 100000) / 100000,
      displayLon = Math.round(lon * 100000) / 100000

    // show position
    // $('#latlng').val(displayLat + ', ' + displayLon)
    console.log('setlatlng: ', displayLat + ', ' + displayLon)
    setLatlngValue(displayLat + ', ' + displayLon)
    // $('#latlng').removeClass('invalid')

    // get selected resource
    var resourceId = parseInt(resourceValue)

    // object with requested resources to find zones in
    var requestZoneId = {}

    var session = window.wialon.core.Session.getInstance()

    if (resourceId === 0) {
      // add all resources to the search
      session.getItems('avl_resource').forEach(function (resource) {
        requestZoneId[resource.getId()] = []
      })
    } else {
      // empty array means "search for all geofences in this resource"
      requestZoneId[resourceId] = []
    }

    msg('Loading zones in point')
    // showNotificationMsg('Loading zones in point', 'warning')

    // do a request
    window.wialon.util.Helper.getZonesInPoint(
      {
        lat: lat,
        lon: lon,
        zoneId: requestZoneId,
      },
      function (error, data) {
        if (error) {
          msg(window.wialon.core.Errors.getErrorText(error))
          showNotificationMsg(window.wialon.core.Errors.getErrorText(error), 'warning')
          console.log('error: ', error, window.wialon.core.Errors.getErrorText(error))

          return
        }

        // msg('Zones have loaded')
        // showNotificationMsg('Zones have loaded', 'info')

        handleZonesInPointResult(data)
      }
    )

    // move marker, or create, if not exists
    if (marker) {
      marker.setLatLng([lat, lon])
    } else {
      marker = window.L.marker([lat, lon], {
        clickable: false,
        draggable: false,
        zIndexOffset: 1000,
      })
      marker.addTo(mapCustom)
      console.log('_getGeofencesInPoint marker: ', marker)
      console.log('_getGeofencesInPoint mapCustom: ', mapCustom)
    }
  }

  function handleZonesInPointResult(data) {
    var session = window.wialon.core.Session.getInstance()

    // var resultsTableBodyEl = $('#results-table-body')

    // remove old results
    // resultsTableBodyEl.empty()
    if (document.getElementById('results-table-body')) document.getElementById('results-table-body').innerHTML = ''

    // numerify result rows
    var zoneI = 0

    // format of response: https://sdk.wialon.com/wiki/en/sidebar/remoteapi/apiref/resource/get_zones_by_point

    // Save zones to make a batch request for additional data
    // {resource: window.wialon.item.Resource,
    //  zonesIds: [<zoneId>, ...],
    //  zonesElements: [{
    //    area: <DOMElement>,
    //    perimeter: <DOMElement>
    //  }, ...]}
    var resourcesToLoadData = []

    for (var resourceId in data)
      if (data.hasOwnProperty(resourceId)) {
        var foundZonesIds = data[resourceId]

        resourceId = parseInt(resourceId)
        var resource = session.getItem(resourceId)

        var resourceToLoadData = {
          resource: resource,
          zonesIds: [],
          zonesElements: [],
        }
        resourcesToLoadData.push(resourceToLoadData)

        foundZonesIds.forEach((zoneId) => {
          var zone = resource.getZone(zoneId)

          // zone data format:
          // https://sdk.wialon.com/wiki/en/sidebar/remoteapi/apiref/resource/get_zone_data

          var zoneType
          switch (zone.t) {
            case 1:
              zoneType = 'Line'
              break
            case 2:
              zoneType = 'Polygon'
              break
            case 3:
              zoneType = 'Circle'
              break
            default:
              zoneType = 'Unknown'
          }

          var tr = '<tr>'

          var zoneElements = {
            // Save elements to update them when area and perimeter will be loaded
            area: '<td>Loading...</td>',
            perimeter: '<td>Loading...</td>',
          }
          resourceToLoadData.zonesIds.push(zoneId)
          resourceToLoadData.zonesElements.push(zoneElements)

          tr += '<td>' + ++zoneI + '</td>'
          tr += '<td>' + zone.n + '</td>' // Name
          tr += '<td>' + zoneType + '</td>' // Type

          tr += zoneElements.area // Area
          tr += zoneElements.perimeter // Perimeter

          tr += '<td>' + resource.getName() + '</td> </tr>' // Resource name

          setTableResults(tr)
          console.log('rable results: ', tableResults)
        })
      }

    var remote = window.wialon.core.Remote.getInstance()
    // Start a batch to do all getZonesData in one HTTP request
    remote.startBatch()

    resourcesToLoadData.forEach(function (toLoad) {
      // request only area and perimeter
      var zoneFlags = window.wialon.item.MZone.flags.area | window.wialon.item.MZone.flags.perimeter

      // all requests will be batched and executed only after finishBatch call
      toLoad.resource.getZonesData(toLoad.zonesIds, zoneFlags, function (error, data) {
        if (error) {
          msg(window.wialon.core.Errors.getErrorText(error))
          showNotificationMsg(window.wialon.core.Errors.getErrorText(error), 'warning')
          console.log('error: ', error, window.wialon.core.Errors.getErrorText(error))
          return
        }

        // update all table rows
        data.forEach(function (zone, i) {
          // toLoad.zonesElements[i].area.text(prettyPrintArea(zone.ar, toLoad.resource.getMeasureUnits()))
          // toLoad.zonesElements[i].perimeter.text(prettyPrintDistance(zone.pr, toLoad.resource.getMeasureUnits()))
        })
      })
    })

    // msg('Loading extra zones data (area & perimeter)')

    // perform all getZonesData requests
    remote.finishBatch(function (error) {
      if (error) {
        msg(window.wialon.core.Errors.getErrorText(error))
        showNotificationMsg(window.wialon.core.Errors.getErrorText(error), 'warning')
        console.log('error: ', error, window.wialon.core.Errors.getErrorText(error))
      } else {
        msg('Extra zone data have loaded')
        // showNotificationMsg('Extra zone data have loaded', 'info')
      }
    })

    if (zoneI > 0) {
      // $('#results-table').show()
      if (document.getElementById('results-table')) document.getElementById('results-table').style.display = ''
      // $('#no-results').hide()
      // document.getElementById('no-results').style.display = 'none'
    } else {
      // $('#results-table').hide()
      if (document.getElementById('results-table')) document.getElementById('results-table').style.display = 'none'
      // $('#no-results').show()
      // document.getElementById('no-results').style.display = ''
      // showNotificationMsg('No geofences at this location', 'info')
    }
  }

  function getGeofenceZones(res_id) {
    // get geofences by resource id
    // $('#zones').empty() // clean 'zones' select list
    if (res_id) {
      // check if resource id exists
      var res = window.wialon.core.Session.getInstance().getItem(res_id) // get resource by id
      if (!res) {
        msg('Unknown resource id: ' + res_id)
        showNotificationMsg(`Selected resource id deosn't exist.`)
        return
      } // exit if resource not found
      var zones = res.getZones() // get resource's zones
      console.log('geofences: ', zones)
      var index = 0 // init variable
      let tempGeofences = []
      let rawTempGeo = []
      for (var i in zones) {
        // construct Select list using found zones
        tempGeofences.push({ label: zones[i].n, value: zones[i].id })
        rawTempGeo.push({ ...zones[i] })
        index++
      }

      setGeofenceOptions([...tempGeofences])
      setRawGeofences([...rawTempGeo])
      // if no Zones was appended to select list
      if (!index)
        // print no Geofences found message
        msg("Geofences not found for '" + res.getName() + "'. Create it first")
      // print found Geofences count
      else msg(index + " geofences found for '" + res.getName() + "'.")
    }
  }

  // ----------------------------------------------------------------------------

  useEffect(() => {
    setIsSubscriptionSuccess(isSubscribedBefore)
  }, [isSubscribedBefore])

  useEffect(() => {
    const payment_status = JSON.parse(localStorage.getItem('tracker_payment'))
    console.log('sm window.L: ', window.L)
    let urlParams = new URLSearchParams(window.location.search)
    const stripe_status = urlParams.get('action')
    if (stripe_status === 'stripe_success' && payment_status?.status !== 'subscription_success') {
      localStorage.setItem('tracker_payment', JSON.stringify({ status: 'payment_success' }))
      // console.log('unessential condition gets true')
    }
    let access_token = urlParams.get('access_token')
    if (access_token) {
      setWialonAccessToken(access_token)
      reactLocalStorage.setObject('WAT', access_token)
      login(access_token)
    } else if (typeof wialonAccessToken === 'string' && wialonAccessToken.length > 0) {
      login(wialonAccessToken)
    } else {
      initMap()
    }
    initStripe().then((_id) => {
      console.log('stripeId: ', _id)
      setStripeAccId(_id)
    })
    checkIfSubscribed().then((data) => setIsSubscribedBefore(data))
    if (payment_status?.status === 'payment_success') {
      setSubscribeModal(true)
    }
    if (payment_status?.status === 'subscription_success') {
      setIsSubscriptionSuccess(true)
      console.log('isSubscriptionSuccess yes')
    }

    
  }, [fleetState])
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search)
    const tracker_paymentIntentClientSecret = urlParams.get('payment_intent_client_secret')
    const tracker_paymentIntent = urlParams.get('payment_intent')
    const tracker_redirectStatus = urlParams.get('redirect_status')
    if (tracker_redirectStatus === 'succeeded'
      && tracker_paymentIntentClientSecret
      && tracker_paymentIntentClientSecret.length > 0 
      && tracker_paymentIntent 
      && tracker_paymentIntent.length > 0
    ) {
      insertTrackerBuyInfo(tracker_paymentIntent, tracker_paymentIntentClientSecret).then((data) => {
        console.log('sm data: ', data)
        if (data) {
          // window.location.pathname = '/admin/fleet'
          globalHistory.replace('/admin/fleet')
          showNotificationMsg('Tracker unit bought successfully.')
        }
      })
    }
   }, [])

  async function login(token) {
    var sess = window.wialon.core.Session.getInstance() // get instance of current Session
    var user = sess.getCurrUser() // get current User
    console.log('sm wialon session: ', sess)
    console.log('sm wialon : ', window.wialon)
    console.log('sm user : ', user)
    if (user) {
      // if user exists - you are already logged, print username to log
      setLoginMsg("You are logged as '" + user.getName() + "', click logout button first")
      initMap()
      init()
      return
    }

    sess.initSession('https://hst-api.wialon.com') // initialize Wialon session
    sess.loginToken(
      token,
      '', // trying login
      function (code) {
        // login callback
        if (code) {
          setLoginMsg('wialon login error: ', window.wialon.core.Errors.getErrorText(code))
          console.log('error: ', code, window.wialon.core.Errors.getErrorText(code))
        }
        // login failed, print error
        else {
          setLoginMsg('Logged successfully in wialon') // login succeed
          initMap()
          init()
        }
      }
    )
  }

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (notificationAlertRef !== null && notificationAlertRef.current !== null) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }
  const toggleCheckoutModal = () => setCheckoutModal(!checkoutModal)
  const toggleSubscribeModal = () => setSubscribeModal(!subscribeModal)

  const set_selectedTrackers = () => {
    let tempList = selectedTrackers
    if (sparkNanoAdded) {
      const isSparkNano = tempList.find((item) => item.name === 'Spark Nano')
      console.log('isSparkNano: ', isSparkNano)
      if (!isSparkNano) {
        tempList = [...tempList, { name: 'Spark Nano', price: 50, quantity: 1 }]
      }
    }
    if (trackPortAdded) {
      const isTrackPort = tempList.find((item) => item.name === 'TrackPort')
      console.log('isTrackPort: ', isTrackPort)
      if (!isTrackPort) {
        tempList = [...tempList, { name: 'TrackPort', price: 50, quantity: 1 }]
      }
    }
    if (livewireAdded) {
      const isLivewire = tempList.find((item) => item.name === 'Livewire')
      console.log('isLivewire: ', isLivewire)
      if (!isLivewire) {
        tempList = [...tempList, { name: 'Livewire', price: 50, quantity: 1 }]
      }
    }
    console.log('selectedTrackers 1: ', selectedTrackers)
    console.log('tempList 1: ', tempList)
    setSelectedTrackers(tempList)
    setCheckoutModal(true)
  }

  useEffect(() => {
    testFunction()
    if (fleetState === 'buy-unit') {
      createSubscriptionPlan().then(([subscription_options, price_infos]) => {
        setsubscriptionOptions(subscription_options || [])
        setsubscriptionPriceInfo(price_infos || [])
        console.log('price_infos: ', price_infos)
      })
    }
  }, [fleetState])

  const testFunction = async () => {
    // const testData = await postapiAwait('stripescustom/setsubscriptionsuccess', { row_id: 'f61d6549-682c-4308-a8f9-6a5373c7411e' })
    // console.log('testData: ', testData)
  }

  return (
    <div className='content'>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div>
        <Modal isOpen={checkoutModal} fade={false} toggle={toggleCheckoutModal} className='tracker-checkout-modal'>
          {unitPayment === 'checkout' &&
            <ModalHeader toggle={toggleCheckoutModal}>
              <i className='tim-icons icon-alert-circle-exc' /> Please checkout your form
            </ModalHeader>
          }
          <ModalBody>
            {unitPayment === 'checkout' ?
              (<>
                <Row>
                  <Col>
                    <h4>Tracker Name</h4>
                  </Col>
                  <Col>
                    <h4>Price</h4>
                  </Col>
                  <Col>
                    <h4>Quantity</h4>
                  </Col>
                  <Col>
                    <h4>Total</h4>
                  </Col>
                </Row>
                {selectedTrackers.map(({ name, price, quantity }, index) => (
                  <Row key={index} style={{ marginBottom: '10px' }}>
                    <Col>{name}</Col>
                    <Col>${price}</Col>
                    <Col>
                      <Input
                        type='number'
                        value={quantity}
                        style={{
                          width: '100%',
                          color: '#32325d',
                          borderColor: 'hsl(0,0%,80%)',
                        }}
                        onChange={({ target: { value } }) => {
                          setSelectedTrackers([
                            ...selectedTrackers.map((trackerItem) => {
                              if (trackerItem.name === name) {
                                return { name, price, quantity: parseInt(value) }
                              } else {
                                return trackerItem
                              }
                            }),
                          ])
                        }}
                        min={1}
                      />
                    </Col>
                    <Col>${parseInt(quantity) * price}</Col>
                  </Row>
                ))}
                <Row className='tracker-total-row'>
                  <Col></Col>
                  <Col></Col>
                  <Col>
                    <h4>Total</h4>
                  </Col>
                  <Col>
                    <h4>
                      $
                      {selectedTrackers.reduce((total, { quantity, price }) => {
                        total += quantity * price
                        return total
                      }, selectedSubscription.value)}
                    </h4>
                  </Col>
                </Row>
              </>
              ) : (
              <Row>
                <Col>
                  <CheckoutForm
                    totalAmount={selectedTrackers.reduce((total, { quantity }) => {
                      total += quantity
                      return total
                    }, 0)}
                  />
                </Col>
                </Row>
              )
            }
          </ModalBody>
          <ModalFooter>
            {unitPayment === 'checkout' &&
              <Button
                id='attach-form-save'
                color='primary'
                
                onClick={() => {
                  set_unitPayment('payment')
                  // const total_quantity = selectedTrackers.reduce((total, { quantity }) => {
                  //   total += quantity
                  //   return total
                  // }, 0)
                  
                }}
              >
                Checkout
              </Button>
            }
            <Button
              color='secondary'
              onClick={() => {
                set_unitPayment('checkout')
                setCheckoutModal(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={subscribeModal} fade={false} toggle={toggleSubscribeModal} className='tracker-checkout-modal'>
          <ModalHeader toggle={toggleSubscribeModal}>
            <i className='tim-icons icon-alert-circle-exc' /> Subscribe
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>Subscription</Col>
              <Col sm={8}>
                <FormGroup style={{ marginBottom: '0px' }}>
                  <Select
                    value={subscriptionOptions.find((option) => option.value === selectedSubscription.value)}
                    onChange={async (selectedItem) => {
                      setSelectedSubscription(selectedItem)
                      let { subscription, row_id } = await createSubscription(subscriptionPriceInfo, selectedItem, selectedTrackers)
                      console.log('subscription from FT: ', subscription)
                      setclientSecret(subscription?.latest_invoice?.payment_intent?.client_secret)
                      setRowId(row_id)
                    }}
                    options={subscriptionOptions}
                    isDisabled={selectedSubscription.value}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Subscribe
                  showMsg={showNotificationMsg}
                  stripeAccId={stripeAccId}
                  selectedTrackers={selectedTrackers}
                  selectedSubscription={selectedSubscription}
                  subscriptionPriceInfo={subscriptionPriceInfo}
                  clientSecret={clientSecret}
                  rowId={rowId}
                  setSubscribeModal={setSubscribeModal}
                  setIsSubscriptionSuccess={setIsSubscriptionSuccess}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
      {fleetState !== 'buy-unit' && (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    color='primary'
                    onClick={() => setFleetState('buy-unit')}
                  >
                    Buy GPS Units
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {(typeof wialonAccessToken === 'object' || wialonAccessToken.length < 5) && (
                  <Button
                    className='btn-fill connect-with-stripe'
                    color='primary'
                    style={{
                      backgroundImage: 'initial',
                      backgroundColor: '#ec250d',
                      marginBottom: '15px',
                    }}
                    type='button'
                    onClick={async (e) => {
                      window.location.href = `https://hosting.wialon.com/login.html?redirect_uri=${window.location.href}&access_type=-1`
                    }}
                  >
                    <span>Get GPS Access Token</span>
                  </Button>
                )}
                <p id='doubleclick-hint'>Doubleclick on the map to manually set location</p>
                {/* <p id='no-results'>No geofences at this location</p> */}

                <table id='results-table' style={{ display: 'none' }}>
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>Name</td>
                      <td>Type</td>
                      <td>Area</td>
                      <td>Perimeter</td>
                      <td>Resource</td>
                    </tr>
                  </thead>
                  <tbody id='results-table-body' dangerouslySetInnerHTML={{ __html: tableResults }}></tbody>
                </table>
                <Row>
                  <Col md={4}>
                    <Row style={{ marginBottom: '10px' }}>
                      <Col>
                        <p>
                          Position:{' '}
                          <Input
                            id='latlng'
                            type='text'
                            placeholder='latitude, longitude'
                            value={latlngValue}
                            onChange={(e) => {
                              setLatlngValue(e.target.value)
                            }}
                          />{' '}
                          <Button id='apply-location-button' onClick={setPositionFromlatlng} style={{ marginTop: '5px' }}>
                            Apply
                          </Button>
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <Col>
                        <FormGroup>
                          <Label>Units: </Label>
                          <div>
                            <Select
                              id='units'
                              value={unitOptions.filter(function (option) {
                                return option.value === unitValue
                              })}
                              onChange={(e) => {
                                setUnitValue(e.value)
                                console.log('map from jsx: ', mapCustom)
                                console.log('map from jsx: ', map)
                                getGeofencesByUnitId(e.value)
                              }}
                              options={unitOptions}
                              isDisabled={unitOptions.length === 1}
                            />
                          </div>
                          <div style={{ marginTop: '5px' }}>
                            <Button
                              id='unit-refresh-button'
                              onClick={() => {
                                if (currentUnitId) getGeofencesByUnitId(currentUnitId)
                              }}
                            >
                              Refresh
                            </Button>
                            {unitValue !== '' && <Button onClick={showUnitForTrace}>Trace</Button>}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Resources: </Label>
                          <Select
                            value={resourceOptions.filter(function (option) {
                              return option.value === resourceValue
                            })}
                            onChange={(e) => {
                              setResourceValue(e.value)

                              console.log('currentUnitId: ', currentUnitId)
                              console.log('e.value: ', typeof e.value)
                              if (e.value !== 0) {
                                getGeofenceZones(e.value)
                              }
                              if (currentUnitId !== null) {
                                getGeofencesByUnitId(currentUnitId)
                              } else if (currentPosition !== null) {
                                console.log('currentPosition: ', currentPosition)
                                getGeofencesByPoint(currentPosition.lat, currentPosition.lon)
                              }
                            }}
                            options={resourceOptions}
                            isDisabled={resourceOptions.length === 1}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div
                      id='log'
                      dangerouslySetInnerHTML={{ __html: msgLog }}
                      style={{
                        border: '1px solid #ddd',
                        padding: '5px',
                        display: 'none',
                      }}
                    ></div>
                    <div className='trace-info'>
                      {traceInfo.map((traceInfoItem) => (
                        <p>
                          <small>{traceInfoItem}</small>
                        </p>
                      ))}
                    </div>
                  </Col>
                  <Col md={8}>
                    {mapLoading && (
                      <div className='content'>
                        <Spinner color='light' />
                      </div>
                    )}
                    <div id='map' style={{ height: '500px' }}></div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '15px', minHeight: '150px' }}>
                  <Col md={6}>
                    <p>Create Geofence: </p>
                    {resourceValue !== 0 ? (
                      <FormGroup>
                        <Label>Select Geofence Type: </Label>
                        <div>
                          <Select
                            id='geofence-type'
                            // value={geofenceTypeOptions.filter(function (option) {
                            //   return option.value === geofenceType
                            // })}
                            value={{ label: 'Circle', value: 'circle' }}
                            onChange={(e) => {
                              // setGeofenceType(e.value)
                            }}
                            options={geofenceTypeOptions}
                          />
                        </div>
                        <Label>Name: </Label>
                        <Input type='text' value={geofenceName} onChange={(e) => setGeofenceName(e.target.value)} />
                        {geofenceType === 'circle' && (
                          <>
                            <Label>Radius: </Label>
                            <Input
                              type='number'
                              value={circleRadius}
                              onChange={(e) => {
                                setCircleRadius(e.target.value)
                                setRadius(e.target.value)
                              }}
                              // disabled={isRadiusDisabled}
                            />
                            <div style={{ marginTop: '5px' }}>
                              <Button
                                id='unit-refresh-button'
                                onClick={() => {
                                  if (!circle_zoneRef.current) {
                                    setEnableNewCircle(true)
                                    msg('Set center of circle to map.')
                                    showNotificationMsg('Set center of circle to map.', 'info')
                                  }
                                }}
                              >
                                New Circle
                              </Button>
                            </div>
                          </>
                        )}
                        {geofenceType === 'rectangle' && (
                          <>
                            <Row>
                              <Col>
                                {enableNewRectangle && (
                                  <p>
                                    Click into the map to select <strong> top-left </strong> corner of the Rectangle.
                                  </p>
                                )}
                                {rectangle_zoneRef.current && (
                                  <p>
                                    Click into the map to select <strong> bottom-right </strong> corner of the Rectangle.
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <div style={{ marginTop: '5px' }}>
                              <Button
                                id='unit-refresh-button'
                                onClick={() => {
                                  console.log('rectangle_zoneRef.current: ', rectangle_zoneRef.current)
                                  if (!rectangle_zoneRef.current) {
                                    setEnableNewRectangle(true)
                                    msg('Click into the map to select top-left corner of the Rectangle.')
                                    showNotificationMsg('Click into the map to select top-left corner of the Rectangle.', 'info')
                                  }
                                }}
                              >
                                New Rectangle
                              </Button>
                            </div>
                          </>
                        )}
                        <div style={{ display: 'grid', justifyItems: 'end' }}>
                          <Button
                            onClick={() => {
                              if (geofenceType === 'circle') {
                                saveCircle()
                              } else {
                              }
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </FormGroup>
                    ) : (
                      <p>
                        <small> Please select a resource first.</small>
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    <p>Get Geofence: </p>

                    {resourceValue !== 0 && geofenceOptions.length !== 0 ? (
                      <FormGroup>
                        <Label>Select Geofence: </Label>
                        <Select
                          value={geofenceOptions.filter(function (option) {
                            return option.value === geofenceValue
                          })}
                          onChange={(e) => {
                            initTargetGeofence(rawGeofences.find((rawItem) => rawItem.id === e.value))
                            setGeofenceValue(e.value)
                          }}
                          options={geofenceOptions}
                          // isDisabled={true}
                        />
                        <div>
                          {geofenceValue ? (
                            <Button
                              id='unit-refresh-button'
                              onClick={() => {
                                console.log('all geofences: ', geofenceOptions)
                              }}
                              style={{ marginTop: '10px' }}
                            >
                              Edit
                            </Button>
                          ) : (
                            <p style={{ marginTop: '15px' }}>Please select a geofence for view/edit.</p>
                          )}
                        </div>
                      </FormGroup>
                    ) : resourceValue !== 0 && geofenceOptions.length === 0 ? (
                      <p>No geofence available for this resource. Please Create First.</p>
                    ) : (
                      <p>
                        <small> Please select a resource first.</small>
                      </p>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {fleetState === 'buy-unit' && (
        <Card>
          <CardHeader>
            {isSubscriptionSuccess ? <h3>Your subcription was created successfully.</h3> : <h3>Buy GPS Tracking Devices</h3>}
            <div style={{ textAlign: 'right' }}>
              <Button
                color='primary'
                onClick={() => setFleetState(null)}
              >
                Back to Fleet
              </Button>
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button
                color='primary'
                onClick={() => {
                  if (!sparkNanoAdded && !trackPortAdded && !livewireAdded) {
                    showNotificationMsg('Please select at least one of the Tracking Devices for checkout.')
                  } else {
                    set_selectedTrackers()
                  }
                }}
                disabled={isSubscriptionSuccess}
              >
                Checkout
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Row style={{ textAlign: 'center' }}>
              <Col lg='4' md='6'>
                <div>
                  <img
                    className='d-flex m-auto'
                    alt='Spark Nano'
                    src={require('assets/img/sparknano.png').default}
                    style={{ maxWidth: '200px', margin: '0 auto' }}
                  />
                </div>
                <div>
                  <h5 style={{ marginTop: '15px', marginBottom: '10px' }}>Spark Nono 7 GPS Tracker</h5>
                  <h4 style={{ marginBottom: '10px' }}> $50</h4>
                  <p>FREE Priority Shipping</p>
                  <div>
                    <Button
                      color='primary'
                      className='ant-btn-back btn btn-secondary'
                      onClick={() => setSparkNanoAdded(true)}
                      disabled={sparkNanoAdded}
                      style={{ color: '#fff' }}
                    >
                      {sparkNanoAdded ? 'Added' : 'Add to Cart'}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg='4' md='6'>
                <div>
                  <img
                    className='d-flex m-auto'
                    alt='Track Port'
                    src={require('assets/img/trackport.png').default}
                    style={{ maxWidth: '200px', margin: '0 auto' }}
                  />
                </div>
                <div>
                  <h5 style={{ marginTop: '15px', marginBottom: '10px' }}>TrackPort OBD Vehicle GPS Tracker</h5>
                  <h4 style={{ marginBottom: '10px' }}> $50</h4>
                  <p>FREE Priority Shipping</p>
                  <div>
                    <Button
                      color='primary'
                      className='ant-btn-back btn btn-secondary'
                      onClick={() => setTrackPortAdded(true)}
                      disabled={trackPortAdded}
                      style={{ color: '#fff' }}
                    >
                      {trackPortAdded ? 'Added' : 'Add to Cart'}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg='4' md='6'>
                <div>
                  <img
                    className='d-flex m-auto'
                    alt='Livewire'
                    src={require('assets/img/livewire.jpg').default}
                    style={{ maxWidth: '200px', margin: '0 auto' }}
                  />
                </div>
                <div>
                  <h5 style={{ marginTop: '15px', marginBottom: '10px' }}>Livewire 4 GPS Vehicle Tracker</h5>
                  <h4 style={{ marginBottom: '10px' }}> $50</h4>
                  <p>FREE Priority Shipping</p>
                  <div>
                    <Button
                      color='primary'
                      className='ant-btn-back btn btn-secondary'
                      onClick={() => setLivewireAdded(true)}
                      disabled={livewireAdded}
                      style={{ color: '#fff' }}
                    >
                      {livewireAdded ? 'Added' : 'Add to Cart'}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

//https://hosting.wialon.com/login.html?
//wialon_sdk_url=https%3A%2F%2Fhst%2Dapi%2Ewialon%2Ecom&
//access_token=46c4a901fb6a2f21d9fecb07942b361a4EC597682CA94CE27E42724F48BB4E7964AF102C&svc_error=0
