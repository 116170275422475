import React, { useState, useEffect, createRef } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
} from 'reactstrap'
import Select from 'react-select'
import ReactTable from 'components/ReactTable/ReactTable.js'

import {
  getapiAwait,
  postapiAwait,
  toBase64,
  putApiAwait,
  callextendedapisAwait,
} from '../../helper.js'
import config from '../../config.js'
import { Spinner } from 'reactstrap'

import { func } from 'prop-types'
import { isConstructorDeclaration } from 'typescript'
import { reactLocalStorage } from 'reactjs-localstorage'
import StreetViewPanormaWithAnOverlayView from './../../google_map/streetview'
import utility from './../../utility'

import NotificationAlert from 'react-notification-alert'
import { LocalPrintshopSVGIcon } from '@react-md/material-icons'
import UpcomingNotes from 'views/components/UpcomingNotes.js'

export default function CreateCustomer(props) {
  const [customerId, setCustomerId] = useState(null)
  const [customerFirstName, setCustomerFirstName] = useState(null)
  const [customerLastName, setCustomerLastName] = useState(null)
  const [customerDisplayName, setCustomerDisplayName] = useState(null)

  const [customerCompany, setCustomerCompany] = useState(null)

  const [homePhone, setHomePhone] = useState(null)
  const [mobilePhone, setMobilePhone] = useState(null)
  const [workPhone, setWorkPhone] = useState(null)
  const [primaryEmail, setPrimaryEmail] = useState(null)

  const [companyId, setCompanyId] = useState(null)

  const [jobTitle, setJobTitle] = useState(null)
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [notes, setNotes] = useState(null)
  const [parentId, setParentId] = useState(null)
  const [emails, setEmails] = useState(null)
  const [phones, setPhones] = useState(null)
  const [addresses, setAddresses] = useState(null)

  // [{"street":"3 No Tejturi Bazar West, Panthapath","city":"Dhaka","state":"","zip":"1215","country":"Bangladesh"}]
  const [street, setStreet] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [zip, setZip] = useState(null)
  const [country, setCountry] = useState(null)

  const [loading, setLoading] = useState(false)

  const [customerList, setCustomerList] = useState([])
  const [parentCustomerSelected, setParentCustomerSelected] = useState({
    value: '',
    label: '',
  })
  const [directions_lat_lng, setDirections_lat_lng] = useState({})

  const [toast, setToast] = useState(false)
  const [toastHeader, setToastHToastHeader] = useState('')
  const [toastBody, setToastBoToastBody] = useState('')

  const notificationAlertRef = React.useRef(null)

  function toogleToast(type, header = '', body = '') {
    setToast(type)
    setToastHToastHeader(header)
    setToastBoToastBody(body)
    setTimeout(() => {
      setToast(false)
    }, 2000)
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  async function onSaveCustomer(e) {
    // event.preventDefault();
    // console.log("value is here  ", customerName, customerWebsiteAddress, customerEmail, customerAddress, customerLogo);

    e.preventDefault()
    setLoading(true)

    let user = reactLocalStorage.getObject('user')

    // let geoAddress = await utility.getCustomerLatLng(resCustomer);
    // console.log("createCustomer->geoAddress: ", geoAddress);
    // let customer_lat_lng = {
    //     lat: geoAddress.lat,
    //     lng: geoAddress.lng,
    // }

    let res = null
    // [{"street":"364/B, Loskor Monjil,Haji Chan Miar Goli, Modhubag, Moghbazar","city":"Ramna","state":"","zip":"1217","country":""}]

    let primaryEmailIsValid = validateEmail(primaryEmail)
    let alternateEmailIsValid = true
    if (emails && emails.length > 0)
      alternateEmailIsValid = validateEmail(emails)

    if (!customerFirstName) {
      showNotificationMsg('Please enter the first name!', 'warning')
    } else if (!customerLastName) {
      showNotificationMsg('Please enter the last name!', 'warning')
    } else if (!customerDisplayName) {
      showNotificationMsg('Please enter the display name!', 'warning')
    } else if (!primaryEmail || !primaryEmailIsValid) {
      showNotificationMsg('Please enter a valid primary email!', 'warning')
    }
    // else if (!alternateEmailIsValid) {
    //   showNotificationMsg('Please enter a valid alternate email!', 'warning')
    // }
    else {
      if (props.mode === 'new') {
        let postData = {
          companyId: user.companyId,
          addresses: [
            {
              street: street ? street : '',
              city: city ? city : '',
              state: state ? state : '',
              zip: zip ? zip : '',
              country: country ? country : '',
            },
          ],
          company: customerCompany ? customerCompany : '',
          display_name: customerDisplayName,
          emails: [
            {
              email: emails ? emails : '',
            },
          ],
          first_name: customerFirstName,
          home_phone: homePhone ? homePhone : '',
          job_title: jobTitle ? jobTitle : '',
          last_name: customerLastName,
          lat: '',
          lng: '',
          mobile_phone: mobilePhone ? mobilePhone : '',
          notes: notes ? notes : '',
          parent_id: parentCustomerSelected.value
            ? parentCustomerSelected.value
            : '',
          phones: [
            {
              type: 'primary',
              phone_no: phones ? phones : '',
              note: 'Test',
            },
          ],
          primary_email: primaryEmail,
          work_phone: workPhone ? workPhone : '',
        }

        console.log('onSaveCustomer->if->postData: ', postData)
        res = await postapiAwait('customers', postData)
      } else {
        let postData = {
          id: props.item_id,
          companyId: user.companyId,
          addresses: [
            {
              street: street ? street : '',
              city: city ? city : '',
              state: state ? state : '',
              zip: zip ? zip : '',
              country: country ? country : '',
            },
          ],
          company: customerCompany ? customerCompany : '',
          display_name: customerDisplayName,
          emails: [
            {
              email: emails ? emails : '',
            },
          ],
          first_name: customerFirstName,
          home_phone: homePhone ? homePhone : '',
          job_title: jobTitle ? jobTitle : '',
          last_name: customerLastName,
          lat: lat,
          lng: lng,
          mobile_phone: mobilePhone ? mobilePhone : '',
          notes: notes ? notes : '',
          parent_id: parentCustomerSelected.value
            ? parentCustomerSelected.value
            : '',
          phones: [
            {
              type: 'primary',
              phone_no: phones ? phones : '',
              note: 'Test',
            },
          ],
          primary_email: primaryEmail,
          work_phone: workPhone ? workPhone : '',
        }

        console.log('onSaveCustomer->if->postData: ', postData)
        res = await putApiAwait(`customers/${props.item_id}`, postData)
      }

      console.log('customer insert: ', res)

      if (props.mode === 'edit') {
        // toogleToast(true, 'Success', 'Customer Update Successfull')
        showNotificationMsg('Customer Updated Successfully', 'success')
      } else {
        // toogleToast(true, 'Success', 'Customer Add Successfull')
        showNotificationMsg('Customer Added Successfully', 'success')
      }
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    // setLoading(true);
    async function fetchAndSetData() {
      let user = reactLocalStorage.getObject('user')

      console.log('props: ', props)

      if (!companyId && user.companyId !== '-1' && props.mode === 'edit') {
        setCompanyId(user.companyId)

        let resCustomer = await getapiAwait(`customers/${props.item_id}`, {})
        if (resCustomer.data) {
          resCustomer = resCustomer.data

          console.log('resCustomer: ', resCustomer)

          setCustomerFirstName(resCustomer.first_name)
          setCustomerLastName(resCustomer.last_name)
          setCustomerDisplayName(resCustomer.display_name)
          setCustomerCompany(resCustomer.company)
          setHomePhone(resCustomer.home_phone)
          setMobilePhone(resCustomer.mobile_phone)
          setWorkPhone(resCustomer.work_phone)
          setPrimaryEmail(resCustomer.primary_email)
          setJobTitle(resCustomer.job_title)
          setLat(resCustomer.lat)
          setLng(resCustomer.lng)
          setNotes(resCustomer.notes)
          setParentId(resCustomer.parent_id)

          setEmails(
            resCustomer.emails && resCustomer.emails.length > 0
              ? resCustomer.emails[0].email
              : ''
          )
          setPhones(
            resCustomer.phones && resCustomer.phones.length > 0
              ? resCustomer.phones[0].phone_no
              : ''
          )

          let _address =
            resCustomer.addresses && resCustomer.addresses.length > 0
              ? resCustomer.addresses[0]
              : { street: '', city: '', state: '', zip: '', country: '' }

          console.log('_address: ', _address)
          setStreet(_address.street)
          setCity(_address.city)
          setState(_address.state)
          setZip(_address.zip)
          setCountry(_address.country)

          // setDirections_lat_lng({
          //     origin: { ...current_user_lat_lng },
          //     destination: { ...customer_lat_lng },
          // })
          //                 company: ""
          // email: "alamrezoanul@gmail.com"
          // homePhone: "01859459229"
          // id: "0d1d6b0c-394c-432b-8769-4a602ded58c8"
          // name: "c2 c2"
          console.log('props.customers: ', props.customers)
          const tempList = []
          props.customers.map((item, i) => {
            console.log('item: ', item)
            tempList.push({
              value: item.id,
              label: `${item.name}`,
            })

            if (item.id == resCustomer.parent_id) {
              setParentCustomerSelected({
                value: item.id,
                label: `${item.name}`,
              })
            }
          })

          setCustomerList(tempList)
        }
      } else {
        const getcustomers = await callextendedapisAwait(
          'customers/allCustomersByCompany',
          { companyId: user.companyId }
        )

        console.log(
          'async useEffect() {->getcustomers.data: ',
          getcustomers.data
        )

        const tempList = []
        getcustomers.data.map((item, i) => {
          console.log('item: ', item)
          tempList.push({
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          })
        })

        setCustomerList(tempList)

        // setLoading(false);
      }
      if (!companyId && user.companyId === '-1') {
        setCompanyId('-1')
      }
    }
    fetchAndSetData()
  }, [])
  const options = [
    { value: 'admin', label: 'Admin' },
    { value: 'employee', label: 'Employee' },
  ]
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }
  return (
    <div className={`create-customer ${customerId === '-1' ? '' : 'content'}`}>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Row>
        <Col>
          <Card>
            <Container>
              <CardHeader>
                <div>
                  <span>
                    <h4
                      className='title'
                      style={{ marginTop: '14px', marginBottom: 0 }}
                    >
                      {props.mode === 'new'
                        ? 'Setup your Customer'
                        : 'Edit your Customer'}
                    </h4>
                  </span>
                  <Button
                    id='ant-btn-back'
                    type='button'
                    className={`ant-btn-primary ant-btn-back customer-back`}
                    icon='back'
                    size='default'
                    style={{ marginLeft: 'calc(100% - 115px)' }}
                    onClick={(e) => {
                      props.setView_state('datatable')
                      props.fetchCustomers()
                    }}
                  >
                    <span>Back</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <h5
                    style={{
                      marginLeft: '15px',
                      marginBottom: '10px',
                    }}
                  >
                    Primary Details
                  </h5>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pr-md-1 custom-padding' md='6'>
                      <FormGroup>
                        <label>
                          First Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          // ref={(_ref) => refCustomerName = _ref}
                          value={customerFirstName}
                          onChange={(e) => setCustomerFirstName(e.target.value)}
                          placeholder='First Name'
                          // defaultValue="Mike"
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pr-md-1 custom-padding' md='6'>
                      <FormGroup>
                        <label>
                          Last Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          // ref={(_ref) => refCustomerName = _ref}
                          value={customerLastName}
                          onChange={(e) => setCustomerLastName(e.target.value)}
                          placeholder='Last Name'
                          // defaultValue="Mike"
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>{' '}
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1 custom-padding'>
                      <FormGroup>
                        <label>
                          Display Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          id='inp_customer_website_address'
                          value={customerDisplayName}
                          onChange={(e) =>
                            setCustomerDisplayName(e.target.value)
                          }
                          placeholder='Paul'
                          // defaultValue="Mike"
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>{' '}
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1 custom-padding'>
                      <FormGroup>
                        <label>
                          Primary Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          id='inp_customer_address'
                          value={primaryEmail}
                          onChange={(e) => setPrimaryEmail(e.target.value)}
                          placeholder='Primary Email'
                          type='email'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pr-md-1 custom-padding'>
                      <FormGroup>
                        <label>Company</label>
                        <Input
                          value={customerCompany}
                          onChange={(e) => setCustomerCompany(e.target.value)}
                          placeholder='Google Inc.'
                          // defaultValue="Mike"
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Home Phone</label>
                        <Input
                          id='inp_customer_address'
                          value={homePhone}
                          onChange={(e) => setHomePhone(e.target.value)}
                          placeholder='Home Phone'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Mobile Phone</label>
                        <Input
                          id='inp_customer_address'
                          value={mobilePhone}
                          onChange={(e) => setMobilePhone(e.target.value)}
                          placeholder='Mobile Phone'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Work Phone</label>
                        <Input
                          id='inp_customer_address'
                          value={workPhone}
                          onChange={(e) => setWorkPhone(e.target.value)}
                          placeholder='Work Phone'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h5
                    style={{
                      marginLeft: '15px',
                      marginBottom: '10px',
                      marginTop: '20px',
                    }}
                  >
                    Additional Details
                  </h5>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Job Title</label>
                        <Input
                          id='inp_customer_address'
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                          placeholder='Job Title'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Short Note</label>
                        <Input
                          id='inp_customer_address'
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          placeholder='Short Note'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Parent Customer(If any)</label>
                        <Select
                          className='react-select info'
                          classNamePrefix='react-select'
                          placeholder='Choose Parent Customer (if any)'
                          name='multipleSelect'
                          closeMenuOnSelect={true}
                          isMulti={false}
                          value={parentCustomerSelected}
                          onChange={(value) => {
                            setParentCustomerSelected(value)
                            console.log(value)
                          }}
                          options={customerList}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Billing Address</label>
                        <Input
                          id='inp_customer_address'
                          value={emails}
                          onChange={(e) => setEmails(e.target.value)}
                          placeholder='Billing Email'
                          type='email'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h5
                    style={{
                      marginLeft: '15px',
                      marginBottom: '10px',
                      marginTop: '20px',
                    }}
                  >
                    Address
                  </h5>
                  <Row style={{ maxWidth: '100%' }}>
                    {/* <Col className='pl-md-1' md='2'>
                  <FormGroup>
                    <label>Phone</label>
                    <Input
                      id='inp_customer_address'
                      value={phones}
                      onChange={(e) => setPhones(e.target.value)}
                      placeholder='Phones'
                      type='text'
                    />
                  </FormGroup>
                </Col> */}
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Street</label>
                        <Input
                          id='inp_customer_address'
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                          placeholder='Street'
                          type='text'
                        />

                        {/* [{"street":"3 No Tejturi Bazar West, Panthapath","city":"Dhaka","state":"","zip":"1215","country":"Bangladesh"}] */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>City</label>
                        <Input
                          id='inp_customer_address'
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          placeholder='City'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>State</label>
                        <Input
                          id='inp_customer_address'
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          placeholder='State'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Zip</label>
                        <Input
                          id='inp_customer_address'
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                          placeholder='Zip'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: '100%' }}>
                    <Col className='pl-md-1'>
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          id='inp_customer_address'
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          placeholder='Country'
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                {/* <Row>
                            <Col md='12'>
                                <StreetViewPanormaWithAnOverlayView
                                    destination={directions_lat_lng.destination}
                                />
                            </Col>
                        </Row> */}
              </CardBody>
              <CardFooter
                style={{ textAlign: 'center', paddingBottom: '50px' }}
              >
                <Button
                  className='btn-fill'
                  color='primary'
                  type='submit'
                  onClick={(e) => {
                    onSaveCustomer(e)
                  }}
                >
                  {loading && (
                    <Spinner color='white' size='sm' className='mr-2' />
                  )}
                  Save
                </Button>
              </CardFooter>
            </Container>
          </Card>
        </Col>
      </Row>
      {!loading && <UpcomingNotes />}
    </div>
  )
}
