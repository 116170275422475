import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import ReactTooltip from 'react-tooltip'

import ReactTable from 'components/ReactTable/ReactTable.js'

import { reactLocalStorage } from 'reactjs-localstorage'

import {
  callapi,
  callextendedapisAwait,
  insertActivityLog,
  callExtendedApiPostAwait,
  delapi,
  getapiAwait,
} from '../../helper.js'
import config from '../../config.js'
import cloneDeep from 'lodash/cloneDeep'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func } from 'prop-types'
import ResProcess from './ResProcess.js'
import NotificationAlert from 'react-notification-alert'
import UpcomingNotes from 'views/components/UpcomingNotes.js'

export default function Job() {
  const [loading, setLoading] = useState(false)
  const [jobData, setJobData] = useState([])
  const [data, setData] = React.useState([])
  const [job_id, setJob_id] = React.useState(null)
  const [view_state, setView_state] = React.useState('datatable')
  const [modal, setModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [customerName, setCustomerName] = React.useState('')

  const notificationAlertRef = React.useRef(null)

  const user = reactLocalStorage.getObject('user')
  console.log('user: ', user)

  async function fetchData() {
    if (setLoading) setLoading(true)
    var resp = []
    // console.log('user.role: ', user.role)
    if (view_state === 'template') {
      //
      resp = await callextendedapisAwait(
        'jobtemplatescustom/templatesByCustomer',
        {
          companyId: user.companyId,
        }
      )
      console.log('jobtemplatescustom/templatesByCustomer: ', resp.data)
    } else {
      if (user.role === 'admin' || user.role === 'super admin') {
        resp = await callextendedapisAwait('jobscustom/jobAllWithCustomer/', {
          companyId: user.companyId,
        })
        console.log('jobscustom/jobAllWithCustomer/ ', resp.data)
      } else {
        resp = await callextendedapisAwait('jobscustom/jobMyAll/' + user.id, {})
        console.log('jobscustom/jobMyAll/ ', resp.data)
      }
    }
    console.log('user ', user)
    let dataCalculation = null
    if (!resp.data.error) dataCalculation = new ResProcess(resp.data)
    else {
      console.log('resp.data.error: ', resp.data.error)
    }
    let dataTableDataArr =
      dataCalculation && dataCalculation.jobAll ? dataCalculation.jobAll : []
    console.log(
      'async getAllEstimates() {->dataTableDataArr: ',
      dataTableDataArr
    )
    if (setLoading) setLoading(false)
    setJobData(dataTableDataArr)
    checkIfCalledFromSchedule(dataTableDataArr)
  }
  function reRenderTableData() {
    fetchData()
  }
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (view_state === 'template') fetchData()
  }, [view_state])

  function checkIfCalledFromSchedule(dataTableDataArr) {
    const jobIdFromSchedule = reactLocalStorage.getObject('fromSchedule')
    reactLocalStorage.setObject('fromSchedule', '')
    console.log('Job id from schedule: ', jobIdFromSchedule)
    if (jobIdFromSchedule && jobIdFromSchedule.length > 0) {
      const targetJobItem = dataTableDataArr.find(
        (item) => item.job_id === jobIdFromSchedule
      )
      // console.log('targetJobItem: ', targetJobItem)
      // console.log('targetJobItemId: ', jobIdFromSchedule)
      if (targetJobItem.first_name) {
        setJob_id(jobIdFromSchedule)
        let getTargetCustomerName = targetJobItem.first_name ? `${targetJobItem.first_name} ${targetJobItem.last_name}` : ''
        setCustomerName(getTargetCustomerName)
        setView_state('edit_job')
      }
    }
  }
  function prepareForEdit(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    }
    // console.log('target job id: ', targetRow.getAttribute('data-id'))
    // let readableJobId = parseInt(targetRow.firstChild.innerText)
    let targetJobId = targetRow.getAttribute('data-id')
    let jobItem = data.find(
      (item) => targetJobId.toString() === item.id.toString()
    )
    // console.log('target job item: ', jobItem)
    editJob(jobItem)
  }

  function setMousePointer(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    } else {
      targetRow = e.target.parentElement.parentElement.parentElement
    }
    if (targetRow && !targetRow.style.cursor) {
      targetRow.style.cursor = 'pointer'
      targetRow.style.userSelect = 'none'
    }
  }

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (
      notificationAlertRef !== null &&
      notificationAlertRef.current !== null
    ) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }

  function loadTableData() {
    const data = jobData.map((prop, key) => {
      return {
        _id: key,
        id: prop.job_id,
        name: prop.first_name ? prop.first_name + ' ' + prop.last_name : '',
        total: prop.job_total,
        status: prop.status,
        jobId: prop.human_readable_id,
        actions: (
          <div className='actions-right'>
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                editJob(obj)
              }}
              color='warning'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-edit'
              data-tip='Edit'
            >
              <i className='tim-icons icon-pencil' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-edit'
              className='tooltip-for-edit'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                setModal(true)
                setDeleteId(obj.id)
                console.log('data is deleted id', obj.id)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-delete'
              data-tip='Delete'
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>
            <ReactTooltip
              id='tooltip-for-delete'
              className='tooltip-for-delete'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />{' '}
          </div>
        ),
      }
    })
    setData(data)
  }
  useEffect(() => {
    loadTableData()
  }, [jobData])
  function editJob(job_for_estimate) {
    setJob_id(job_for_estimate.id)
    // let { name: getTargetCustomerName } = data.find(
    //   (dataItem) => dataItem.id === job_for_estimate
    // )

    let getTargetCustomerName = job_for_estimate.first_name ? `${job_for_estimate.first_name} ${job_for_estimate.last_name}` : ''

    setCustomerName(getTargetCustomerName)
    // console.log('getCustomer naem ', getTargetCustomerName)
    // console.log('Customer naem ', customerName)

    setView_state(view_state === 'template' ? 'edit_from_template' : 'edit_job')
  }
  const toggle = () => setModal(!modal)
  function onOk() {
    setModal(!modal)

    let deletejob = null
    if (view_state === 'template') {
      deletejob = delapi('jobtemplatescustom/deletebyid/' + deleteId + '?')
    } else {
      deletejob = delapi('jobs/' + deleteId + '?')
    }
    deletejob.then((data) => {
      if (data.count === '0') {
        showNotificationMsg('Sorry! Cannot delete the job.', 'warning')
      } else {
        showNotificationMsg('Job has been deleted successfully', 'success')
        var newdata = jobData
        console.log('data is before', newdata)
        newdata.find((o, i) => {
          if (o.job_id === deleteId) {
            newdata.splice(i, 1)
            setDeleteId(null)
            return true
          }
          return false
        })
        console.log('data is after', newdata)
        setJobData(newdata)
        loadTableData()
      }
    })
  }
  if (loading) {
    return (
      <>
        <div
          className='content'
          style={{ textAlign: 'center', transition: 'none' }}
        >
          <Spinner color='white' size='sm' className='mr-2' />
        </div>
      </>
    )
  } else {
    if (view_state == 'datatable') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this job?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the job information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div
            className='content job'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader style={{ textAlign: 'right' }}>
                    {/* <CardTitle tag="h4">React Table</CardTitle> */}
                    <Button
                      color='primary'
                      onClick={() => setView_state('create_job')}
                    >
                      + Add New
                    </Button>
                    {/* <Button color="success">
                                            <i className="tim-icons icon-single-copy-04" />  Duplicate
                                        </Button> */}
                  </CardHeader>
                  <CardBody>
                    {console.log('data is ', data, modal)}

                    <div className='job-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'jobId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Total',
                            accessor: 'total',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state === 'template') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this job Template?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the job template information once you
                deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div
            className='content job'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <div>
                      <span>
                        <h4>Click a row to select a template</h4>
                      </span>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        id='ant-btn-back'
                        type='button'
                        className={`ant-btn-primary ant-btn-back btn`}
                        icon='back'
                        size='default'
                        onClick={(e) => {
                          setView_state('create_job')
                        }}
                      >
                        <span>Back</span>
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {console.log('data is ', data, modal)}

                    <div className='job-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'jobId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Total',
                            accessor: 'total',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state == 'edit_job') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type
                your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button color='primary' onClick={() => {}}>
                  Add Note
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div className='content'>
            <LineItemsForm
              mode='edit'
              for='job'
              item_id={job_id}
              itemName={customerName}
              setView_state={setView_state}
              showNotificationMsg={showNotificationMsg}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    } else if (view_state == 'edit_from_template') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div className='content'>
            <LineItemsForm
              from='template'
              mode='edit'
              for='job'
              item_id={job_id}
              itemName={customerName}
              setView_state={setView_state}
              showNotificationMsg={showNotificationMsg}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    } else if (view_state == 'create_job') {
      return (
        <>
          <div>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type
                your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button color='primary' onClick={() => {}}>
                  Add Note
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div className='content'>
            <LineItemsForm
              mode='new'
              for='job'
              item_id={job_id}
              view_state={view_state}
              setView_state={setView_state}
              showNotificationMsg={showNotificationMsg}
              fetchJobData={fetchData}
              reRenderTableData={reRenderTableData}
              setModal={setModal}
            />
          </div>
        </>
      )
    }
  }
}
