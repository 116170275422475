import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/worklocker.scss?v=1.2.0";
import "assets/demo/demo.css";

import ApproveDenyEstimate from './components/admin/ApproveDenyEstimate';
import ResetPassword from 'views/pages/ResetPassword'

ReactDOM.render(<App />, document.getElementById("root"));

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
        <Route exact path='/approvedenyestimate' component={ApproveDenyEstimate} />
        {/* <Route exact path='/auth/reset-password' component={ResetPassword} /> */}

        <Redirect from="/" to="/admin/dashboard" />

      </Switch>
    </BrowserRouter>
  )
}