import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

export default function TinyMCEeditor(props) {
  const editorRef = useRef(null)

  const onChange = (content) => {
    console.log('mce text: ', content)
    props.setNote(content)
  }

  React.useEffect(() => {
    if (editorRef.current) editorRef.current.getContent()
  }, [])
  return (
    <>
      <Editor
        apiKey='rlxdj18jtffqdhedsmpz8uurgsyn84k3qdfssb7xx0iciv3x'
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={props.initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'textcolor',
          ],
          toolbar:
            'undo redo | code | formatselect | ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:"Poppins", sans-serif; font-size:14px }',
        }}
        onEditorChange={onChange}
      />
    </>
  )
}
