class Demo {
  constructor(estimateRes, jobRes, invoiceRes) {
    // this.data = data;
    this.openEstimate = this.calculateOpenEstimate(estimateRes)
    this.estimateAllInfo = this.calculateEstimateAllInfo(estimateRes)
    this.jobAllInfo = this.calculateJobAllInfo(jobRes)
    this.invoiceAllInfo = this.calculateInvoiceAllInfo(invoiceRes)
    this.chart1Data = this.generateChart(
      this.estimateAllInfo,
      this.jobAllInfo,
      this.invoiceAllInfo
    )
    // this.generateAbc1();
  }
  //estimate
  calculateOpenEstimate(data) {
    var i
    let open_estimate = 0

    for (i = 0; i < data.length; i++) {
      if (
        (data[i].estimate_approved === 0 &&
          data[i].estimate_disapproved === 0) ||
        (data[i].estimate_approved === 0 && !data[i].estimate_disapproved) ||
        (!data[i].estimate_approved && data[i].estimate_disapproved === 0) ||
        (!data[i].estimate_approved && !data[i].estimate_disapproved)
      ) {
        open_estimate++
      }
    }
    // console.log('this result from both null: ', open_estimate)
    return open_estimate
  }
  calculateEstimateAllInfo(data) {
    var i
    let open_estimate = 0

    for (i = 0; i < data.length; i++) {
      if (
        (data[i].estimate_approved === 0 &&
          data[i].estimate_disapproved === 0) ||
        (data[i].estimate_approved === 0 && !data[i].estimate_disapproved) ||
        (!data[i].estimate_approved && data[i].estimate_disapproved === 0) ||
        (!data[i].estimate_approved && !data[i].estimate_disapproved)
      ) {
        open_estimate++
      }
    }
    var dataList = data
    const result = {}
    let c = 0
    for (const data of dataList) {
      const date = data.estimate_created_at?.substr(0, 10)
      result[date] = {
        approved: 0,
        disApproved: 0,
        open: 0,
        timeStamp: 0,
      }
      c++
    }

    for (const data of dataList) {
      const date = data.estimate_created_at?.substr(0, 10)
      let key = ''
      if (
        (data.estimate_approved === 0 && data.estimate_disapproved === 0) ||
        (data.estimate_approved === 0 && !data.estimate_disapproved) ||
        (!data.estimate_approved && data.estimate_disapproved === 0) ||
        (!data.estimate_approved && !data.estimate_disapproved)
      ) {
        key = 'open'
      } else if (data.estimate_approved === 1) {
        key = 'approved'
      } else if (data.estimate_disapproved === 1) {
        key = 'disApproved'
      }
      const old = result[date][key]
      const newValue = result[date]
      newValue[key] = old + 1
      result[date] = newValue
    }
    console.log('the result is here------', result)
    var p
    var estimates_all = []
    var all_date = Object.keys(result)
    console.log('the result of all date: ', all_date)
    for (p = 0; p < all_date.length; p++) {
      var myDate = all_date[p].split('-')
      var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2])
      estimates_all.push([
        all_date[p],
        result[all_date[p]].open,
        result[all_date[p]].approved,
      ])
    }
    console.log('the result2 is here------', estimates_all)

    var data = estimates_all
    var dataLength = data.length
    var i = 0
    var ohlc = [],
      ohlc2 = [],
      ohlc3 = []
    for (i; i < dataLength; i += 1) {
      ohlc.push(data[i][0])
      ohlc2.push(
        data[i][1] // open
      )
      ohlc3.push(
        data[i][2] //approved
      )
    }
    // let open_estimate= open_estimate
    // let estimates_all= estimates_all
    // let dateRange= ohlc
    // let openEstimatesInDateRange= ohlc2
    // let approvedEstimatesInDateRange= ohlc3
    // let openEstimatesInDateRangeBackup= ohlc2
    // let approvedEstimatesInDateRangeBackup= ohlc3
    // let dateRangeBackup= ohlc

    var estimateAllInfoObj = {
      open_estimate: open_estimate,
      estimates_all: estimates_all,
      dateRange: ohlc,
      openEstimatesInDateRange: ohlc2,
      approvedEstimatesInDateRange: ohlc3,

      openEstimatesInDateRangeBackup: ohlc2,
      approvedEstimatesInDateRangeBackup: ohlc3,
      dateRangeBackup: ohlc,
    }
    console.log('estimateAllofObj : ', estimateAllInfoObj)

    return estimateAllInfoObj
  }
  //job
  calculateJobAllInfo(data) {
    var i
    let open_job = 0
    for (i = 0; i < data.length; i++) {
      if (data[i].job_completed === 0 || !data[i].job_completed) {
        open_job++
      }
    }
    console.log('Number of open_job: ', open_job)
    var dataList = data
    const result = {}
    var result2 = []
    let c = 0
    for (const data of dataList) {
      const date = data.job_created_at.substr(0, 10)
      result[date] = {
        completed: 0,
        open: 0,
        timeStamp: 0,
      }
      c++
    }
    for (const data of dataList) {
      const date = data.job_created_at.substr(0, 10)
      let key = ''
      if (data.job_completed === 0 || !data.job_completed) {
        key = 'open'
      } else if (data.job_completed === 1) {
        key = 'completed'
      }
      const old = result[date][key]
      const newValue = result[date]
      newValue[key] = old + 1
      result[date] = newValue
    }
    console.log('the result of daterange loop is here------', result)
    var p
    var job_all = []
    var all_date = Object.keys(result)
    for (p = 0; p < all_date.length; p++) {
      var myDate = all_date[p].split('-')
      var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2])
      job_all.push([
        all_date[p],
        result[all_date[p]].open,
        result[all_date[p]].completed,
      ])
    }
    console.log('the result of daterange loop is here------', job_all)

    var data = job_all
    var dataLength = data.length
    var i = 0
    var ohlc = [],
      ohlc2 = [],
      ohlc3 = []
    for (i; i < dataLength; i += 1) {
      ohlc.push(
        data[i][0] // the date
      )
      ohlc2.push(
        data[i][1] // open
      )
      ohlc3.push(
        data[i][2] //completed
      )
    }
    var jobAllInfoObj = {
      open_job: open_job,
      jobs_all: job_all,
      dateRangeforJobs: ohlc,
      openJobsInDateRange: ohlc2,
      completedJobsInDateRange: ohlc3,

      openJobsInDateRangeBackup: ohlc2,
      completedJobsInDateRangeBackup: ohlc3,
      dateRangeforJobsBackup: ohlc,
    }
    console.log('jobAllofObj : ', jobAllInfoObj)
    return jobAllInfoObj
  }
  //invoice
  calculateInvoiceAllInfo(data) {
    var i
    let open_invoice = 0
    for (i = 0; i < data.length; i++) {
      if (data[i].invoice_paid === 0 || !data[i].invoice_paid) {
        open_invoice++
      }
    }
    console.log('Number of open_invoice: ', open_invoice)
    var dataList = data
    const result = {}
    var result2 = []
    let c = 0
    for (const data of dataList) {
      const date = data.invoice_created_at.substr(0, 10)
      result[date] = {
        paid: 0,
        open: 0,
        timeStamp: 0,
      }
      c++
    }

    for (const data of dataList) {
      const date = data.invoice_created_at.substr(0, 10)
      let key = ''
      if (data.invoice_paid === 0 || !data.invoice_paid) {
        key = 'open'
      } else if (data.invoice_paid === 1) {
        key = 'paid'
      }
      const old = result[date][key]
      const newValue = result[date]
      newValue[key] = old + 1
      result[date] = newValue
    }
    //console.log("the result of daterange loop is here------", result);
    var p
    var invoice_all = []
    var all_date = Object.keys(result)
    for (p = 0; p < all_date.length; p++) {
      var myDate = all_date[p].split('-')
      var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2])
      invoice_all.push([
        all_date[p],
        result[all_date[p]].open,
        result[all_date[p]].paid,
      ])
    }
    console.log('the result of daterange loop is here------', invoice_all)

    var data = invoice_all
    var dataLength = data.length
    var i = 0
    var ohlc = [],
      ohlc2 = [],
      ohlc3 = []
    for (i; i < dataLength; i += 1) {
      ohlc.push(
        data[i][0] // the date
      )
      ohlc2.push(
        data[i][1] // open
      )
      ohlc3.push(
        data[i][2] //completed
      )
    }
    const invoiceAllInfoObj = {
      open_invoice: open_invoice,
      invoices_all: invoice_all,
      dateRangeforInvoices: ohlc,
      openInvoicesInDateRange: ohlc2,
      paidInvoicesInDateRange: ohlc3,

      openInvoicesInDateRangeBackup: ohlc2,
      paidInvoicesInDateRangeBackup: ohlc3,
      dateRangeforInvoicesBackup: ohlc,
    }

    return invoiceAllInfoObj
  }
  generateChart(estimateAllInfo, jobAllInfo, invoiceAllInfo) {
    var chartExample11 = {
      data1: (canvas) => {
        let ctx = canvas.getContext('2d')

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50)
        gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)')
        gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)')
        gradientStroke.addColorStop(0, 'rgba(29,140,248,0)') //blue colors

        let grd = ctx.createLinearGradient(0, 230, 0, 50)
        grd.addColorStop(1, 'rgba(0, 214, 180, 0.2)')
        grd.addColorStop(0.4, 'rgba(0, 214, 180, 0.0)')
        grd.addColorStop(0, 'rgba(0, 214, 180, 0)') //light sky color

        return {
          labels: estimateAllInfo.dateRange,
          datasets: [
            {
              label: 'Open Estimates',
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: '#1f8ef1',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#1f8ef1',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1f8ef1',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: estimateAllInfo.openEstimatesInDateRange,
            },
            {
              label: 'Approved Estimates',
              fill: true,
              backgroundColor: grd,
              borderColor: '#00d6b4',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#00d6b4',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#00d6b4',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: estimateAllInfo.approvedEstimatesInDateRange,
            },
          ],
        }
      },
      data2: (canvas) => {
        let ctx = canvas.getContext('2d')

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50)

        gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)')
        gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)')
        gradientStroke.addColorStop(0, 'rgba(29,140,248,0)') //blue colors

        let grd = ctx.createLinearGradient(0, 230, 0, 50)

        grd.addColorStop(1, 'rgba(0, 214, 180, 0.2)')
        grd.addColorStop(0.4, 'rgba(0, 214, 180, 0.0)')
        grd.addColorStop(0, 'rgba(0, 214, 180, 0)') //light sky color

        return {
          labels: jobAllInfo.dateRangeforJobs,
          datasets: [
            {
              label: 'Open Jobs',
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: '#1f8ef1',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#1f8ef1',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1f8ef1',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: jobAllInfo.openJobsInDateRange,
            },
            {
              label: 'Finished Jobs',
              fill: true,
              backgroundColor: grd,
              borderColor: '#00d6b4',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#00d6b4',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#00d6b4',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: jobAllInfo.completedJobsInDateRange,
            },
          ],
        }
      },
      data3: (canvas) => {
        let ctx = canvas.getContext('2d')

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50)

        gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)')
        gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)')
        gradientStroke.addColorStop(0, 'rgba(29,140,248,0)') //blue colors

        let grd = ctx.createLinearGradient(0, 230, 0, 50)

        grd.addColorStop(1, 'rgba(0, 214, 180, 0.2)')
        grd.addColorStop(0.4, 'rgba(0, 214, 180, 0.0)')
        grd.addColorStop(0, 'rgba(0, 214, 180, 0)') //light sky color

        return {
          labels: invoiceAllInfo.dateRangeforInvoices,
          datasets: [
            {
              label: 'Open Invoices',
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: '#1f8ef1',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#1f8ef1',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1f8ef1',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: invoiceAllInfo.openInvoicesInDateRange,
            },
            {
              label: 'Paid Invoices',
              fill: true,
              backgroundColor: grd,
              borderColor: '#00d6b4',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#00d6b4',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#00d6b4',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: invoiceAllInfo.paidInvoicesInDateRange,
            },
          ],
        }
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          fillStyle: '#FFFFFF',
          fontColor: '#FFFFFF',
        },
        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest',
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(29,140,248,0.0)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                suggestedMin: 0,
                stepSize: 2,
                // suggestedMax: 100,
                padding: 20,
                fontColor: '#9a9a9a',
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(29,140,248,0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                padding: 20,
                fontColor: '#9a9a9a',
              },
            },
          ],
        },
      },
    }
    return chartExample11
  }
  generateAbc1() {
    this.abc1 = 10
  }
  getNum() {
    return 1
  }
}
export default Demo
