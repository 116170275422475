import React, { PureComponent, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import * as momento from 'moment'
import moment from 'moment-timezone'
import Select from 'react-select'
import { DatePicker } from 'antd'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import { reactLocalStorage } from 'reactjs-localstorage'
import NotificationAlert from 'react-notification-alert'

import cloneDeep from 'lodash/cloneDeep'
import { Spinner } from 'reactstrap'
// import config from "./../config";
import {
  callapi,
  callextendedapisAwait,
  postapiAwait,
  insertActivityLog,
  getapiAwait,
  patchApiAwait,
  callExtendedApiPatchAwait,
  callExtendedApiPostAwait,
} from '../../helper'
import config from '../../config'
import $ from 'jquery'
import Grid from 'antd/lib/card/Grid'
import UpcomingNotes from 'views/components/UpcomingNotes'
const { RangePicker } = DatePicker
const Schedules = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [view_state, setView_state] = React.useState(
    !props.from ? 'all' : props.from
  )
  const [events_ids, setEvents_ids] = React.useState([])
  const [jobEvents, setJobEvents] = React.useState([{}])
  const [estimateEvents, setEstimateEvents] = React.useState([{}])
  const [currentStartDate, setCurrentStartDate] = React.useState('')
  const [currentEndDate, setCurrentEndDate] = React.useState('')

  const [dateRangedata, setDateRangeData] = React.useState({})
  const [eventSelected, setEventSelected] = React.useState({})
  const [addEventModalVisible, setAddEventModalVisible] = React.useState(false)
  const [scheduleMode, setScheduleMode] = React.useState('')

  const user = reactLocalStorage.getObject('user')
  const notificationAlertRef = React.useRef(null)
  // console.log('user from schedules: ', user).

  const allCalendarRef = React.useRef(null)

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (
      notificationAlertRef !== null &&
      notificationAlertRef.current !== null
    ) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }

  const getJobSchedules = async () => {
    console.log('I am clicked')
    var resp = await callextendedapisAwait(
      'schedulesforjobcustom/scheduleForEachJob/' + props.item_id,
      {}
    )
    resp = resp.data
    console.log('resp of job schedules: ', resp)
    var eventsLocal = []
    var events_ids1 = []

    for (var j = 0; j < resp.length; ++j) {
      let start = resp[j].start_date_time.toString()
      let end = resp[j].end_date_time.toString()

      let anEvent = {
        id: parseInt(j),
        originalId: resp[j].scheduleforjobId,
        jobId: resp[j].jobId,
        human_readable_id: resp[j].human_readable_id,
        title: `${resp[j].human_readable_id} - ${resp[j].display_name}`,
        assigned_userNames: await getUserNamesFromId(resp[j].assigned_userIds),
        start: start,
        end: end,
        startDateTime: start,
        endDateTime: end,
        type: 'Job',
      }

      // console.log('anEvent from job: ', anEvent)

      let assignedUserIds = JSON.parse(resp[j].assigned_userIds)
      let userIsFound = assignedUserIds.find((item) => item.value === user.id)

      if (user.role === 'admin' || user.role === 'super admin' || userIsFound) {
        eventsLocal.push(anEvent)
        events_ids1.push({
          id: parseInt(j),
          value: resp[j].id,
        })
      }
    }

    console.log('job events: ', eventsLocal)

    setLoading(false)
    setJobEvents(eventsLocal)
    setEvents_ids(events_ids1)
  }

  const getUserNamesFromId = async (assigned_userIds) => {
    console.log('getUserNamesFromId->assigned_userIds: ', assigned_userIds)
    let retArr = []

    let assigned_userIdsJSON = JSON.parse(assigned_userIds)
    for (let i = 0; i < assigned_userIdsJSON.length; ++i) {
      console.log(
        'getUserNamesFromId->assigned_userIdsJSON[i]: ',
        assigned_userIdsJSON[i]
      )

      let anUserId = assigned_userIdsJSON[i].value

      if (anUserId && anUserId.length > 0) {
        let anUser = await callextendedapisAwait(`/users/${anUserId}`, {})

        // retString += `${anUser.data.first_name} ${anUser.data.last_name} <br />`;
        retArr.push(`${anUser.data.first_name} ${anUser.data.last_name}`)
      }
    }

    return retArr
  }
  const getEstimateSchedules = async () => {
    console.log('I am clicked')
    var resp = await callextendedapisAwait(
      'schedulesforestimatecustom/scheduleForEachEstimate/' + props.item_id,
      {}
    )
    resp = resp.data
    console.log('resp of estimate schedules: ', resp)
    var eventsLocal = []
    var events_ids1 = []

    for (var j = 0; j < resp.length; ++j) {
      // let start = momento(resp[j].start_date_time).toISOString().toString()
      // let end = momento(resp[j].end_date_time).toISOString().toString()
      let start = resp[j].start_date_time.toString()
      let end = resp[j].end_date_time.toString()

      let anEvent = {
        id: parseInt(j),
        originalId: resp[j].scheduleforestimateId,
        estimateId: resp[j].estimateId,
        human_readable_id: resp[j].human_readable_id,
        title: `${resp[j].human_readable_id} - ${resp[j].display_name}`,
        assigned_userNames: await getUserNamesFromId(resp[j].assigned_userIds),
        start: start,
        end: end,
        startDateTime: start,
        endDateTime: end,
        type: 'Estimate',
      }

      // console.log('anEvent from estimate: ', anEvent)

      let assignedUserIds = JSON.parse(resp[j].assigned_userIds)
      let userIsFound = assignedUserIds.find((item) => item.value === user.id)

      if (user.role === 'admin' || user.role === 'super admin' || userIsFound) {
        eventsLocal.push(anEvent)
        events_ids1.push({
          id: parseInt(j),
          value: resp[j].id,
        })
      }
    }

    console.log('estimate events : ', eventsLocal)

    setLoading(false)
    setEstimateEvents(eventsLocal)
    setEvents_ids(events_ids1)
    // setView_state('estimate')
  }

  async function getAllEstimateSchedules() {
    // if (user.role === 'admin') {
    var resp = await callextendedapisAwait(
      'schedulesforestimatecustom/scheduleForEachCompany/' + user.companyId,
      {}
    )
    resp = resp.data
    console.log('resp of estimate schedules for company: ', resp)
    var eventsLocal = []
    var events_ids1 = []

    for (var j = 0; j < resp.length; ++j) {
      let start = resp[j].start_date_time.toString()
      let end = resp[j].end_date_time.toString()

      let anEvent = {
        id: parseInt(j),
        originalId: resp[j].scheduleforestimateId,
        estimateId: resp[j].estimateId,
        human_readable_id: resp[j].human_readable_id,
        title: `${resp[j].human_readable_id} - ${resp[j].display_name}`,
        assigned_userNames: await getUserNamesFromId(resp[j].assigned_userIds),
        start: start,
        end: end,
        startDateTime: start,
        endDateTime: end,
        type: 'Estimate',
      }

      // console.log('anEvent from estimate: ', anEvent)
      let assignedUserIds = JSON.parse(resp[j].assigned_userIds)
      let userIsFound = assignedUserIds.find((item) => item.value === user.id)
      if (userIsFound) console.log('User is found: ', userIsFound)

      if (user.role === 'admin' || user.role === 'super admin' || userIsFound) {
        eventsLocal.push(anEvent)
        events_ids1.push({
          id: parseInt(j),
          value: resp[j].id,
        })
      }
    }

    console.log('estimate events : ', eventsLocal)

    setLoading(false)
    setEstimateEvents(eventsLocal)
    setEvents_ids(events_ids1)
    // }
  }
  async function getAllJobSchedules() {
    // if (user.role === 'admin') {
    console.log('user : ', user.companyId)
    var resp = await callextendedapisAwait(
      'schedulesforjobcustom/scheduleForEachCompany/' + user.companyId,
      {}
    )
    resp = resp.data
    console.log('resp of job schedules for company: ', resp)
    var eventsLocal = []
    var events_ids1 = []

    for (var j = 0; j < resp.length; ++j) {
      // let start = momento(resp[j].start_date_time).toISOString().toString()
      // let end = momento(resp[j].end_date_time).toISOString().toString()

      let start = resp[j].start_date_time.toString()
      let end = resp[j].end_date_time.toString()

      let anEvent = {
        id: parseInt(j),
        originalId: resp[j].scheduleforjobId,
        jobId: resp[j].jobId,
        human_readable_id: resp[j].human_readable_id,
        title: `${resp[j].human_readable_id} - ${resp[j].display_name}`,
        assigned_userNames: await getUserNamesFromId(resp[j].assigned_userIds),
        start: start,
        end: end,
        startDateTime: start,
        endDateTime: end,
        type: 'Job',
      }

      console.log('anEvent from job: ', anEvent)

      let assignedUserIds = JSON.parse(resp[j].assigned_userIds)
      let userIsFound = assignedUserIds.find((item) => item.value === user.id)
      if (userIsFound) console.log('User is found: ', userIsFound)

      if (user.role === 'admin' || user.role === 'super admin' || userIsFound) {
        eventsLocal.push(anEvent)
        events_ids1.push({
          id: parseInt(j),
          value: resp[j].id,
        })
      }
    }
    /*
      if (eventsLocal.length <= 0) {
        eventsLocal.push({
          id: 0,
          originalId: 0,
          title: '',
          start: moment().toISOString().toString(),
          end: moment().add(2, 'hours').toISOString().toString(),
        })

        events_ids1.push({
          id: 0,
          value: 0,
        })
      }*/

    console.log('job events: ', eventsLocal)

    setLoading(false)
    setJobEvents(eventsLocal)
    setEvents_ids(events_ids1)
    // }
  }

  async function handleScheduleSubmit() {
    // console.log('selected start event: ', currentStartDate)
    // console.log('selected end event: ', currentEndDate)
    if (view_state === 'estimate') {
      if (eventSelected.originalId !== 0) {
        //edit

        console.log('eventSelected: ', eventSelected)
        // var myevents = cloneDeep(estimateEvents)

        // myevents.map((e, i) => {
        //   if (e.id === parseInt(eventSelected.id)) {
        //     console.log('selected found.')
        //     myevents[i] = eventSelected
        //   }
        // })

        // setEstimateEvents(myevents)
        // console.log('edit after: events: ', estimateEvents)

        //edit here
        var postdata = {
          start_date_time: currentStartDate,
          end_date_time: currentEndDate,
          estimateId: props.item_id,
          customerId: props.customerId,
          userId: user.id,
          id: eventSelected.originalId,
        }
        // console.log('estimate post data before update: ', postdata)
        var response1 = await postapiAwait(
          `schedulesforestimatecustom/${eventSelected.originalId}/replace`,
          postdata
        )

        showNotificationMsg('Schedule Updated Successfully!', 'success')
        // setNewscheduleId(eventSelected.originalId)

        // message.success("Schedule has been updated successfully");
        // getAllEstimateSchedules()
        // console.log('edit schedule-> response1: ', response1)

        insertActivityLog('Estimate', props.item_id, 'Schedule Updated.')
      } else {
        //add
        // console.log('estimateId: ', props.item_id)
        // console.log('customerId: ', props.customerId)
        // console.log('userId: ', user.id)
        var resp = await postapiAwait('schedulesforestimatecustom/insert', {
          start_date_time: currentStartDate,
          end_date_time: currentEndDate,
          estimateId: props.item_id,
          customerId: props.customerId,
          userId: user.id,
        })
        resp = resp.data

        console.log('schedulesforestimate: ', resp)
        // setNewscheduleId(resp.id)
        // message.success("Schedule has been added successfully");

        // console.log("new schedule Id : ", this.state.newscheduleId);
        showNotificationMsg('Schedule Inserted Successfully!', 'success')
        insertActivityLog('Estimate', props.item_id, 'Schedule Added.')
      }
      getEstimateSchedules()
    } else if (view_state === 'job') {
      if (eventSelected.originalId !== 0) {
        //edit
        console.log('here is job edit')

        // console.log('edit before job events: ', jobEvents)
        // myevents = cloneDeep(jobEvents)

        // myevents.map((e, i) => {
        //   if (e.id === parseInt(eventSelected.id)) {
        //     console.log('selected found.')
        //     myevents[i] = eventSelected
        //   }
        // })

        // setJobEvents(myevents)
        // console.log('edit after: events: ', jobEvents)

        //edit here
        postdata = {
          start_date_time: currentStartDate,
          end_date_time: currentEndDate,
          jobId: props.item_id,
          customerId: props.customerId,
          userId: user.id,
          id: eventSelected.originalId,
        }
        console.log('post data before post: ', postdata)
        response1 = await postapiAwait(`schedulesforjobcustom/update`, postdata)

        // setNewscheduleId(eventSelected.originalId)

        // message.success("Schedule has been updated successfully");
        // getAllEstimateSchedules()
        console.log('edit schedule-> response1: ', response1)
        showNotificationMsg('Schedule Updated Successfully!', 'success')

        insertActivityLog('Job', props.item_id, 'Schedule Updated.')
      } else {
        //add
        console.log('jobId: ', props.item_id)
        console.log('customerId: ', props.customerId)
        console.log('userId: ', user.id)
        resp = await postapiAwait('schedulesforjobcustom/insert', {
          start_date_time: currentStartDate,
          end_date_time: currentEndDate,
          jobId: props.item_id,
          customerId: props.customerId,
          userId: user.id,
        })
        resp = resp.data

        console.log('new schedulesforjob: ', resp)

        showNotificationMsg('Schedule Inserted Successfully!', 'success')

        // getAllJobSchedules()
        // console.log("new schedule Id : ", this.state.newscheduleId);
        insertActivityLog('Job', props.item_id, 'Schedule Added.')
      }
      getJobSchedules()
    }
  }

  useEffect(() => {
    if (view_state === 'estimate' && props.from) getEstimateSchedules()
    else if (view_state === 'job' && props.from) getJobSchedules()
    else {
      getAllEstimateSchedules()
      getAllJobSchedules()
    }
    console.log('schedule view_state: ', view_state)
    // console.log('schedule props.from: ', props.from)
    // if (props.item_id) console.log('and item id: ', props.item_id)
  }, [view_state])

  const handleScheduleChange = ({ value }) => {
    console.log('value is this : ', value)
    setView_state(value)
  }

  const selectOptions = [
    { value: 'estimate', label: 'Estimate' },
    {
      value: 'job',
      label: 'Job',
    },
  ]

  function HandleEventClick(targetElement) {
    if (targetElement.tagName !== 'DIV')
      targetElement = targetElement.parentElement
    const scheduleType = targetElement.getAttribute('data-type')
    const scheduleTypeId = targetElement.getAttribute('data-id')
    reactLocalStorage.setObject('fromSchedule', scheduleTypeId)
    props.history.push(
      `/admin/${scheduleType === 'Estimate' ? 'estimates' : 'jobs'}`
    )
  }

  const formatDateTimeToLocal = (dateTime) => {
    let d = new Date(dateTime),
      year = d.getFullYear(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (hour.length < 2) hour = '0' + hour
    if (minute.length < 2) minute = '0' + minute

    return `${year}-${month}-${day} ${hour}:${minute}`
  }

  function renderStartInput(inputProps, openCalendar, closeCalendar) {
    if (inputProps && inputProps.value) {
      if (momento(inputProps.value).isValid()) {
        setCurrentStartDate(momento(inputProps.value).toISOString().toString())
      } else {
        setCurrentStartDate(currentStartDate)
        inputProps.value = formatDateTimeToLocal(currentStartDate)
      }
    } else {
      setCurrentStartDate(momento(eventSelected.start).toISOString().toString())
    }

    if (inputProps && !inputProps.value)
      inputProps.value = eventSelected.start
        ? formatDateTimeToLocal(eventSelected.start)
        : ''

    function clear() {
      inputProps.onChange({ target: { value: '' } })
    }
    return (
      <div>
        <Button
          onClick={openCalendar}
          style={{
            fontWeight: 'initial',
            padding: '6px 10px',
            backgroundImage: '',
            backgroundColor: '#344675d4',
          }}
        >
          Open
        </Button>
        <Button
          onClick={closeCalendar}
          style={{
            fontWeight: 'initial',
            padding: '6px 10px',
            backgroundImage: '',
            backgroundColor: '#344675d4',
          }}
        >
          Close
        </Button>
        <Button
          onClick={clear}
          style={{
            fontWeight: 'initial',
            padding: '6px 10px',
            backgroundImage: '',
            backgroundColor: '#344675d4',
          }}
        >
          clear
        </Button>
        <input {...inputProps} />
      </div>
    )
  }
  function renderEndInput(inputProps, openCalendar, closeCalendar) {
    if (inputProps && inputProps.value) {
      if (momento(inputProps.value).isValid()) {
        setCurrentEndDate(momento(inputProps.value).toISOString().toString())
      } else {
        setCurrentEndDate(currentEndDate)
        inputProps.value = formatDateTimeToLocal(currentEndDate)
      }
    } else {
      setCurrentEndDate(momento(eventSelected.end).toISOString().toString())
    }

    if (inputProps && !inputProps.value)
      inputProps.value = eventSelected.end
        ? formatDateTimeToLocal(eventSelected.end)
        : ''

    function clear() {
      inputProps.onChange({ target: { value: '' } })
    }
    return (
      <div>
        <Button
          onClick={openCalendar}
          style={{
            fontWeight: 'initial',
            padding: '6px 10px',
            backgroundImage: '',
            backgroundColor: '#344675d4',
          }}
        >
          Open
        </Button>
        <Button
          onClick={closeCalendar}
          style={{
            fontWeight: 'initial',
            padding: '6px 10px',
            backgroundImage: '',
            backgroundColor: '#344675d4',
          }}
        >
          Close
        </Button>
        <Button
          onClick={clear}
          style={{
            fontWeight: 'initial',
            padding: '6px 10px',
            backgroundImage: '',
            backgroundColor: '#344675d4',
          }}
        >
          clear
        </Button>
        <input {...inputProps} />
      </div>
    )
  }

  return (
    <>
      <div className='content'>
        {loading && <Spinner color='light' />}
        {!loading && (
          <div>
            <div>
              <NotificationAlert ref={notificationAlertRef} />
              <FullCalendar
                eventContent={(e, createElement) => {
                  let parentContainer = document.createElement('div')

                  let title = `${e.event.extendedProps.type}<br /><strong>${e.event.title}</strong>`
                  //
                  var assigned_userNames = ``,
                    i = 0
                  if (e.event.extendedProps.assigned_userNames) {
                    for (
                      ;
                      i < e.event.extendedProps.assigned_userNames.length - 1;
                      ++i
                    ) {
                      assigned_userNames +=
                        e.event.extendedProps.assigned_userNames[i] + '<br />'
                    }
                  }

                  assigned_userNames +=
                    e.event.extendedProps.assigned_userNames[i]

                  if (
                    assigned_userNames === undefined ||
                    assigned_userNames === 'undefined'
                  ) {
                    assigned_userNames = ''
                  }

                  parentContainer.innerHTML = `${title}<br />${momento(
                    e.event.extendedProps.startDateTime.toString()
                  ).format('MM/DD/YYYY H:mm:ss')}<br />${assigned_userNames}`
                  parentContainer.style.cssText =
                    'color: #fff !important; padding: 2px;'
                  parentContainer.setAttribute(
                    'data-type',
                    `${e.event.extendedProps.type}`
                  )
                  parentContainer.setAttribute(
                    'data-id',
                    `${
                      e.event.extendedProps.type === 'Estimate'
                        ? e.event.extendedProps.estimateId
                        : e.event.extendedProps.jobId
                    }`
                  )

                  parentContainer.addEventListener('click', (event) => {
                    if (!props.from) HandleEventClick(event.target)
                  })

                  let arrayOfDomNodes = [parentContainer]
                  return { domNodes: arrayOfDomNodes }
                }}
                ref={allCalendarRef}
                initialView='timeGridWeek'
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  listPlugin,
                  interactionPlugin,
                ]}
                selectable={false}
                editable={false}
                eventStartEditable={false}
                events={
                  view_state === 'estimate'
                    ? estimateEvents
                    : view_state === 'job'
                    ? jobEvents
                    : [...estimateEvents, ...jobEvents]
                }
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                height='auto'
                dateClick={(arg) => {
                  // console.log('arg from dataclick : ', arg)
                  // console.log(
                  //   'moment datetime form arg: ',
                  //   momento(arg.date).toISOString().toString()
                  // )
                  setScheduleMode('Create Schedule')
                  if (props.from) {
                    var _newEvent = {
                      id: estimateEvents.length,
                      originalId: 0,
                      title: '',
                      start: momento(arg.dateStr).toISOString().toString(),
                      end: momento(arg.dateStr).toISOString().toString(),
                    }
                    console.log('_newEvent: ', _newEvent)
                    // console.log(
                    //   'data after modifi: ',
                    //   moment(_newEvent.start).tz('Asia/Dhaka')._i
                    // )
                    console.log('_newEvent start: ', _newEvent.start)
                    console.log(
                      '_newEvent start moment: ',
                      momento(_newEvent.start).toISOString().toString()
                    )
                    // console.log(
                    //   '_newEvent start utc: ',
                    //   _newEvent.start.toUTCString()
                    // )
                    setEventSelected(_newEvent)
                    setCurrentStartDate(_newEvent.start)
                    setCurrentEndDate(_newEvent.end)
                    setAddEventModalVisible(true)
                  }
                }}
                eventClick={(info) => {
                  // console.log('info from eventClick: ', info)
                  setScheduleMode('Update Schedule')
                  if (props.from) {
                    var _newEvent = {
                      id: estimateEvents.length,
                      originalId: info.event._def.extendedProps.originalId,
                      title: '',
                      start: momento(
                        info.event._def.extendedProps.startDateTime
                      )
                        .toISOString()
                        .toString(),
                      end: momento(info.event._def.extendedProps.endDateTime)
                        .toISOString()
                        .toString(),
                    }
                    console.log('_newEvent: ', _newEvent)

                    // console.log('event from event click: ', info.event)

                    setEventSelected(_newEvent)

                    setCurrentStartDate(_newEvent.start)
                    setCurrentEndDate(_newEvent.end)
                    // setEstimateEvents(estimateEvents.concat(_newEvent))
                    setAddEventModalVisible(true)
                    // console.log('after estimate evnets', estimateEvents)}
                  }
                }}
                eventR
              />
            </div>
            <Modal isOpen={addEventModalVisible}>
              <ModalHeader
                toggle={() => {
                  setAddEventModalVisible(false)
                }}
              >
                {scheduleMode}
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridGap: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <div className='start-schedule'>
                    <h5>Start</h5>
                    <Datetime
                      renderInput={renderStartInput}
                      dateFormat='YYYY-MM-DD'
                      timeFormat='HH:mm'
                    />
                  </div>
                  <div className='end-schedule'>
                    <h5>End</h5>
                    <Datetime
                      renderInput={renderEndInput}
                      dateFormat='YYYY-MM-DD'
                      timeFormat='HH:mm'
                    />
                  </div>
                </div>

                <ModalFooter>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setAddEventModalVisible(false)
                    }}
                  >
                    Cancel
                  </Button>{' '}
                  <Button
                    color='primary'
                    onClick={(e) => {
                      setAddEventModalVisible(false)
                      handleScheduleSubmit()
                    }}
                  >
                    {scheduleMode}
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Modal>
          </div>
        )}
        {!loading && <UpcomingNotes />}
      </div>
    </>
  )
}
export default Schedules
