import React from 'react'
import FixedPlugin from '../../components/FixedPlugin/FixedPlugin.js'
import NotificationAlert from 'react-notification-alert'

export default function Settings(props) {
  const [activeColor, setActiveColor] = React.useState('blue')
  const [sidebarMini, setSidebarMini] = React.useState(true)
  const [opacity, setOpacity] = React.useState(0)
  const [sidebarOpened, setSidebarOpened] = React.useState(false)
  const mainPanelRef = React.useRef(null)
  const notificationAlertRef = React.useRef(null)

  const handleActiveClick = (color) => {
    console.log('this is sidebar color :  ', color)
    console.log(props.handleActiveClick)
    // props.handleActiveClick(color)
    setActiveColor(color)
  }
  const handleMiniClick = () => {
    let notifyMessage = 'Sidebar mini '
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false)
      notifyMessage += 'deactivated...'
    } else {
      setSidebarMini(true)
      notifyMessage += 'activated...'
    }
    // let options = {};
    // options = {
    //     place: "tr",
    //     message: notifyMessage,
    //     type: "primary",
    //     icon: "tim-icons icon-bell-55",
    //     autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
    showNotificationMsg(notifyMessage, 'primary')
    document.body.classList.toggle('sidebar-mini')
  }
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened)
    document.documentElement.classList.toggle('nav-open')
  }
  const closeSidebar = () => {
    setSidebarOpened(false)
    document.documentElement.classList.remove('nav-open')
  }
  return (
    <div className='content'>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <FixedPlugin
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleMiniClick={handleMiniClick}
        handleActiveClick={handleActiveClick}
      /> */}
    </div>
  )
}
