import React, { useState, PureComponent, useEffect } from 'react'
import {
  //   Row,
  //   Col,
  //   Input,
  //   Table,
  //   Spin,
  //   // Button,
  //   Drawer,
  //   Form,
  //   DatePicker,
  // Modal,
  //   Card,
  //   Icon,
  //   List,
  //   InputNumber,
  //   Steps,
  //   Checkbox,
  //   TimePicker,
  message,
  //   Divider,
  //   Tooltip,
  // Select,
  //   DataTable,
  //   Tabs,
  //   Space,
  //   Typography,
} from 'antd'

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  CardImg,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  Tooltip,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { NavLink as RouterNavLink } from 'react-router-dom'
import $ from 'jquery'
import Select from 'react-select'
import SortingTable from 'components/SortingTable/SortingTable.js'
import CreateCustomer from './CreateCustomer'
import Schedules from './Schedules'

import { reactLocalStorage } from 'reactjs-localstorage'
import MultiSelect from '@khanacademy/react-multi-select'
import config from './../../config'
import {
  callapi,
  callextendedapisAwait,
  postapiAwait,
  insertActivityLog,
  getapiAwait,
  patchApiAwait,
  callExtendedApiPatchAwait,
  toBase64,
  SaveIcon,
  BackArrowIcon,
  DownIcon,
  AddFileIcon,
  ClockIcon,
  CopyIcon,
  EmailIcon
} from './../../helper'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import * as moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import ExampleDirections from './../../google_map'
import StreetViewPanormaWithAnOverlayView from './../../google_map/streetview'
import Geocode from 'react-geocode'
import Checkout from './../../Checkout'
import FormBuilder from 'views/forms/FormBuilder'

import NotificationAlert from 'react-notification-alert'
import utility from './../../utility'

import { loadStripe } from '@stripe/stripe-js'
import UpcomingNotes from 'views/components/UpcomingNotes'

// const { TabPane } = Tabs;
// const { Option } = Select
// const { RangePicker } = DatePicker;
// // const { Paragraph } = Typography;
// const FormItem = Form.Item;
// const { Search, TextArea } = Input;
// const { Text, Link } = Typography;
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(config.googleMapAPIKey)

// Enable or disable logs. Its optional.
Geocode.enableDebug()

let serviceid = 0
let materialid = 0


const estimateStatusOptions = [
  {
    label: 'No Action Taken',
    value: 'No Action Taken'
  },
  {
    label: 'Sent to Customer',
    value: 'Sent to Customer'
  },
  {
    label: 'Approved',
    value: 'approve'
  },
  {
    label: 'Disapproved',
    value: 'deny'
  }
]

const LineItemForm = (props) => {
  // function showAddNoteModal() {
  //   $('#smallModal').removeClass(' hidediv')
  //   $('#smallModal').addClass(' showdiv')
  // }

  // function hideAddNoteModal() {
  //   $('#smallModal').removeClass(' showdiv')
  //   $('#smallModal').addClass(' hidediv')
  //   // this.setState({notes :})
  // }
  // console.log('props from lineitems: ', props)

  const notificationAlertRef = React.useRef(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [customers, setCustomers] = useState([])
  const [jobsOfEstimate, setJobsOfEstimate] = useState([])
  const [invoicesOfJob, setInvoicesOfJob] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [thcustomer, setThcustomer] = useState({})
  const [thcustomerPrimaryEmail, setThcustomerPrimaryEmail] = useState('')

  const [selectedRows, setSelectedRows] = useState([])
  const [toggledClearRows, setToggledClearRows] = useState(false)
  const [activityFeeds, setActivityFeeds] = useState([])
  const [selectedusers, setSelectedusers] = useState([])
  const [customerSelected, setCustomerSelected] = useState({
    value: '',
    label: '',
  })
  const [customerName, setCustomerName] = React.useState(props.itemName ? props.itemName : '')
  const [userlist, setUserlist] = useState([])
  const [directions_lat_lng, setDirections_lat_lng] = useState({
    origin: { lat: -1, lng: -1 },
    destination: { lat: -1, lng: -1 },
  })
  const [customersOptions, setCustomersOptions] = useState([])
  const [eventSelected, setEventSelected] = useState(false)
  const [addeventModalVisible, setAddeventModalVisible] = useState(false)
  const [view_state, setView_state] = useState('')
  const [events_ids, setEvents_ids] = useState([])
  const [events, setEvents] = useState([
    {
      id: 0,
      originalId: 0,
      title: 'event 1',
      start: moment().toISOString(),
      end: moment().add(2, 'hours').toISOString(),
    },
  ])
  const [customerModalvisible, setCustomerModalvisible] = useState(false)
  const [editCustomerModalVisible, setEditCustomerModalVisible] = useState(false)
  const [parentCustomerId, setParentCustomerId] = useState('')
  const [human_readable_id, setHuman_readable_id] = useState(0)
  const [item_id, setItem_id] = useState(0)
  const [notes, setNotes] = useState({})
  const [groupingId, setGroupingId] = useState(0)
  const [servicelists, setServicelists] = useState([])
  const [materiallists, setMateriallists] = useState([])
  const [assigned_userIds, setAssigned_userIdsassigned_userIds] = useState([config.user.id])
  const [userId, setUserId] = useState(config.user.id)
  const [tax, setTax] = useState(15)
  const [subtotal, setSubtotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [estimateType, setEstimateType] = useState('')
  const [updated_at, setUpdated_at] = useState('')
  const [successPayment, setSuccessPayment] = useState(false)
  const [buttonState, setButtonState] = useState('disable')
  const [job_status, setJob_status] = useState('')
  const [buttonClass, setButtonClass] = useState('')
  const [objresp, setObjresp] = useState({})
  const [estimate_status, setEstimate_status] = useState('')
  const [modal, setModal] = useState(false)
  const [noteInputData, setNoteInputData] = useState({
    note: [],
    img: null,
  })
  const [tempNoteInput, setTempNoteInput] = useState('')
  const [imgPreview, setImgPreview] = useState('')
  const [cameraStream, setCameraStream] = useState(null)
  const [scheduleDropdownOpen, setScheduleDropdownOpen] = useState(false)
  const [mouseOnDropdownItem, setMouseOnDropdownItem] = useState(false)

  const [invoice_status, setInvoice_status] = useState('')
  const [line_items, setLIne_Items] = useState([])
  const [stripe_acct_id, setStripe_Acc_Id] = useState('')

  const [gstTaxNum, setGstTaxNum] = useState(null)
  const [gstTaxValue, setGstTaxValue] = useState(0)
  const [pstTaxNum, setPstTaxNum] = useState(null)
  const [pstTaxValue, setPstTaxValue] = useState(0)

  const [horizontalTabs, sethorizontalTabs] = React.useState('home')
  const [pageTabs, setpageTabs] = React.useState('home')
  const [isCapture, setIsCapture] = useState(false)
  const [cameraError, setCameraError] = useState(null)

  const [formModal, setFormModal] = useState(false)
  const [sendCustomerPreviewModal, setSendCustomerPreviewModal] = useState(false)

  const [selectedEstimateStatus, setSelectedEstimateStatus] = useState(estimateStatusOptions[0])


  const [currentButtonAction, setCurrentButtonAction] = useState('Save')
  const [selectedDropdownBtn, setSelectedDropdownBtn] = useState('none')

  const toggle = () => setTooltipOpen(!tooltipOpen)
  const modalToggle = () => setModal(false)
  const toggleFormModal = () => setFormModal(false)
  const toggleSendCustomerPreviewModal = () => setSendCustomerPreviewModal(!sendCustomerPreviewModal)
  const scheduleToggle = () => setScheduleDropdownOpen(!scheduleDropdownOpen)

  const user = reactLocalStorage.getObject('user')

  //Trigger Notification
  const notify = (place, type, message) => {
    // switch (color) {
    //   case 1:
    //     type = "primary";
    //     break;
    //   case 2:
    //     type = "success";
    //     break;
    //   case 3:
    //     type = "danger";
    //     break;
    //   case 4:
    //     type = "warning";
    //     break;
    //   case 5:
    //     type = "info";
    //     break;
    //   default:
    //     break;
    // }
    var options = {}
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }

  async function onMyWayJob() {
    const dataLocal = {
      field: 'onmyway',
      id: props.item_id,
    }
    var response1 = await callExtendedApiPatchAwait(`jobscustom/startFinishOMWPatch`, dataLocal)
    console.log('onMyWayJob = async (self) => {->response1: ', response1)

    // message.success("Job Successfully set to On My Way.");
    // notify('tr', 'success', 'Job Successfully set to On My Way.')
    showNotificationMsg('Job Successfully set to On My Way.', 'success')

    insertActivityLog('job', props.item_id, 'Job set to On My Way.')
    getActivityFeed()
    componentDidMount()
  }

  async function onMyWayEstimate() {
    const dataLocal = {
      field: 'onmyway',
      id: props.item_id,
    }
    var response1 = await callExtendedApiPatchAwait(`estimatescustom/startFinishOMWPatch`, dataLocal)
    console.log('onMyWayEstimate = async (self) => {->response1: ', response1)

    showNotificationMsg('Estimate Successfully set to On My Way.', 'success')

    insertActivityLog('estimate', props.item_id, 'Estimate set to On My Way.')
    getActivityFeed()
    componentDidMount()
  }
  async function finishJob() {
    const dataLocal = {
      field: 'finish',
      id: props.item_id,
    }
    var response1 = await callExtendedApiPatchAwait(`jobscustom/startFinishOMWPatch`, dataLocal)
    console.log('finishJob = async (self) => {->response1: ', response1)

    // message.success("Job Successfully Completed.");
    // notify('tr', 'success', 'Job Successfully Completed.')
    showNotificationMsg('Job Finished Successfully.', 'success')

    insertActivityLog('job', props.item_id, 'Job Completed.')
    getActivityFeed()
    componentDidMount()
  }

  async function finishEstimate() {
    const dataLocal = {
      field: 'finish',
      id: props.item_id,
    }
    var response1 = await callExtendedApiPatchAwait(`estimatescustom/startFinishOMWPatch`, dataLocal)
    console.log('finishEstimate = async (self) => {->response1: ', response1)

    showNotificationMsg('Estimate Finished Successfully.', 'success')

    insertActivityLog('estimate', props.item_id, 'Estimate Completed.')
    getActivityFeed()
    componentDidMount()
  }

  async function sendEstimateToCustomer() {
    const id = item_id
    console.log('id: ', id)

    var _baseUrl = process.env.REACT_APP_NODE_ENV === 'production' ? `https://${window.location.host}` : `http://${window.location.host}`
    const resp = await callextendedapisAwait('estimatescustom/sendEstimateToCustomer/', { id: id, baseurl: _baseUrl })
    console.log('async sendEstimateToCustomer(id) {->resp.data: ', resp.data)

    // message.success('Successfully Sent to Customer')
    showNotificationMsg('Successfully Sent to Customer', 'success')
    setSendCustomerPreviewModal(false)
    const resp2 = insertActivityLog('estimate', id, 'Sent to Customer')
  }

  async function markAsPaidHandler(paidItemId) {
    console.log('invoicescustom item id before /markAsPaid: ', paidItemId)
    var response1 = await callExtendedApiPatchAwait('invoicescustom/markAsPaid', { id: paidItemId })
    response1 = response1.data

    console.log('invoicescustom/markAsPaid: ', response1)
    // message.success('Successfully marked as paid.')
    // showNotificationMsg('Successfully marked as paid', 'success')

    insertActivityLog('invoice', paidItemId, 'Invoice marked as paid.')

    componentDidMount()
  }

  async function startJob() {
    const dataLocal = {
      field: 'start',
      id: props.item_id,
    }

    // var response1 = await postapiAwait(
    //   `schedulesforjob/${props.item_id}/replace`,
    //   {}
    // )

    // console.log('response1 ', response1)

    let _path = `jobscustom/startFinishOMWPatch`
    console.log('startJob()->_path: ', _path)
    var response1 = await callExtendedApiPatchAwait(_path, dataLocal)
    console.log('startJob = async (self) => {->response1: ', response1)
    showNotificationMsg('Job Started Successfully', 'success')

    // message.success('Job Successfully Started.')

    insertActivityLog('job', props.item_id, 'Job Started.')
    getActivityFeed()
    componentDidMount()
  }

  async function startEstimate() {
    const dataLocal = {
      field: 'start',
      id: props.item_id,
    }

    var response1 = await callExtendedApiPatchAwait('estimatescustom/startFinishOMWPatch', dataLocal)

    console.log('response1 ', response1)

    showNotificationMsg('Estimate Started Successfully!', 'success')

    insertActivityLog('Estimate', props.item_id, 'Estimate Started.')
    getActivityFeed()
    componentDidMount()
  }

  async function viewScheduleForJob() {
    //  var resp = await callextendedapisAwait("schedules", {});
    var resp = await callextendedapisAwait('schedulesforjobcustom/scheduleForEachJob/' + item_id, {})
    resp = resp.data
    console.log('Item Id is here--', item_id)

    console.log('resp of schedules: ', resp)
    var eventsLocal = []
    var events_ids1 = []

    for (var j = 0; j < resp.length; ++j) {
      eventsLocal.push({
        id: parseInt(j),
        originalId: resp[j].id,
        title: '',
        start: moment(resp[j].start_date_time).toISOString().toString(),
        end: moment(resp[j].end_date_time).toISOString().toString(),
      })

      events_ids1.push({
        id: parseInt(j),
        value: resp[j].id,
      })
    }

    if (eventsLocal.length <= 0) {
      eventsLocal.push({
        id: 0,
        originalId: 0,
        title: '',
        start: moment().toISOString().toString(),
        end: moment().add(2, 'hours').toISOString().toString(),
      })

      events_ids1.push({
        id: 0,
        value: 0,
      })
    }

    setEvents(eventsLocal)
    setEvents_ids(events_ids1)
    setView_state('schedule')
  }

  async function viewScheduleForEstimate() {
    //  var resp = await callextendedapisAwait("schedules", {});
    var resp = await callextendedapisAwait('schedulesforestimatecustom/scheduleForEachEstimate/' + item_id, {})
    resp = resp.data
    console.log('Item Id is here--', item_id)

    console.log('resp of schedules: ', resp)
    var eventsLocal = []
    var events_ids1 = []

    for (var j = 0; j < resp.length; ++j) {
      eventsLocal.push({
        id: parseInt(j),
        originalId: resp[j].id,
        title: '',
        start: moment(resp[j].start_date_time).toISOString().toString(),
        end: moment(resp[j].end_date_time).toISOString().toString(),
      })

      events_ids1.push({
        id: parseInt(j),
        value: resp[j].id,
      })
    }

    if (eventsLocal.length <= 0) {
      eventsLocal.push({
        id: 0,
        originalId: 0,
        title: '',
        start: moment().toISOString().toString(),
        end: moment().add(2, 'hours').toISOString().toString(),
      })

      events_ids1.push({
        id: 0,
        value: 0,
      })
    }

    setEvents(eventsLocal)
    setEvents_ids(events_ids1)
    setView_state('schedule')
  }

  // async function getInvoiceData() {
  //   var resp2 = await getapiAwait(`customers/${customerSelected}`, {})
  //   resp2 = resp2.data

  //   console.log('customerSelected:', customerSelected)
  //   // getcustomer.then(async (resp2) => {

  //   console.log('getcustomer.then((resp2) => {->resp2: ', resp2)
  // }

  async function addNoteInfo(e, noteInput) {
    // console.log('noteInputData: ', {
    //   ...noteInputData,
    //   note: [...noteInputData.note, noteInput],
    // })

    const typeOfPrevNotes = typeof noteInputData.note

    e.preventDefault()
    let values = {}

    values.companyId = user.companyId
    values.type = props.for
    values.sub_total = subtotal
    values.total = total
    values.customerId = customerSelected.value
    values.userId = userId
    values.groupingId = groupingId

    values.notes =
      typeOfPrevNotes !== 'string'
        ? JSON.stringify({
            ...noteInputData,
            note: [...noteInputData.note, noteInput],
          })
        : JSON.stringify({
            ...noteInputData,
            note: [noteInputData.note, noteInput],
          })
    // values.notes = noteInputData.note === undefined ? '' : noteInputData.note
    values.assigned_userIds = selectedusers
    values.servicelists = servicelists
    values.materiallists = materiallists
    values.human_readable_id = human_readable_id
    console.log('typeof noteInputData.note: ', typeof noteInputData.note)

    console.log('ist woirking', props.item_id)

    if (props.mode === 'edit') {
      console.log(' in edit mode :p')
      if (noteInputData.note === undefined || noteInputData.note === '') {
        showNotificationMsg('Please enter some note first!', 'info')
      } else {
        console.log('  input data is valid', props.for)
        if (props.for === 'estimate') {
          console.log('   for estimate', noteInputData.note)
          var response1 = await patchApiAwait(`estimatescustom/update/${props.item_id}`, values)
          insertActivityLog('estimate', props.item_id, 'Estimate note updated.')

          showNotificationMsg(`Note added Successfully!`, 'info')
          console.log('resoponse ', response1)
          componentDidMount()
        } else if (props.for === 'job') {
          response1 = await patchApiAwait(`jobs/${props.item_id}`, values)

          insertActivityLog('job', props.item_id, 'Job note Updated.')

          showNotificationMsg('Note added Successfully!', 'success')
          componentDidMount()
        } else if (props.for === 'invoice') {
          response1 = await patchApiAwait(`invoices/${props.item_id}`, values)

          insertActivityLog('invoice', props.item_id, 'Invoice note Updated.')
          setTimeout(() => {
            showNotificationMsg('Note added successfully', 'success')
          }, 500)

          componentDidMount()
        }
      }
    } else {
      showNotificationMsg(`Please save the ${props.for} first!`, 'info')
    }
  }

  async function saveUpdate(e, saveMode) {
    e.preventDefault()
    let values = {}

    // const user = reactLocalStorage.getObject('user')
    values.companyId = user.companyId
    values.type = props.for
    values.sub_total = subtotal
    values.total = total
    values.customerId = customerSelected.value
    values.userId = user.id
    values.groupingId = groupingId
    values.notes = notes === undefined ? '' : JSON.stringify(notes)
    values.assigned_userIds = selectedusers
    values.servicelists = servicelists
    values.materiallists = materiallists
    values.human_readable_id = human_readable_id

    console.log('updated values: ', values)

    console.log('props.mode: ', props.mode)
    console.log('props.for: ', props.for)
    if (saveMode === 'template') {
      if (props.for === 'estimate') {
        console.log('templatetemplate')
        let response1 = await callextendedapisAwait('estimatetemplatescustom/countAll', {})
        console.log('saveUpdate = () => estimatetemplatescustom/countAll: ', response1)
        // console.log('humanredableid: ', response1.data[0].estimate_count)
        values.human_readable_id = response1.data[0].estimate_count + 1
        //

        console.log('values before estimate template insert: ', values)
        var response2 = await postapiAwait(`estimatetemplatescustom/insert`, values)
        console.log('insert EstimateTemplate-> response2: ', response2)
        showNotificationMsg('Estimate Template inserted successfully!', 'success')
        insertActivityLog('estimate', props.item_id, 'Estimate Template Inserted.')
      } else if (props.for === 'job') {
        let response1 = await callextendedapisAwait('jobtemplatescustom/countAll', {})
        // console.log(
        //   'saveUpdate = () => jobtemplatescus.tom/countAll:  ',
        //   response1.data
        // )
        values.human_readable_id = response1.data[0].job_count + 1

        response2 = await postapiAwait(`jobtemplatescustom/insert`, values)
        // console.log('insert JobTemplate-> response2: ', response2)

        showNotificationMsg('Job Template inserted successfully!', 'success')
        insertActivityLog('job', props.item_id, 'JOb Template Inserted.')
      } else if (props.for === 'invoice') {
        let response1 = await callextendedapisAwait('invoicetemplatescustom/countAll', {})
        // console.log(
        //   'saveUpdate = () => invoicetemplatescustom/countAll: ',
        //   response1.data
        // )
        values.human_readable_id = response1.data[0].invoice_count + 1

        response2 = await postapiAwait(`invoicetemplatescustom/insert`, values)
        // console.log('insert Invoice-> response2: ', response2)

        showNotificationMsg('Invoice Template inserted successfully!', 'success')
        insertActivityLog('invoice', props.item_id, 'Invoice Template Inserted.')
      }
    } else {
      console.log('save not from template')
      if (props.mode === 'edit' && !props.from) {
        switch (props.for) {
          case 'estimate':
            var response1 = await patchApiAwait(`estimatescustom/update/${props.item_id}`, values)
            console.log('response1 ')
            insertActivityLog('estimate', props.item_id, 'Estimate Updated.')

            showNotificationMsg('Estimate has been updated successfully', 'success')

            setTimeout(() => {
              props.componentDidMount()
              props.setView_state('datatable')
            }, 1000)

            break
          case 'job':
            response1 = await patchApiAwait(`jobs/${props.item_id}`, values)

            insertActivityLog('job', props.item_id, 'Job Updated.')

            showNotificationMsg('Job has been updated successfully', 'success')

            setTimeout(() => {
              props.reRenderTableData()
              props.setView_state('datatable')
            }, 1000)
            break
          case 'invoice':
            response1 = await patchApiAwait(`invoices/${props.item_id}`, values)

            insertActivityLog('invoice', props.item_id, 'Invoice Updated.')

            showNotificationMsg('Invoice has been updated successfully', 'success')

            setTimeout(() => {
              props.reRenderTableData()
              props.setView_state('datatable')
            }, 1000)
            break
          default:
            break
        }
      } else if (props.mode === 'new' || (props.from && props.from === 'template')) {
        switch (props.for) {
          case 'estimate':
            var response0 = await postapiAwait('groupings', {
              name: 'estimate',
            })
            console.log('response0: ', response0)
            values.groupingId = response0.data.id

            response1 = await callextendedapisAwait('estimates/count', {})
            console.log('saveUpdate = () => {->response1.data: ', response1.data)
            values.human_readable_id = response1.data.count + 1

            console.log('post data: ', values)

            var response2 = await postapiAwait(`estimates`, values)
            console.log('insert Estimate-> response2: ', response2)

            insertActivityLog('estimate', response2.data.id, 'Estimate Inserted.')

            showNotificationMsg('Estimate has been inserted successfully', 'success')

            setTimeout(() => {
              props.componentDidMount()
              props.setView_state('datatable')
            }, 1000)

            break
          case 'job':
            response0 = await postapiAwait('groupings', {
              name: 'job',
            })
            console.log('response0: ', response0)
            values.groupingId = response0.data.id

            response1 = await callextendedapisAwait('jobs/count', {})
            console.log('saveUpdate = () => {->response1.data: ', response1.data)
            values.human_readable_id = response1.data.count + 1

            response2 = await postapiAwait(`jobs`, values)
            console.log('insert Job-> response2: ', response2)

            insertActivityLog('job', response2.data.id, 'Job Inserted.')

            showNotificationMsg('Job has been inserted successfully', 'success')
            setTimeout(() => {
              props.reRenderTableData()
              props.setView_state('datatable')
            }, 1000)

            break
          case 'invoice':
            response0 = await postapiAwait('groupings', {
              name: 'invoice',
            })
            console.log('response0: ', response0)
            values.groupingId = response0.data.id

            response1 = await callextendedapisAwait('invoices/count', {})
            console.log('saveUpdate = () => {->response1.data: ', response1.data)
            values.human_readable_id = response1.data.count + 1

            response2 = await postapiAwait(`invoices`, values)
            console.log('insert Invoice-> response2: ', response2)

            insertActivityLog('invoice', response2.data.id, 'Invoice Inserted.')

            showNotificationMsg('Invoice has been inserted successfully', 'success')

            setTimeout(() => {
              props.reRenderTableData()
              props.setView_state('datatable')
            }, 1000)

            break
          default:
            break
        }
      }
    }
  }

  function renderEmails(cust) {
    return (
      <div>
        {cust.emails &&
          cust.emails.map((email) => (
            <div className='station' key={email.id}>
              {email.email}
            </div>
          ))}
      </div>
    )
  }

  async function viewParentCustomer() {
    // alert(customerSelected);

    setLoading(true)

    console.log('selected customer: ', customerSelected)
    // var resp = await callextendedapisAwait(`Customers/getParentCustomer`, { id: customerSelected });
    var resp = await getapiAwait(`customers/${customerSelected.value}`, {})
    resp = resp.data
    console.log('viewParentCustomer = async () => {->resp: ', resp)

    if (resp.parent_id && resp.parent_id.length > 1) {
      setEditCustomerModalVisible(!editCustomerModalVisible)
      setParentCustomerId(resp.parent_id)
    } else {
      showNotificationMsg('No Parent Customer, you can select parent customer from customer edit box', 'info')
    }

    setLoading(false)
  }

  async function getActivityFeed() {
    const dataLocal = {
      type: props.for,
      item_id: props.item_id,
    }
    console.log('dataLocal: ', dataLocal)
    const resp3 = await callextendedapisAwait('activityfeedscustom/activityFeedRetrieve/', dataLocal)
    console.log('async componentDidMount() {->resp3.data: ', resp3.data)
    setActivityFeeds(resp3.data)
  }
  const addAdditionalInfos = async (customerSelected, item) => {
    let companyRes = await getapiAwait(`companyInfocustom/getCompanyInfoById/${user.companyId}`, {})

    console.log('companyInfocustom/getCompanyInfoById->resp2: ', companyRes.data)
    setGstTaxNum(companyRes?.data?.[0]?.gst_tax_number ? companyRes.data[0].gst_tax_number : '')
    setGstTaxValue(companyRes?.data?.[0]?.gst_tax_value ? companyRes.data[0].gst_tax_value : 0)
    setPstTaxNum(companyRes?.data?.[0]?.pst_tax_number ? companyRes.data[0].pst_tax_number : '')
    setPstTaxValue(companyRes?.data?.[0]?.pst_tax_value ? companyRes.data[0].pst_tax_value : 0)

    if (props.mode === 'edit') {
      setHuman_readable_id(item.human_readable_id)
      setHuman_readable_id(item.human_readable_id)
      setNotes(item.notes ? JSON.parse(item.notes) : {})
      setSubtotal(item.sub_total)
      setTotal(item.total)
      console.log('typeof item.servicelists: ', typeof item.servicelists)
      setServicelists(typeof item.servicelists === 'object' ? item.servicelists : JSON.parse(item.servicelists))
      setMateriallists(typeof item.materiallists === 'object' ? item.materiallists : JSON.parse(item.materiallists))
      setItem_id(item.id)

      let tempNote = item.notes ? JSON.parse(item.notes) : {}

      if (tempNote.img) {
        setImgPreview(tempNote.img)
        setNoteInputData({
          ...noteInputData,
          img: tempNote.img,
        })
      }
      if (tempNote.note) {
        setNoteInputData({
          ...noteInputData,
          note: tempNote.note,
        })
      }
      
      if (props.for === 'invoice') {
        const lineItems = []

        for (let i = 0; i < item.servicelists.length; ++i) {
          let s = item.servicelists[i]
          lineItems.push({
            name: s.item_name,
            amount: Math.round(parseFloat(s.unit_price).toFixed(2) * 100),
            currency: 'usd',
            quantity: s.quantity,
          })
        }
        for (let i = 0; i < item.materiallists.length; ++i) {
          let s = item.materiallists[i]
          lineItems.push({
            name: s.item_name,
            amount: Math.round(parseFloat(s.unit_price).toFixed(2) * 100),
            currency: 'usd',
            quantity: s.quantity,
          })
        }

        setLIne_Items(lineItems)
        if (companyRes && companyRes.data && companyRes.data[0] && companyRes.data[0].stripe_acct_id) {
          setStripe_Acc_Id(companyRes.data[0].stripe_acct_id)
        }
        
      }
    } else {
      setHuman_readable_id(0)
      setHuman_readable_id(0)

      setNotes({})
      setSubtotal(0)
      setTotal(0)
      setServicelists([])
      setMateriallists([])
      setItem_id(0)
    }

    setSelectedusers(typeof item.assigned_userIds === 'string' ? JSON.parse(item.assigned_userIds) : item.assigned_userIds)
    
    setGroupingId(props.mode === 'edit' ? item.groupingId : 0)
    setUpdated_at(props.mode === 'edit' ? item.updated_at : '')
    //----------------------------------
    var current_user = {}

    var resp = await getapiAwait(`userscustom/getUsersforCompany/${config.user.companyId}`, {})

    resp = resp.data
    var userlist = []

    for (var j = 0; j < resp.length; ++j) {
      if (resp[j].id === config.user.id) {
        current_user = resp[j]
      }
      userlist.push({
        value: resp[j].id,
        label: resp[j].first_name + ' ' + resp[j].last_name,
        // value: resp[j].id,label: "One", value: 1
      })
    }

    setUserlist(userlist)
    if (customerSelected) {
      prepareMap(customerSelected, item, current_user, companyRes)
    }
  }


  async function prepareMap(customerSelected, item, current_user, companyRes) {
    
    //-------------
    // var current_user_address_len = current_user.address
    // if (current_user_address_len && current_user_address_len.length <= 0) {
    //   console.log('current_user.address is null')
    // }
    console.log('')
    const companyAddress = companyRes?.data?.[0]?.address
    console.log('companyAddres: ', companyAddress)
    if (companyAddress && companyAddress.length > 0) {
      var current_company_lat_lng = {}
      let cgeoAddress = null
      try {
        const response = await Geocode.fromAddress(companyAddress)
        console.log('Geocode.fromAddress(companyAddress) response: ', response)
        cgeoAddress = response.results[0].geometry.location
      } catch (error) {
        console.log('Error occurred in get geo address for customer, error: ', error)
      }
      current_company_lat_lng = {
        lat: cgeoAddress ? cgeoAddress.lat : '',
        lng: cgeoAddress ? cgeoAddress.lng : '',
      }
    }

    var resp2 = await getapiAwait(`customers/${customerSelected}`, {})
    resp2 = resp2.data
    
    setCustomerSelected({
      value: customerSelected,
      label: `${resp2.first_name} ${resp2.last_name}`,
    })
    
    setThcustomer(resp2)
    setThcustomerPrimaryEmail(resp2.primary_email)
    
    let geoAddress = await utility.getCustomerLatLng(resp2)
    let customer_lat_lng = {
      lat: geoAddress.lat,
      lng: geoAddress.lng,
    }

    var selectedUsers = []
    selectedusers.map((u_id, i) => {
      selectedUsers.push(u_id + '')
    })
    
    const lat_lng = {
      origin: { ...current_company_lat_lng },
      destination: { ...customer_lat_lng },
    }

    console.log('lat_lng: ', lat_lng)
    setDirections_lat_lng(lat_lng)
    getActivityFeed()
    setLoading(false)
  }

  // const { getFieldDecorator, getFieldValue } = props.form;
  async function copyEstimateToJob() {
    // console.log("CopyEstimate:", ids);

    setLoading(true)

    // ids.map((id, index) => {
    var id = props.item_id
    console.log('CopyEstimate id: ', id)

    var resp = await getapiAwait('estimates/' + id, {})

    var estimateCopyData = []

    resp = resp.data
    console.log('CopyEstimate Data: ', resp)

    var servicelists_local = []
    if (resp.servicelists != null) {
      resp.servicelists.map((value, index) => {
        servicelists_local.push({
          item_name: value.item_name,
          quantity: value.quantity,
          unit_price: parseFloat(value.unit_price).toFixed(2),
          taxable: true,
          amount: value.amount,
        })
        console.log('CopyEstimate servicelists Data: ', value.item_name, index)
      })
    }
    var materiallists_local = []
    if (resp.materiallists != null) {
      resp.materiallists.map((value, index) => {
        materiallists_local.push({
          item_name: value.item_name,
          quantity: value.quantity,
          unit_price: parseFloat(value.unit_price).toFixed(2),
          taxable: true,
          amount: value.amount,
        })

        console.log('CopyEstimate materiallists Data: ', value.amount, index)
      })
    }

    var response1 = await callextendedapisAwait('jobs/count', {})
    console.log('CopyEstimate = () => {->response1.data: ', response1.data)

    // const user = reactLocalStorage.getObject('user')
    estimateCopyData.push({
      companyId: user.companyId,
      type: 'job',
      sub_total: resp.sub_total,
      total: resp.total,
      notes: resp.notes,
      start: null,
      finish: null,
      onmyway: null,
      completed: 0,
      assigned_userIds: resp.assigned_userIds,
      customerId: resp.customerId,
      groupingId: resp.groupingId,
      servicelists: servicelists_local,
      materiallists: materiallists_local,
      userId: resp.userId,
      human_readable_id: response1.data.count + 1,
    })

    console.log('CopyEstimate New Data: ', estimateCopyData)

    var resp2 = await postapiAwait('jobscustom/copyestimatetojob', estimateCopyData)

    resp2 = resp2.data

    console.log('CopyEstimate resp2', resp2)
    setLoading(false)

    insertActivityLog('estimate', props.item_id, 'Copied to Job')
    // message.success("Estimates has been copied successfully");
    // notify('tr', 'success', 'Estimates has been copied successfully')
    showNotificationMsg('Estimates has been copied successfully', 'success')

    componentDidMount()
  }

  async function sendInvoiceToCustomer() {
    const id = item_id
    console.log('invoice id: ', id)

    // var _baseUrl = `http://${window.location.host}`;
    const resp = await callextendedapisAwait('invoicescustom/sendInvoiceToCustomer/', { id: id })
    console.log('async sendInvoiceToCustomer(id) {->resp.data: ', resp.data)

    // message.success("Successfully Sent to Customer");
    // notify('tr', 'success', 'Successfully Sent to Customer')
    showNotificationMsg('Successfully Sent to Customer', 'success')

    // const resp2 = insertActivityLog('invoice', id, 'Sent to Customer')
    setTimeout(() => {
      // componentDidMount()
    }, 1000)
  }

  function deleteserviceid(key, type) {
    //alert(type);
    if (type == 'service') {
      serviceid = serviceid - 1
      const valueToRemove = servicelists[key]
      const filteredItems = servicelists.filter((item) => item !== valueToRemove)
      setServicelists(filteredItems)
      console.log('service array: ', servicelists)
    } else {
      materialid = materialid - 1
      const valueToRemove = materiallists[key]
      const filteredItems = materiallists.filter((item) => item !== valueToRemove)
      setMateriallists(filteredItems)
      console.log('material array: ', materiallists)
    }
  }

  async function copyJobToInvoice(e) {
    setLoading(true)

    var id = props.item_id
    console.log('copyJobToInvoice id: ', id)

    var resp = await getapiAwait('jobs/' + id, {})

    var jobCopyData = []

    resp = resp.data
    console.log('copyJobToInvoice Data: ', resp)
    if (Array.isArray(resp)) {
      resp = resp[0]
    }

    var servicelists_local = []
    resp.servicelists.map((value, index) => {
      servicelists_local.push({
        item_name: value.item_name,
        quantity: value.quantity,
        unit_price: parseFloat(value.unit_price).toFixed(2),
        taxable: true,
        amount: value.amount,
      })
      console.log('copyJobToInvoice servicelists Data: ', value.item_name, index)
    })

    var materiallists_local = []
    resp.materiallists.map((value, index) => {
      materiallists_local.push({
        item_name: value.item_name,
        quantity: value.quantity,
        unit_price: parseFloat(value.unit_price).toFixed(2),
        taxable: true,
        amount: value.amount,
      })

      console.log('copyJobToInvoice materiallists Data: ', value.amount, index)
    })

    var response1 = await callextendedapisAwait('invoices/count', {})
    console.log('copyJobToInvoice = () => {->response1.data: ', response1.data)

    // const user = reactLocalStorage.getObject('user')
    jobCopyData.push({
      companyId: user.companyId,
      type: 'invoice',
      sub_total: resp.sub_total,
      total: resp.total,
      notes: resp.notes,

      customerId: resp.customerId,
      groupingId: resp.groupingId,
      servicelists: servicelists_local,
      materiallists: materiallists_local,

      human_readable_id: response1.data.count + 1,
      paid: false,
      userId: resp.userId,

      assigned_userIds: resp.assigned_userIds,
    })

    console.log('copyJobToInvoice New Data: ', jobCopyData)

    var resp2 = await postapiAwait('invoices', jobCopyData[0])

    resp2 = resp2.data
    console.log('after copy invoice response: ', resp2)

    insertActivityLog('invoice', props.item_id, 'Invoice created')
    // message.success('Job has been copied to Invoice successfully')
    showNotificationMsg('Job has been copied to Invoice successfully', 'success')

    // componentDidMount()

    setLoading(false)
  }

  function calculatesubtotalamount(taxable, quantity, unit_price, tax, type, amount) {
    let newSubtotal = taxable
      ? quantity * unit_price + ((quantity * unit_price) / 100) * gstTaxValue + ((quantity * unit_price) / 100) * pstTaxValue
      : quantity * unit_price
    setSubtotal(newSubtotal)

    let fTotal = 0
    let myInputList = document.querySelectorAll('span.change-total-value')
    console.log('myInputList: ', myInputList)

    for (var indx = 0; indx < myInputList.length; ++indx) {
      let valuePrice = parseFloat(myInputList[indx].parentElement.previousSibling.firstElementChild.value)
      let valueQty = parseFloat(myInputList[indx].parentElement.previousSibling.previousSibling.firstElementChild.value)
      let valueTaxable = myInputList[indx].parentElement.previousSibling.previousSibling.previousSibling.firstElementChild.firstElementChild.value

      if (!valuePrice) valuePrice = 0
      if (!valueQty) valueQty = 0
      fTotal +=
        valueTaxable === 'true'
          ? valueQty * valuePrice + ((valueQty * valuePrice) / 100) * gstTaxValue + ((valueQty * valuePrice) / 100) * pstTaxValue
          : valueQty * valuePrice
    }

    console.log('fTotal from calcSubtotal: ', fTotal)
    setTotal(fTotal)
    return subtotal
  }

  //Tax sub total calculation
  function calculatesubtotaltax(add, amount, type, index, _subtotal = null, e) {
    let data = type == 'materiallists' ? materiallists : servicelists
    data[index].taxable = add
    type == 'materiallists' ? setMateriallists(data) : setServicelists(data)
    // console.log('subtotal add: ', add)
    let clamount = add ? (isNaN(amount) ? 0 : amount) + subtotal : subtotal - (isNaN(amount) ? 0 : amount)
    // console.log('subtotal: ', clamount)

    let subclamount = add
      ? (isNaN(amount) ? 0 : amount) + (isNaN(_subtotal) ? 0 : _subtotal)
      : (isNaN(_subtotal) ? 0 : _subtotal) - (isNaN(amount) ? 0 : amount)
    // console.log('subclamount: ', subclamount)

    let fTotal = 0
    let myInputList = document.querySelectorAll('span.change-total-value')

    for (var indx = 0; indx < myInputList.length; ++indx) {
      let valuePrice = parseFloat(myInputList[indx].parentElement.previousSibling.firstElementChild.value)
      let valueQty = parseFloat(myInputList[indx].parentElement.previousSibling.previousSibling.firstElementChild.value)
      let valueTaxable = myInputList[indx].parentElement.previousSibling.previousSibling.previousSibling.firstElementChild.firstElementChild.value

      if (e.target === myInputList[indx].parentElement.previousSibling.previousSibling.previousSibling.firstElementChild.firstElementChild)
        valueTaxable = add
      if (!valuePrice) valuePrice = 0
      if (!valueQty) valueQty = 0
      fTotal +=
        valueTaxable === 'true' || valueTaxable === true
          ? valueQty * valuePrice + ((valueQty * valuePrice) / 100) * gstTaxValue + ((valueQty * valuePrice) / 100) * pstTaxValue
          : valueQty * valuePrice

      // console.log(
      //   'total frm check: ' +
      //     (valueTaxable === 'true' || valueTaxable === true
      //       ? valueQty * valuePrice + ((valueQty * valuePrice) / 100) * tax
      //       : valueQty * valuePrice)
      // )
      // console.log('checked? : ' + valueTaxable)
    }
    console.log('calcSutTax gstTaxValue : ', gstTaxValue)
    console.log('calcSutTax pstTaxValue : ', pstTaxValue)
    console.log('calcSutTax subtotal: ', clamount)

    console.log('calcSutTax fTotal from subtotalTax: ', fTotal)

    setTotal(fTotal)
    setSubtotal(clamount)
    return subtotal
  }

  function handleSelectedSchedule(value) {
    console.log('value from selected schedule option: ', value)
    switch (value) {
      case 'schedule':
        if (props.for === 'job') {
          // viewScheduleForJob()
        } else {
          // viewScheduleForEstimate()
        }
        break
      case 'scheduleStart':
        if (props.for === 'job') {
          startJob()
        } else {
          startEstimate()
        }
        break
      case 'onMyWay':
        if (props.for === 'job') {
          onMyWayJob()
        } else {
          onMyWayEstimate()
        }
        break
      case 'finish':
        if (props.for === 'job') {
          finishJob()
        } else {
          finishEstimate()
        }
        break
      default:
        break
    }
  }

  async function componentDidMount() {
    setLoading(true)
    var api_for = ''
    if (props.for === 'estimate') {
      api_for = 'estimates'
    } else if (props.for === 'job') {
      api_for = 'jobs'
    } else {
      api_for = 'invoices'
    }

    getActivityFeed()
    // const user = reactLocalStorage.getObject('user')

    if (props.mode === 'new') {
      var response1 = await getapiAwait('customers/allCustomersByCompany', {
        companyId: user.companyId,
      })

      console.log('customers/allCustomersByCompany->respnose1.data: ', response1.data)
      const allcustomers = []
      for (let i = 0; i < response1.data.length; ++i) {
        const c = response1.data[i]

        allcustomers.push({
          value: c.id,
          label: c.first_name + ' ' + c.last_name,
        })
      }

      console.log('assigned_userIds: ', assigned_userIds)
      console.log('selectedusers: ', selectedusers)

      var response2 = await getapiAwait(`userscustom/getUsersforCompany/${user.companyId}`, {})

      response2 = response2.data

      var userlist = []
      var current_user = null
      for (var j = 0; j < response2.length; ++j) {
        if (response2[j].id === config.user.id) {
          current_user = response2[j]
        }
        userlist.push({
          value: response2[j].id,
          label: response2[j].first_name + ' ' + response2[j].last_name,
        })
      }

      console.log('all customers ', allcustomers)

      setLoading(false)
      setCustomers(allcustomers)
      // setCustomersOptions(children)
      setButtonClass('ant-btn-primary-disabled')
      setButtonState('disable')
      setUserlist(userlist)
      // showNotificationMsg('this message sent from new 1', 'success')
    } else if (props.mode === 'edit') {
      response1 = await getapiAwait('customers/allCustomersByCompany', {
        companyId: user.companyId,
      })

      console.log('customers/allCustomersByCompany->respnose1.data: ', response1.data)
      console.log('props.from: ', props.from)
      const allcustomers = []
      for (let i = 0; i < response1.data.length; ++i) {
        const c = response1.data[i]

        allcustomers.push({
          value: c.id,
          label: c.first_name + ' ' + c.last_name,
        })
      }

      var resp = { data: {} }

      if (props.from && props.from === 'template') {
        resp = await getapiAwait(api_for + 'templatecustom/templatebyid/' + props.item_id, {})
      } else {
        resp = await getapiAwait(api_for + '/' + props.item_id, {})
      }

      resp = resp.data
      console.log('editable I ' + props.for + ': ', resp)
      setNotes(resp.notes ? JSON.parse(resp.notes) : {})
      console.log('note is set, ', notes)

      let estimate_status = ''
      if (resp.approved === true) {
        estimate_status = 'Approved'
        setSelectedEstimateStatus(estimateStatusOptions[2])
      } else if (resp.disapproved === true) {
        estimate_status = 'Disapproved'
        setSelectedEstimateStatus(estimateStatusOptions[3])
      } else if (resp.sent_to_customer === true) {
        estimate_status = 'Sent to Customer'
        setSelectedEstimateStatus(estimateStatusOptions[1])
      } else {
        estimate_status = 'No Action Taken'
        setSelectedEstimateStatus(estimateStatusOptions[0])
      }

      let job_status = ''
      if (resp.completed === true) {
        job_status = 'Completed'
      } else {
        job_status = 'Not Completed'
      }

      let invoice_status = ''
      if (resp.paid === true) {
        invoice_status = 'Paid'
      } else {
        invoice_status = 'Not Paid'
      }
      // console.log("Invoice status is here----",invoice_status)
      setObjresp(resp)
      setEstimate_status(estimate_status)
      setJob_status(job_status)
      setInvoice_status(invoice_status)

      if (Array.isArray(resp)) {
        console.log('i m here')
        resp = resp[0]
      }

      var customerSelected = resp.customerId

      await addAdditionalInfos(customerSelected, resp)

      //Start here
      if (props.for == 'estimate') {
        let resp2 = await getapiAwait('jobscustom/jobAllWithCustomerForSingleEstimate', { id: props.item_id })

        console.log('jobAllWithCustomerForSingleEstimate->resp2: ', resp2)

        var dataTableDataArr = []
        resp2.data.map((item, i) => {
          var j = 0
          for (; j < dataTableDataArr.length; ++j) {
            const item2 = dataTableDataArr[j]
            if (item.job_id === item2.job_id) {
              break
            }
          }

          if (j === dataTableDataArr.length) {
            var obj = cloneDeep(item)
            if (obj.job_completed === 1) {
              obj.status = 'Completed'
            } else {
              obj.status = 'Not Completed'
            }

            dataTableDataArr.push(obj)
          }
        })

        setButtonClass('ant-btn-primary')
        setButtonState('enable')
        setJobsOfEstimate(dataTableDataArr)
      } else if (props.for == 'job') {
        //Start here
        let resp2 = await getapiAwait('invoicescustom/invoiceAllWithCustomerForSingleJob', { id: props.item_id })

        console.log('invoiceAllWithCustomerForSingleJob->resp2: ', resp2)

        dataTableDataArr = []
        resp2.data.map((item, i) => {
          var j = 0
          for (; j < dataTableDataArr.length; ++j) {
            const item2 = dataTableDataArr[j]
            if (item.invoice_id === item2.invoice_id) {
              break
            }
          }

          if (j === dataTableDataArr.length) {
            var obj = cloneDeep(item)
            if (obj.invoice_paid === 1) {
              obj.status = 'Paid'
            } else {
              obj.status = 'Not Paid'
            }

            dataTableDataArr.push(obj)
          }
        })

        setButtonClass('ant-btn-primary')
        setButtonState('enable')
        setInvoicesOfJob(dataTableDataArr)
        console.log('data table invoice array : ', dataTableDataArr)
      } else if (props.for == 'invoice') {
        setButtonClass('ant-btn-primary')
        setButtonState('enable')
      }

      setCustomers(allcustomers)
    } else {
      console.log('neither new or edit. user : ', user)
    }
    setLoading(false)
  }

  useEffect(() => {
    /*const urlParams = new URLSearchParams(window.location.search)
    const stripe_status = urlParams.get('action')
    if (stripe_status && stripe_status.length > 0) {
      switch (stripe_status) {
        case 'stripe_success':
          let paidItem_id = urlParams.get('item_id')
          console.log('case stripe success')
          if (paidItem_id && paidItem_id.length > 0) {
            // componentDidMount()
            setItem_id(paidItem_id)
            console.log('stripe_status before mark as paid: ', stripe_status)
            console.log('stripe_status after mark as paid: ', stripe_status)
            console.log('paidItem_id: ', paidItem_id)
            markAsPaidHandler(paidItem_id)
            reactLocalStorage.setObject('paidInvoiceId', paidItem_id)
            // setTimeout(() => {
            // window.location.replace('/admin/invoices')
            // }, 1000)
          }
          break
        case 'stripe_cancelled':
          break
      }
    }*/
    componentDidMount()
  }, [])

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault()
    componentDidMount()
    switch (tabState) {
      case 'horizontalTabs':
        sethorizontalTabs(tabName)
        break
      case 'pageTabs':
        setpageTabs(tabName)
        break
      default:
        break
    }
  }

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (notificationAlertRef !== null && notificationAlertRef.current !== null) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }

  function cameraInit() {
    // The width and height of the captured photo. We will set the
    // width to the value defined here, but the height will be
    // calculated based on the aspect ratio of the input stream.

    var width = 640 // We will scale the photo width to this
    var height = 0 // This will be computed based on the input stream

    // |streaming| indicates whether or not we're currently streaming
    // video from the camera. Obviously, we start at false.

    var streaming = false

    // The various HTML elements we need to configure or control. These
    // will be set by the startup() function.

    var video = null
    var canvas = null
    var photo = null
    var startbutton = null

    function startup() {
      video = document.getElementById('video')
      canvas = document.getElementById('canvas')
      // photo = document.getElementById('photo')
      startbutton = document.getElementById('startbutton')

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then(function (stream) {
          video.srcObject = stream
          video.play()
          setCameraStream(stream)
        })
        .catch(function (err) {
          console.log('An error occurred: ' + err)
          setCameraError(err.NotAllowedError ? err.NotAllowedError : 'Something Wrong, Camera not availabe!')
        })

      video.addEventListener(
        'canplay',
        function (ev) {
          if (!streaming) {
            height = video.videoHeight / (video.videoWidth / width)

            // Firefox currently has a bug where the height can't be read from
            // the video, so we will make assumptions if this happens.

            if (isNaN(height)) {
              height = width / (4 / 3)
            }

            video.setAttribute('width', width)
            video.setAttribute('height', height)
            canvas.setAttribute('width', width)
            canvas.setAttribute('height', height)
            streaming = true
          }
        },
        false
      )

      startbutton.addEventListener(
        'click',
        function (ev) {
          takepicture()
          ev.preventDefault()
        },
        false
      )

      clearphoto()
    }

    // Fill the photo with an indication that none has been
    // captured.

    function clearphoto() {
      var context = canvas.getContext('2d')
      context.fillStyle = '#AAA'
      context.fillRect(0, 0, canvas.width, canvas.height)

      var data = canvas.toDataURL('image/png')
      // photo.setAttribute('src', data)
      // setImgPreview(data)
    }

    // Capture a photo by fetching the current contents of the video
    // and drawing it into a canvas, then converting that to a PNG
    // format data URL. By drawing it on an offscreen canvas and then
    // drawing that to the screen, we can change its size and/or apply
    // other changes before drawing it.

    function takepicture() {
      var context = canvas.getContext('2d')
      if (width && height) {
        canvas.width = width
        canvas.height = height
        context.drawImage(video, 0, 0, width, height)

        var data = canvas.toDataURL('image/png')
        // photo.setAttribute('src', data)
        setNoteInputData({
          ...noteInputData,
          img: data,
        })
        setImgPreview(data)
      } else {
        clearphoto()
      }
    }

    startup()
  }

  function setCameraOff() {
    if (cameraStream) {
      cameraStream.getTracks().forEach(function (track) {
        track.stop()
      })
    }
  }

  function renderNotes(notesForRender) {
    const noteType = typeof notesForRender
    // console.log('noteType: ', noteType)
    if (noteType === 'object' || noteType === 'array') {
      return (
        <ul>
          {notesForRender.map((noteItem) => (
            <li>{noteItem}</li>
          ))}
        </ul>
      )
    } else {
      return notesForRender
    }
  }

  const handleEstimateStatusChange = async (_selected) => {
    if (_selected.value !== selectedEstimateStatus.value && _selected.value !== 'No Action Taken') {
      if (_selected.value === 'Sent to Customer') {
        sendEstimateToCustomer()
      } else {
        const resp = await callextendedapisAwait('estimatescustom/approveDisapprove/', { id: props.item_id, action: _selected.value });
        console.log("async estimatescustom/approveDisapprove/ ->resp.data: ", resp.data);
        const _alertText = _selected.value === 'deny' ? 'Successfully Disapproved the estimate' : 'Successfully Approved the estimate'
        showNotificationMsg(_alertText, 'success')
      }

      setSelectedEstimateStatus(_selected)
    }
    
  }

  return (
    <>
      {loading && (
        <div className='content'>
          <Spinner color='light' />
        </div>
      )}
      {!loading && view_state !== 'create_customer' ? (
        <div
          className={
            props.for === 'estimate'
              ? 'estimate content'
              : props.for === 'job'
              ? 'job content'
              : props.for === 'invoice'
              ? 'invoice content'
              : 'content'
          }
        >
          <div>
            <Modal
              isOpen={modal}
              fade={false}
              toggle={modalToggle}
              className='note-editor'
              style={{
                transform: 'translate(0, 10%)',
                width: '100% !important',
                maxWidth: '900px',
                marginBottom: '150px',
              }}
            >
              <ModalHeader toggle={modalToggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Please type your note
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for='exampleText'>Enter Note</Label>
                    <Input
                      type='textarea'
                      name='text'
                      id='exampleText'
                      style={{
                        color: '#000',
                        fontSize: '16px',
                      }}
                      value={tempNoteInput}
                      onChange={(e) => {
                        setTempNoteInput(e.target.value)
                      }}
                      autoFocus
                    />
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col sm={6}>
                        <Label for='noteImg' style={{ width: '100%' }}>
                          <Button color='primary' style={{ padding: '11px', width: '100%' }} onClick={(e) => {}}>
                            <i style={{ fontSize: '20px', marginRight: '5px' }} className='tim-icons icon-cloud-upload-94'></i> Upload Photo
                          </Button>
                        </Label>
                        <Input
                          onChange={async (e) => {
                            console.log('file: ', e.target.files[0])
                            const base64Img = await toBase64(e.target.files[0])
                            setNoteInputData({
                              ...noteInputData,
                              img: base64Img,
                            })

                            setImgPreview(base64Img)
                          }}
                          placeholder='Company Logo'
                          name='noteImg'
                          id='noteImg'
                          type='file'
                          accept='image/*'
                        />
                      </Col>
                      <Col sm={6}>
                        <Button
                          color='primary'
                          style={{ padding: '11px', width: '100%' }}
                          onClick={(e) => {
                            setIsCapture(true)
                            cameraInit()
                          }}
                        >
                          <i style={{ fontSize: '20px', marginRight: '5px' }} className='tim-icons icon-camera-18'></i> Capture Photo
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} style={{ textAlign: 'center' }}>
                        {imgPreview && <CardImg src={imgPreview} style={{ width: 'auto', maxWidth: '100%' }} />}
                      </Col>
                      <Col sm={6}>
                        <div className='camera'>
                          <video
                            id='video'
                            style={{
                              maxWidth: '100%',
                              height: `${isCapture && !cameraError ? 'auto' : '0'}`,
                            }}
                          >
                            Video stream not available.
                          </video>
                          <Button
                            color='primary'
                            id='startbutton'
                            style={{
                              display: `${isCapture && !cameraError ? 'initial' : 'none'}`,
                            }}
                          >
                            Take photo
                          </Button>
                          {cameraError && <p>{cameraError}</p>}
                        </div>
                        <canvas id='canvas' style={{ display: 'none' }}></canvas>
                        <div className='output'>
                          <img id='photo' alt='' />
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button
                  color='primary'
                  onClick={(e) => {
                    if (tempNoteInput !== '') {
                      // setNoteInputData({
                      //   ...noteInputData,
                      //   note: [...noteInputData.note, tempNoteInput],
                      // })
                      addNoteInfo(e, tempNoteInput)
                      setTempNoteInput('')
                      setModal(false)
                      if (isCapture) {
                        setIsCapture(false)
                        if (cameraStream) {
                          setCameraOff()
                        }
                      }
                    } else {
                      showNotificationMsg('Please enter some note first!', 'info')
                    }
                  }}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div>
            <Modal isOpen={formModal} fade={false} toggle={toggleFormModal}>
              <ModalHeader toggle={toggleFormModal}>
                <i className='tim-icons icon-alert-circle-exc' /> Please create your form
              </ModalHeader>
              <ModalBody>
                <FormBuilder />
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setFormModal(!formModal)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button id='attach-form-save' color='primary' onClick={() => {}}>
                  Submit
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div>
            <Modal 
              className="send-email-proview-modal" 
              isOpen={sendCustomerPreviewModal}
              fade={false}
              toggle={toggleSendCustomerPreviewModal}
              style={{
                minWidth: 'initial',
                maxWidth: '100%',
                width: '40rem'
              }}
            >
              <ModalHeader toggle={toggleSendCustomerPreviewModal}>
                <i className='tim-icons icon-alert-circle-exc' /> Please Confirm before sending to customer
              </ModalHeader>
              <ModalBody style={{ textAlign: 'center' }}>
                <div className='edit-inner'>
                  <Row>
                    <Col xs={4} lg={4}>
                      <span>Services</span>
                    </Col>
                    <Col
                      xs={2}
                      lg={2}
                    >
                      <span>Qty</span>
                    </Col>
                    <Col
                      xs={2}
                      lg={2}
                    >
                      <span>Unit Price </span>
                    </Col>
                    <Col
                      xs={4}
                      lg={4}
                    >
                      <span>Amount</span>
                    </Col>
                  </Row>
                  {servicelists.map((s, index) => {
                    return (
                      <Row className='mt-2' key={index} id={`servicelists_` + index}>
                        <Col xs={4} lg={4}>
                          <span>
                            <strong>{s.item_name}</strong>
                          </span>
                        </Col>
                        
                        <Col
                          xs={2}
                          lg={2}
                        >
                          <span>
                            <strong>{s.quantity}</strong>
                          </span>
                        </Col>
                        <Col
                          xs={2}
                          lg={2}
                        >
                          <span>
                            <strong>{parseFloat(s.unit_price).toFixed(2)}</strong>
                          </span>
                        </Col>
                        <Col
                          xs={4}
                          lg={4}
                        >
                          <span
                            className='change-total-value'
                          >
                            {' '}
                            {(servicelists[index].taxable
                              ? servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2) +
                                ((servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)) / 100) * gstTaxValue +
                                ((servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)) / 100) * pstTaxValue
                              : servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)
                            ).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                    )
                  })}
                  
                  <Row style={{
                    marginTop: '40px',
                    paddingTop: '25px',
                    borderTop: '5px solid #faf6f5'
                  }}>
                    <Col>
                      <Row>
                        <Col xs={4} lg={4}>
                          <span>Materials</span>
                        </Col>
                        <Col xs={2} lg={2}>
                          <span>Qty</span>
                        </Col>
                        <Col
                          xs={2}
                          lg={2}
                        >
                          <span>Unit Price </span>
                        </Col>
                        <Col
                          xs={4}
                          lg={4}
                        >
                          <span>Amount</span>
                        </Col>
                      </Row>
                      {materiallists.map((s, index) => {
                        return (
                          <Row className='mt-2' key={index} id={`materiallists_` + index}>
                            <Col xs={4} lg={4}>
                              <span>
                                <strong>{s.item_name ? s.item_name : ''}</strong>
                              </span>
                            </Col>
                            
                            <Col
                              xs={2}
                              lg={2}
                            >
                              <span>
                                <strong>{s.quantity}</strong>
                              </span>
                            </Col>
                            <Col
                              xs={2}
                              lg={2}
                            >
                              <span>
                                <strong>{parseFloat(s.unit_price).toFixed(2)}</strong>
                              </span>
                            </Col>
                            <Col
                              xs={4}
                              lg={4}
                            >
                              <span
                                className='change-total-value'
                              >
                                {(materiallists[index].taxable
                                  ? materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2) +
                                    ((materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)) / 100) * gstTaxValue +
                                    ((materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)) / 100) * pstTaxValue
                                  : materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)
                                ).toFixed(2)}
                              </span>
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>
                  </Row>
                  
                  <Row style={{ margin: '20px' }}>
                    {/* <Divider /> */}
                    <Col md={8}>
                    </Col>

                    <Col md={4}>
                      <div className='total-calculator'>
                        <strong>
                          <span className='amount-text-ext'>Total: </span>
                          <span className='amount-text amount-text-ext'>${total.toFixed(2)}</span>
                        </strong>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() => {
                    setSendCustomerPreviewModal(false)
                  }}
                >
                  Cancel
                </Button>{' '}
                <Button id='attach-form-save' color='primary' onClick={() => {
                  sendEstimateToCustomer()
                }}>
                  Send
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <Row style={{ position: 'relative' }}>
            <NotificationAlert ref={notificationAlertRef} />
            
            <Row>
              <Col offset={0} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div className='pageTitlediv'>
                  <h2 className='page-status-info' style={{ textTransform: 'capitalize' }}>
                    {props.for} Status
                    <span className='before-status'>
                      {props.mode === 'edit' && (
                        <span
                          className='logged-in'
                          style={{
                            color: '#449E5D',
                            fontSize: '13px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                          }}
                        >
                          ●
                        </span>
                      )}
                      {props.mode === 'edit' && (
                        <span style={{ fontSize: '12px' }}>
                          {props.for === 'estimate' && (
                            
                            <Select
                              className='react-select estimate-status'
                              closeMenuOnSelect={true}
                              isMulti={false}
                              value={selectedEstimateStatus}
                              onChange={(value) => {
                                handleEstimateStatusChange(value)
                              }}
                              options={estimateStatusOptions}
                            />
                          )}
                          {props.for === 'job' && job_status}
                          {props.for === 'invoice' && invoice_status}
                          {/* {props.for === "invoice" && (obj.status)} */}
                        </span>
                      )}
                    </span>
                  </h2>
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#919191',
                      fontWeight: 'normal',
                    }}
                  >
                    Last update {moment(updated_at).format('YYYY-MM-DD hh:mm A')}
                  </p>
                </div>
                </Col>
            </Row>
          </Row>
          
                
          <Row>
            <Col className='action-buttons-container'>
              <div className='back-button-container'>
                <button
                  type='button'
                  className={'back-btn-custom'}
                  icon='back'
                  size='default'
                  title='Back'
                  onClick={(e) => {
                    props.setView_state('datatable')
                    props.reRenderTableData()
                  }}
                >
                  <span>
                    <BackArrowIcon />
                  </span>
                </button>
              </div>

              <div className="right-buttons-container">
                <div className="button-groups">
                  {props.mode !== 'new' && (
                    <Button
                      id='ant-btn-save'
                      type='button'
                      className={`btn-custom`}
                      icon='save'
                      size='default'
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          saveUpdate(e, '')
                        } else {
                          console.log('i am disabled')
                        }
                      }}
                    >
                      <span>
                        <SaveIcon />
                        Save
                      </span>
                    </Button>
                  )}
                  
                  {props.mode === 'new' && (
                    <Button
                      id='ant-btn-save'
                      type='button'
                      disabled={buttonState === 'disable' && true} // || !selectedusers || selectedusers.length === 0) 
                      className={buttonClass + ` ant-btn-save`}
                      icon='save'
                      size='default'
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          saveUpdate(e, '')
                        } else {
                        //   console.log('i am disabled')
                        }
                      }}
                    >
                      <span>
                        <SaveIcon />
                        Save
                      </span>
                    </Button>
                  )}

                  
                  {/* {props.mode === 'new' && (
                    <Button
                      id='ant-btn-save'
                      type='button'
                      disabled={buttonState === 'disable' && true} // || !selectedusers || selectedusers.length === 0) 
                      className={buttonClass + ` ant-btn-save`}
                      icon='save'
                      size='default'
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          saveUpdate(e, '')
                        } else {
                        //   console.log('i am disabled')
                        }
                      }}
                    >
                      <span>
                        <SaveIcon />
                        Save
                      </span>
                    </Button>
                    // </Tooltip>
                  )}   */}
                  
                  {props.for === 'job' && props.mode === 'new' && (
                    <Button
                      className={buttonClass}
                      type='button'
                      icon='copy'
                      size='defalut'
                      id='ant-btn-copy'
                      disabled={true}
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          copyJobToInvoice(e)
                        } else {
                        }
                      }}
                    >
                      <span>
                        <CopyIcon />
                        Copy to Invoice
                      </span>
                    </Button>
                    // </Tooltip>
                  )}
                  
                  {props.for === 'job' && props.mode !== 'new' && (
                    <Button
                      className={buttonClass}
                      type='button'
                      icon='copy'
                      size='defalut'
                      id='ant-btn-copy'
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          copyJobToInvoice(e)
                        } else {
                          console.log("I'm disabled")
                        }
                      }}
                    >
                      <span>
                        <CopyIcon />
                        Copy to Invoice
                      </span>
                    </Button>
                  )}
                  
                  {props.for === 'estimate' && props.mode !== 'new' && (
                    // <Tooltip
                    //   placement="topLeft"
                    //   title="Copy Estimate to Job"
                    // >
                    <Button
                      className={buttonClass}
                      type='button'
                      icon='copy'
                      size='default'
                      id='ant-btn-copy'
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          copyEstimateToJob(e)
                        } else {
                          console.log('i')
                        }
                      }}
                    >
                      
                      <span><CopyIcon /> Copy to Job</span>
                    </Button>
                    // </Tooltip>
                  )}

                  {props.for === 'estimate' && props.mode === 'new' && (
                    <Button
                      className={'btn-custom'}
                      type='button'
                      icon='copy'
                      size='default'
                      disabled={true}
                      onClick={(e) => {
                        if (buttonState == 'enable') {
                          copyEstimateToJob(e)
                        } else {
                          console.log('i')
                        }
                      }}
                    >
                      <span>
                        <CopyIcon />
                        Copy to Job
                      </span>
                    </Button>
                    // </Tooltip>
                  )}

                  
                  {props.for === 'estimate' && config.user.role === 'admin' && props.mode === 'new' && (
                    <Button
                      className={buttonClass}
                      type='success'
                      icon='mail'
                      size='default'
                      // disabled={buttonState === 'disable' && true}
                      disabled={true}
                      onClick={() => {
                        if (buttonState == 'enable') {
                          setSendCustomerPreviewModal(true)
                        } else {
                          console.log('i am disabled')
                        }
                      }}
                    >
                      <span><EmailIcon /> Send</span>
                    </Button>
                    // </Tooltip>
                  )}
                  {props.for === 'estimate' && props.mode !== 'new' && config.user.role === 'admin' && (
                    <Button
                      className={buttonClass}
                      type='success'
                      icon='mail'
                      size='default'
                      onClick={() => {
                        if (buttonState == 'enable') {
                          setSendCustomerPreviewModal(true)
                        } else {
                          console.log('i am disabled')
                        }
                      }}
                    >
                      <span><EmailIcon /> Send</span>
                    </Button>
                    // </Tooltip>
                  )}
                  

                  {props.for === 'invoice' && config.user.role === 'admin' && (
                    <Button
                      className='ant-btn ant-btn-primary'
                      type='success'
                      icon='mail'
                      size='default'
                      disabled={props.mode === 'new' && true}
                      onClick={() => {
                        sendInvoiceToCustomer()
                      }}
                    >
                      <span><EmailIcon /> Send</span>
                    </Button>
                    // </Tooltip>
                  )}

                  

                  {props.for === 'invoice' && (
                    <>
                      <Button
                        className='btn-custom'
                        type='success'
                        icon='check'
                        disabled={invoice_status === 'Paid' || props.mode === 'new'}
                        onClick={async (e) => {
                          // const user = reactLocalStorage.getObject('user')

                          console.log('line_items: ', line_items)
                          // console.log("application_fee: ", application_fee);

                          if (stripe_acct_id && stripe_acct_id.length > 0) {
                            console.log('stripe_acct_id: ', stripe_acct_id)
                            const payload = {
                              companyId: user.companyId,

                              line_items: line_items,
                              // application_fee: application_fee,

                              stripe_success_url:
                                process.env.REACT_APP_NODE_ENV === 'production'
                                  ? `https://${window.location.host}/admin/invoices?action=stripe_success&item_id=${item_id}`
                                  : `http://${window.location.host}/admin/invoices?action=stripe_success&item_id=${item_id}`,
                              stripe_cancel_url:
                                process.env.REACT_APP_NODE_ENV === 'production'
                                  ? `https://${window.location.host}/admin/invoices?action=stripe_cancelled&item_id=${item_id}`
                                  : `http://${window.location.host}/admin/invoices?action=stripe_cancelled&item_id=${item_id}`,
                            }

                            let response = await postapiAwait(`stripescustom/createsession`, payload)
                            console.log('companyInfocustom/getCompanyInfoById->resp2: ', response.data)

                            // Initialize Stripe.js with the same connected account ID used when creating
                            // the Checkout Session.

                            const stripe = await loadStripe(config.stripe_publishable_key, { stripeAccount: `${stripe_acct_id}` })

                            stripe.redirectToCheckout({
                              // Make the id field from the Checkout Session creation API response
                              // available to this file, so you can provide it as argument here
                              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                              sessionId: response.data.id,
                            })
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, display the localized error message to your customer
                            // using `error.message`.
                          } else {
                            showNotificationMsg('Please connect your Stripe account in Settings.', 'info')
                          }
                        }}
                      >
                        Pay
                      </Button>
                      <Button
                        className='btn-custom'
                        type='success'
                        icon='check'
                        disabled={invoice_status === 'Paid' || props.mode === 'new'}
                        onClick={(e) => {
                          markAsPaidHandler(item_id)
                        }}
                      >
                        Mark As Paid
                      </Button>
                    </>
                  )}


                    <button
                      className={`custom-dropdown-item nav-item-save-options ${selectedDropdownBtn === 'save-options' ? 'active' : ''}`}
                    >
                      <span
                        data-toggle='tab'
                        onClick={() => setSelectedDropdownBtn(selectedDropdownBtn === 'save-options' ? 'none' : 'save-options')}
                      >
                        <AddFileIcon />
                        <DownIcon />
                      </span>
                      <div className={`custom-dropdown-items save-dropdown-items ${props.mode}`}>
                        {props.mode === 'new' && (
                          <Button
                            id='ant-btn-save'
                            type='button'
                            className={`btn-custom`}
                            icon='save'
                            size='default'
                            onClick={(e) => {
                              props.setView_state('template')
                            }}
                          >
                            <span>Select a Template</span>
                          </Button>
                        )}
                        {props.mode !== 'new' && (
                          <Button
                            type='button'
                            className={`btn-custom`}
                            icon='save'
                            size='default'
                            onClick={(e) => {
                              setFormModal(true)
                            }}
                          >
                            <span>Attach Form</span>
                          </Button>
                        )}
                        {/* &nbsp;&nbsp; */}

                        <Button
                          id='ant-btn-save'
                          type='button'
                          className={buttonClass + ` ant-btn-save`}
                          icon='save'
                          size='default'
                          onClick={(e) => {
                            saveUpdate(e, 'template')
                            // console.log('save as template')
                          }}
                        >
                          <span>Save As Template</span>
                        </Button>
                      </div>
                    </button>
                    {(props.for === 'job' || props.for === 'estimate') && props.mode !== 'new' && (
                      <button
                        className={`custom-dropdown-item nav-item-schedule-options ${selectedDropdownBtn === 'schedule' ? 'active' : ''}`}
                      >
                        <span
                          data-toggle='tab'
                          onClick={() => setSelectedDropdownBtn(selectedDropdownBtn === 'schedule' ? 'none' : 'schedule')}
                        >
                          <ClockIcon />
                          <DownIcon />
                        </span>
                        <div className="custom-dropdown-items schedule-dropdown-items">
                          <Button
                            className={buttonClass + ' schedule-dropdown-item'}
                            type='button'
                            icon='copy'
                            size='defalut'
                            id='ant-btn-copy'
                            onClick={() => handleSelectedSchedule('scheduleStart')}
                          >
                            Start
                          </Button>
                          <Button
                            className={buttonClass + ' schedule-dropdown-item'}
                            type='button'
                            icon='copy'
                            size='defalut'
                            id='ant-btn-copy'
                            onClick={() => handleSelectedSchedule('onMyWay')}
                          >
                            On my way
                          </Button>
                          <Button
                            className={buttonClass + ' schedule-dropdown-item'}
                            type='button'
                            icon='copy'
                            size='defalut'
                            id='ant-btn-copy'
                            onClick={() => handleSelectedSchedule('finish')}
                          >
                            Finish
                          </Button>
                          
                        </div>
                      </button>
                    )}


                </div>
                
              </div>
            </Col>
          </Row>



          <Card className='tab-card tab-card-custom' style={{ marginBottom: '0', backgroundColor: 'transparent' }}>
            <CardBody>
              <Row>
                <Col md='12'>
                  <Nav className='nav-pills-info mt-2' pills>
                    <NavItem className={horizontalTabs === 'home' ? 'active' : ''}>
                      <NavLink
                        data-toggle='tab'
                        href='#pablo'
                        className={horizontalTabs === 'home' ? 'active' : ''}
                        onClick={(e) => changeActiveTab(e, 'horizontalTabs', 'home')}
                      >
                        {props.for.charAt(0).toUpperCase() + props.for.slice(1)} Information
                      </NavLink>
                      <div className='radius-left'></div>
                      <div className='radius-right'></div>
                    </NavItem>
                    <NavItem className={horizontalTabs === 'activityFeed' ? 'active' : ''}>
                      <NavLink
                        data-toggle='tab'
                        href='#pablo'
                        className={horizontalTabs === 'activityFeed' ? 'active' : ''}
                        onClick={(e) => changeActiveTab(e, 'horizontalTabs', 'activityFeed')}
                      >
                        Activity Feed
                      </NavLink>
                      <div className='radius-left'></div>
                      <div className='radius-right'></div>
                    </NavItem>
                    {props.for !== 'invoice' && props.for !== 'job' && (
                      <NavItem className={horizontalTabs === 'estimate' ? 'active' : ''}>
                        <NavLink
                          data-toggle='tab'
                          href='#pablo'
                          className={horizontalTabs === 'estimate' ? 'active' : ''}
                          onClick={(e) => changeActiveTab(e, 'horizontalTabs', 'estimate')}
                        >
                          Job
                        </NavLink>
                        <div className='radius-left'></div>
                        <div className='radius-right'></div>
                      </NavItem>
                    )}
                    {props.for === 'job' && (
                      <NavItem className={horizontalTabs === 'job' ? 'active' : ''}>
                        <NavLink
                          data-toggle='tab'
                          href='#pablo'
                          className={horizontalTabs === 'job' ? 'active' : ''}
                          onClick={(e) => changeActiveTab(e, 'horizontalTabs', 'invoice')}
                        >
                          Invoice
                        </NavLink>
                        <div className='radius-left'></div>
                        <div className='radius-right'></div>
                      </NavItem>
                    )}
                    <NavItem className={horizontalTabs === 'notes' ? 'active' : ''}>
                      <NavLink
                        data-toggle='tab'
                        href='#pablo'
                        className={horizontalTabs === 'notes' ? 'active' : ''}
                        onClick={(e) => changeActiveTab(e, 'horizontalTabs', 'notes')}
                      >
                        Notes
                      </NavLink>
                      <div className='radius-left'></div>
                      <div className='radius-right'></div>
                    </NavItem>
                    {(props.for === 'estimate' || props.for === 'job') && props.mode !== 'new' && (
                      <NavItem className={horizontalTabs === 'schedules' ? 'active' : ''}>
                        <NavLink
                          data-toggle='tab'
                          href='#pablo'
                          className={horizontalTabs === 'schedules' ? 'active' : ''}
                          onClick={(e) => changeActiveTab(e, 'horizontalTabs', 'schedules')}
                        >
                          Schedule
                        </NavLink>
                        <div className='radius-left'></div>
                        <div className='radius-right'></div>
                      </NavItem>
                    )}
                    
                  </Nav>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <TabContent className='tab-space' style={{ paddingTop: '0' }} activeTab={horizontalTabs}>
            <TabPane tabId='home'>
              <Row>
                <Col md={props.mode === 'new' ? '12' : '8'}>
                  <Card>
                    <CardHeader></CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg={8} className='edit-outer'>
                          <div className='edit-inner'>
                            <Row>
                              <Col xs={3} lg={3}>
                                <span>Services</span>
                              </Col>
                              <Col xs={2} lg={2}>
                                <div style={{ textAlign: 'center' }}>Tax</div>
                              </Col>
                              <Col
                                xs={2}
                                lg={2}
                                className='text-center'
                                style={{
                                  padding: '0 5px',
                                }}
                              >
                                <span style={{ paddingLeft: '9px' }}>Qty</span>
                              </Col>
                              <Col
                                xs={2}
                                lg={2}
                                className='text-left'
                                style={{
                                  padding: '0 5px',
                                }}
                              >
                                <span>Unit Price </span>
                              </Col>
                              <Col
                                xs={3}
                                lg={3}
                                className='text-left'
                                style={{
                                  padding: '0 5px',
                                }}
                              >
                                <span>Amount</span>
                              </Col>
                            </Row>
                            {servicelists.map((s, index) => {
                              return (
                                <Row className='mt-2' key={index} id={`servicelists_` + index}>
                                  <Col xs={4} lg={4}>
                                    <Input
                                      placeholder='Service Name *'
                                      type='text'
                                      required
                                      value={s.item_name}
                                      onChange={(e) => {
                                        let list = [...servicelists]
                                        list[index].item_name = e.target.value
                                        setServicelists(list)
                                        console.log('servicelists index: ', list[index])
                                      }}
                                    />
                                  </Col>

                                  <Col xs={1} lg={1}>
                                    <Label check>
                                      <Input
                                        type='checkbox'
                                        defaultChecked={s.taxable}
                                        value={s.taxable}
                                        style={{
                                          marginLeft: '-10.5px',
                                        }}
                                        onChange={(e) => {
                                          let taxamunt =
                                            ((servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)) / 100) * gstTaxValue +
                                            ((servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)) / 100) * pstTaxValue

                                          let _subtotal = servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)

                                          if (!e.target.checked) {
                                            servicelists[index].amount = calculatesubtotaltax(false, taxamunt, 'servicelists', index, _subtotal, e)
                                          } else {
                                            servicelists[index].amount = calculatesubtotaltax(true, taxamunt, 'servicelists', index, _subtotal, e)
                                          }
                                        }}
                                      />
                                    </Label>
                                  </Col>

                                  <Col
                                    xs={2}
                                    lg={2}
                                    style={{
                                      padding: '0 5px',
                                    }}
                                  >
                                    <Input
                                      type='text'
                                      value={s.quantity}
                                      name={'serviceQtyInput' + index}
                                      style={{
                                        width: '100%',
                                      }}
                                      onChange={(e) => {
                                        const _targetValue = e.target.value && !isNaN(e.target.value) ? parseInt(e.target.value) : ''
                                        
                                        let list = [...servicelists]
                                        list[index].quantity = _targetValue
                                        console.log('servicelists index: ', list[index])

                                        list[index].amount = calculatesubtotalamount(
                                          servicelists[index].taxable,
                                          list[index].quantity,
                                          parseFloat(servicelists[index].unit_price).toFixed(2),
                                          tax,
                                          'servicelists',
                                          list[index].amount
                                        )
                                        setServicelists(list)
                                      }}
                                      min={1}
                                    />
                                  </Col>
                                  <Col
                                    xs={2}
                                    lg={2}
                                    className='text-right'
                                    style={{
                                      padding: '0 5px',
                                    }}
                                  >
                                    <Input
                                      type='text'
                                      value={s.unit_price}
                                      name={'serviceUnitInput' + index}
                                      style={{
                                        width: '100%',
                                      }}
                                      onChange={(e) => {
                                        
                                        const _targetValue = isNaN(parseFloat(e.target.value)) ? s.unit_price : e.target.value

                                        let list = [...servicelists]
                                        list[index].unit_price = _targetValue

                                        list[index].amount = calculatesubtotalamount(
                                          servicelists[index].taxable,
                                          servicelists[index].quantity,
                                          parseFloat(list[index].unit_price).toFixed(2),
                                          tax,
                                          'servicelists',
                                          list[index].amount
                                        )
                                        setServicelists(list)
                                      }}
                                      min={1}
                                    />
                                  </Col>
                                  <Col
                                    xs={2}
                                    lg={2}
                                    className='text-right'
                                    style={{
                                      padding: '0 5px',
                                    }}
                                  >
                                    {/* <Input
                                    type={'number'}
                                    disabled
                                    className='change-total-value'
                                    style={{
                                      width: '100%',
                                      border: 'none',
                                      background: 'transparent',
                                      color: '#fff',
                                      fontSize: '14px',
                                      fontStyle: ' normal',
                                      fontHeight: '500 ',
                                      paddingLeft: '9px',
                                    }}
                                    value={
                                      servicelists[index].taxable
                                        ? servicelists[index].quantity *
                                        servicelists[index].unit_price +
                                        ((servicelists[index].quantity *
                                          servicelists[index].unit_price) /
                                          100) *
                                        tax
                                        : servicelists[index].quantity *
                                        servicelists[index].unit_price
                                    }
                                    min={0}
                                  /> */}
                                    <span
                                      className='change-total-value'
                                      style={{
                                        display: 'block',
                                        textAlign: 'left',
                                        marginTop: '8px',
                                      }}
                                    >
                                      {' '}
                                      {(servicelists[index].taxable
                                        ? servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2) +
                                          ((servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)) / 100) * gstTaxValue +
                                          ((servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)) / 100) * pstTaxValue
                                        : servicelists[index].quantity * parseFloat(servicelists[index].unit_price).toFixed(2)
                                      ).toFixed(2)}
                                    </span>
                                  </Col>
                                  <Col xs={1} lg={1} style={{ paddingLeft: '0' }}>
                                    <Button
                                      color='danger'
                                      style={{
                                        //   position: 'absolute',
                                        //   right: 52,
                                        padding: '4px 14px 8px 14px',
                                        marginLeft: '-10px',
                                        //   top: 29,
                                      }}
                                      onClick={() => {
                                        deleteserviceid(index, 'service')
                                      }}
                                    >
                                      <i className='tim-icons icon-simple-remove' />
                                    </Button>
                                  </Col>
                                </Row>
                              )
                            })}
                            <Row className='mt-2 mb-3'>
                              <Col
                                style={{
                                  width: '100%',
                                }}
                              >
                                <Button
                                  color='info'
                                  // disabled={buttonState === 'disable' && true}
                                  onClick={() => {
                                    if (customerSelected != 'Select a Customer') {
                                      serviceid++
                                      // enableSaveButton("service");
                                      setServicelists(
                                        servicelists.concat({
                                          item_name: '',
                                          // item_name: `service item ${self
                                          //   .state.servicelists.length +
                                          //   1}`,
                                          taxable: false,
                                          quantity: 1,
                                          unit_price: 0,
                                          amount: 0,
                                        })
                                      )
                                    } else {
                                      message.warning('Please Select a Customer')
                                    }
                                  }}
                                >
                                  + Add Service
                                </Button>
                              </Col>
                            </Row>
                            <Row style={{
                              marginTop: '40px',
                              paddingTop: '25px',
                              borderTop: '5px solid #faf6f5'
                            }}>
                              <Col>
                                <Row>
                                  <Col xs={3} lg={3}>
                                    <span>Materials</span>
                                  </Col>
                                  <Col xs={2} lg={2}>
                                    <div style={{ textAlign: 'center' }}>Tax</div>
                                  </Col>
                                  <Col xs={2} lg={2} className='text-center'>
                                    <span style={{ paddingLeft: '9px' }}>Qty</span>
                                  </Col>
                                  <Col
                                    xs={2}
                                    lg={2}
                                    className='text-left'
                                    style={{
                                      padding: '0 5px',
                                    }}
                                  >
                                    <span>Unit Price </span>
                                  </Col>
                                  <Col
                                    xs={3}
                                    lg={3}
                                    className='text-left'
                                    style={{
                                      padding: '0 5px',
                                    }}
                                  >
                                    <span>Amount</span>
                                  </Col>
                                </Row>
                                {materiallists.map((s, index) => {
                                  return (
                                    <Row className='mt-2' key={index} id={`materiallists_` + index}>
                                      <Col xs={4} lg={4}>
                                        <Input
                                          placeholder='Material Name'
                                          type='text'
                                          required
                                          value={s.item_name ? s.item_name : ''}
                                          onChange={(e) => {
                                            console.log('Input event: ', e.target.value)
                                            let list = [...materiallists]
                                            list[index].item_name = e.target.value
                                            setMateriallists(list)
                                            console.log('materiallists index: ', list[index])
                                          }}
                                        />
                                      </Col>

                                      <Col xs={1} lg={1}>
                                        <Label check>
                                          <Input
                                            type='checkbox'
                                            defaultChecked={s.taxable}
                                            value={s.taxable}
                                            style={{
                                              marginLeft: '-15.5px',
                                            }}
                                            onChange={(e) => {
                                              let taxamunt =
                                                ((materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)) / 100) * gstTaxValue +
                                                ((materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)) / 100) * pstTaxValue

                                              let _subtotal = materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)

                                              if (!e.target.checked) {
                                                materiallists[index].amount = calculatesubtotaltax(
                                                  false,
                                                  taxamunt,
                                                  'materiallists',
                                                  index,
                                                  _subtotal,
                                                  e
                                                )
                                              } else {
                                                materiallists[index].amount = calculatesubtotaltax(
                                                  true,
                                                  taxamunt,
                                                  'materiallists',
                                                  index,
                                                  _subtotal,
                                                  e
                                                )
                                              }
                                            }}
                                          />
                                        </Label>
                                      </Col>

                                      <Col
                                        xs={2}
                                        lg={2}
                                        style={{
                                          padding: '0 5px',
                                        }}
                                      >
                                        <Input
                                          type='text'
                                          value={s.quantity}
                                          style={{
                                            width: '100%',
                                          }}
                                          onChange={(e) => {
                                            const _targetValue = e.target.value && !isNaN(e.target.value) ? parseInt(e.target.value) : ''
                                            
                                            let list = [...materiallists]
                                            list[index].quantity = _targetValue

                                            console.log('current-value 1: ' + _targetValue)
                                            list[index].amount = calculatesubtotalamount(
                                              materiallists[index].taxable,
                                              list[index].quantity,
                                              parseFloat(materiallists[index].unit_price).toFixed(2),
                                              tax,
                                              'materiallists',
                                              list[index].amount
                                            )
                                            setMateriallists(list)
                                          }}
                                          min={1}
                                        />
                                      </Col>
                                      <Col
                                        xs={2}
                                        lg={2}
                                        className='text-right'
                                        style={{
                                          padding: '0 5px',
                                        }}
                                      >
                                        <Input
                                          type='text'
                                          value={s.unit_price}
                                          style={{
                                            width: '100%',
                                          }}
                                          onChange={(e) => {
                                            
                                            const _targetValue = isNaN(parseFloat(e.target.value)) ? s.unit_price : e.target.value
                                            let thamount = materiallists[index].quantity * _targetValue

                                            let list = [...materiallists]
                                            list[index].unit_price = _targetValue

                                            console.log('current-value 2: ' + _targetValue)
                                            list[index].amount = calculatesubtotalamount(
                                              materiallists[index].taxable,
                                              materiallists[index].quantity,
                                              parseFloat(list[index].unit_price).toFixed(2),
                                              tax,
                                              'materiallists',
                                              list[index].amount
                                            )
                                            setMateriallists(list)
                                          }}
                                          min={1}
                                        />
                                      </Col>
                                      <Col
                                        xs={2}
                                        lg={2}
                                        className='text-right'
                                        style={{
                                          padding: '0 5px',
                                        }}
                                      >
                                        {/* <Input
                                        type={'number'}
                                        disabled
                                        className='change-total-value'
                                        style={{
                                          width: '110%',
                                          border: 'none',
                                          background: 'transparent',
                                          color: '#fff',
                                          fontSize: '14px',
                                          fontStyle: ' normal',
                                          fontHeight: '500 ',
                                          paddingLeft: '9px',
                                        }}
                                        value={
                                          materiallists[index].taxable
                                            ? materiallists[index].quantity *
                                            materiallists[index]
                                              .unit_price +
                                            ((materiallists[index].quantity *
                                              materiallists[index]
                                                .unit_price) /
                                              100) *
                                            tax
                                            : materiallists[index].quantity *
                                            materiallists[index].unit_price
                                        }
                                        min={0}
                                      /> */}
                                        <span
                                          className='change-total-value'
                                          style={{
                                            display: 'block',
                                            textAlign: 'left',
                                            marginTop: '8px',
                                          }}
                                        >
                                          {(materiallists[index].taxable
                                            ? materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2) +
                                              ((materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)) / 100) * gstTaxValue +
                                              ((materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)) / 100) * pstTaxValue
                                            : materiallists[index].quantity * parseFloat(materiallists[index].unit_price).toFixed(2)
                                          ).toFixed(2)}
                                        </span>
                                      </Col>
                                      <Col xs={1} lg={1} style={{ paddingLeft: '0' }}>
                                        <Button
                                          color='danger'
                                          style={{
                                            //   position: 'absolute',
                                            //   right: 52,
                                            padding: '4px 14px 8px 14px',
                                            marginLeft: '-10px',
                                            //   top: 29,
                                          }}
                                          onClick={() => {
                                            deleteserviceid(index, 'material')
                                          }}
                                        >
                                          <i className='tim-icons icon-simple-remove' />
                                        </Button>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </Col>
                            </Row>
                            <Row className='mt-2'>
                              <Col>
                                <Button
                                  color='info'
                                  // disabled={buttonState === 'disable' && true}
                                  onClick={() => {
                                    if (customerSelected != 'Select a Customer') {
                                      serviceid++
                                      // enableSaveButton("service");
                                      setMateriallists(
                                        materiallists.concat({
                                          item_name: '',
                                          // item_name: `service item ${self
                                          //   .state.materiallists.length +
                                          //   1}`,
                                          taxable: false,
                                          quantity: 1,
                                          unit_price: 0,
                                          amount: 0,
                                        })
                                      )
                                    } else {
                                      message.warning('Please Select a Customer')
                                    }
                                  }}
                                >
                                  + Add Material
                                </Button>
                              </Col>
                            </Row>
                            <Row style={{ margin: '20px' }}>
                              {/* <Divider /> */}
                              <Col md={8}>
                                {gstTaxValue ? (
                                  <>
                                    <p>Taxes</p>
                                    <Row>
                                      <Col>
                                        <span> Type</span>
                                      </Col>
                                      <Col>
                                        <span> Number</span>
                                      </Col>
                                      <Col>
                                        <span> Tax</span>
                                      </Col>
                                    </Row>
                                    <Row style={{ fontSize: '0.75rem' }}>
                                      <Col>GST</Col>
                                      <Col>{gstTaxNum ? gstTaxNum : '-'}</Col>
                                      <Col>{gstTaxValue ? gstTaxValue + '%' : '-'}</Col>
                                    </Row>
                                    <Row style={{ fontSize: '0.75rem' }}>
                                      <Col>PST</Col>
                                      <Col>{pstTaxNum ? pstTaxNum : '-'}</Col>
                                      <Col>{pstTaxValue ? pstTaxValue + '%' : '-'}</Col>
                                    </Row>
                                  </>
                                ) : (
                                  <span>Please set tax at Settings</span>
                                )}
                              </Col>

                              <Col md={4}>
                                <div className='total-calculator'>
                                  <strong>
                                    <span className='amount-text-ext'>Total: </span>
                                    <span className='amount-text amount-text-ext'>${total.toFixed(2)}</span>
                                  </strong>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <Row>
                            <Col md={12}>
                              <h4>Select A Customer:</h4>
                              <Select
                                className='react-select info'
                                classNamePrefix='react-select'
                                placeholder='Choose Customer'
                                name='signleSelect'
                                closeMenuOnSelect={true}
                                isMulti={false}
                                value={customerSelected}
                                onChange={(value) => {
                                  setCustomerSelected({ ...value })

                                  console.log('customerSelected: ', customerSelected)
                                  // console.log('value: ', value)
                                  // setButtonState('enable')
                                  // if (
                                  //   customerSelected &&
                                  //   customerSelected.value &&
                                  //   customerSelected.value.length > 0 &&
                                  //   selectedusers &&
                                  //   selectedusers.length > 0
                                  // ) {
                                  //   setButtonState('enable')

                                  //   console.log(
                                  //     'cust->buttonState: ',
                                  //     buttonState
                                  //   )
                                  // }
                                }}
                                options={customers}
                              />
                              <Button
                                className='addnotebtn'
                                type='button'
                                icon='file-add'
                                style={{
                                  padding: '11px 0',
                                  width: '100%',
                                }}
                                onClick={() => setView_state('create_customer')}
                              >
                                + Add New Customer
                              </Button>
                            </Col>
                            <Col md={12}>
                              <h4>Assign to:</h4>
                              <Select
                                className='react-select info'
                                classNamePrefix='react-select'
                                placeholder='Choose User to Assign'
                                name='multipleSelect'
                                closeMenuOnSelect={false}
                                isMulti
                                value={selectedusers}
                                onChange={(value) => {
                                  setSelectedusers(value)
                                  if (value && value.length && value.length > 0) {
                                    setButtonState('enable')
                                  } else {
                                    setButtonState('disable')
                                  }
                                }}
                                options={userlist}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {/* </div> */}
                      </Row>
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </Col>
                {props.mode !== 'new' && (
                  <Col md='4'>
                    <Card className='card-user'>
                      <CardBody>
                        {thcustomer &&
                          directions_lat_lng &&
                          directions_lat_lng.destination &&
                          directions_lat_lng.destination.lat &&
                          directions_lat_lng.destination.lat !== -1 &&
                          (
                          <StreetViewPanormaWithAnOverlayView destination={directions_lat_lng.destination} />
                        )}
                        <div style={{ margin: '10px 0' }}>
                          <div>
                            <span>
                              {thcustomer.first_name}&nbsp;
                              {thcustomer.last_name}
                            </span>
                          </div>
                          <div>
                            <span>
                              {thcustomer.addresses
                                ? `${thcustomer.addresses[0].street}, ${thcustomer.addresses[0].city}, ${thcustomer.addresses[0].state}, ${thcustomer.addresses[0].zip}`
                                : ''}
                            </span>
                          </div>
                          <div>
                            <span>{thcustomer.work_phone}</span>
                          </div>
                          {/* <div>
                          <span>
                            {thcustomer.first_name}&nbsp;
                            {thcustomer.last_name}
                          </span>
                        </div>
                        <div>
                          <span>
                            {thcustomer.addresses
                              ? `${thcustomer.addresses[0].street}, ${thcustomer.addresses[0].city}, ${thcustomer.addresses[0].state}, ${thcustomer.addresses[0].zip}`
                              : ''}
                          </span>
                        </div>
                        <div>
                          <span>{thcustomer.work_phone}</span>
                        </div> */}
                          <div>{thcustomer && renderEmails(thcustomer)}</div>
                        </div>
                        <div>
                          {thcustomer.parent_id && (
                            <RouterNavLink to='/admin/cutomers'>
                              <Button
                                style={{ marginBottom: '11px' }}
                                className='ant-btn ant-btn-primary save-type'
                                onClick={(e) => {
                                  console.log('view parent customer')
                                  // viewParentCustomer()
                                  reactLocalStorage.setObject('parentCustomer', thcustomer.parent_id)
                                }}
                                id='viewparent'
                              >
                                View Parent Customer
                              </Button>
                            </RouterNavLink>
                          )}
                        </div>
                        <div style={{ margin: 2 }}>
                          {thcustomer
                            && directions_lat_lng
                            && directions_lat_lng.destination
                            && directions_lat_lng.destination.lat
                            && directions_lat_lng.destination.lat !== -1
                            && <ExampleDirections lat_lng={directions_lat_lng} />}
                        </div>
                      </CardBody>
                      <CardFooter></CardFooter>
                    </Card>
                  </Col>
                )}
              </Row>
            </TabPane>
            <TabPane tabId='activityFeed'>
              <Row>
                <Col>
                  <Card>
                    <CardHeader></CardHeader>
                    <CardBody>
                      <SortingTable
                        thead={[{ text: 'Name' }, { text: 'Action' }, { text: 'Date Time' }]}
                        tbody={[
                          ...activityFeeds.map((a) => {
                            return {
                              data: [
                                {
                                  text: thcustomer.first_name + ' ' + thcustomer.last_name,
                                },
                                { text: a.action },
                                { text: moment(a.created_at).format('DD/MM/YYYY') },
                              ],
                            }
                          }),
                        ]}
                      />
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='estimate'>
              <Row>
                <Col>
                  <Card>
                    <CardHeader></CardHeader>
                    <CardBody>
                      <SortingTable
                        thead={[{ text: 'Id' }, { text: 'Customer First Name' }, { text: 'Customer Last Name' }, { text: 'Total' }, { text: 'Status' }]}
                        tbody={[
                          ...jobsOfEstimate.map((a) => {
                            return {
                              data: [{ text: a.human_readable_id }, { text: a.first_name }, { text: a.last_name }, { text: a.job_total }, { text: a.status }],
                            }
                          }),
                        ]}
                      />
                      
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            {/* <TabPane tabId="job">
            <SortingTable
              thead={[
                { text: "Id" },
                { text: "Customer First Name" },
                { text: "Customer Last Name" },
                { text: "Total" },
                { text: "Status" }
              ]}
              tbody={[
                ...jobsOfEstimate.map((a) => {
                  return {
                    data: [
                      { text: a.human_readable_id },
                      { text: a.first_name },
                      { text: a.last_name },
                      { text: a.job_total },
                      { text: a.status }
                    ]
                  }
                })
              ]}
            />
          </TabPane> */}
            <TabPane tabId='invoice'>
              <Row>
                <Col>
                  <Card>
                    <CardHeader></CardHeader>
                    <CardBody>
                      <SortingTable
                        thead={[{ text: 'Id' }, { text: 'Customer Name' }, { text: 'Total' }, { text: 'Status' }]}
                        tbody={[
                          ...invoicesOfJob.map((a) => {
                            return {
                              data: [
                                { text: a.human_readable_id },
                                { text: a.first_name + ' ' + a.last_name },
                                { text: a.invoice_total },
                                { text: a.status },
                              ],
                            }
                          }),
                        ]}
                      />
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='schedules'>
              <Row>
                <Col>
                  <Card>
                    <CardHeader></CardHeader>
                    <CardBody>
                      <Schedules from={props.for} item_id={item_id} customerId={customerSelected.value} />
                    
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId='notes'>
              <Row>
                <Col>
                  <Card>
                    <CardHeader
                      style={{textAlign: 'right'}}
                    >
                      <Button
                        className='addnotebtn'
                        type='button'
                        icon='file-add'
                        onClick={() => {
                          // props.setModal(true)
                          if (props.mode === 'new') {
                            showNotificationMsg(`Please save the ${props.for} first!`, 'info')
                          } else {
                            setModal(true)
                          }
                        }}
                        style={{ width: '145px', padding: '11px ' }}
                      >
                        + Add note
                      </Button>
                    </CardHeader>
                    <CardBody>
                      {notes ? (
                        <>
                          <div>{renderNotes(notes.note)}</div>
                          <div style={{ textAlign: 'center' }}>
                            {imgPreview && (
                              <CardImg
                                src={imgPreview}
                                style={{
                                  width: '100%',
                                  maxWidth: '400px',
                                  margin: '30px auto',
                                }}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <div>
                          <p style={{ textAlign: 'center' }}>Note is empty for this {props.for}.</p>
                        </div>
                      )}
              
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          {!loading && <UpcomingNotes />}
        </div>
      ) : (
        !loading && (
          <div className='content'>
            <CreateCustomer mode='new' for='customer' setView_state={setView_state} fetchCustomers={componentDidMount} />
          </div>
        )
      )}
    </>
  )
}

export default LineItemForm
