import { getapiAwait, postapiAwait, callextendedapisAwait } from '../../helper.js'
import { reactLocalStorage } from 'reactjs-localstorage'
import { loadStripe } from '@stripe/stripe-js'
import config from '../../config'

const user = reactLocalStorage.getObject('user')

export const initStripe = async () => {
  let companyRes = await getapiAwait(`companyInfocustom/getCompanyInfoById/${user?.companyId}`, {})
  console.log('companyRes: ', companyRes)
  const stripe_id = companyRes.data[0]?.stripe_acct_id
  return stripe_id
}

export const checkIfSubscribed = async () => {
  console.log('stripeInfo.')
  const stripeInfoRes = await postapiAwait(`stripescustom/getsubscriptioninfo`, { userId: user.id })
  const ifSubscribed = stripeInfoRes.data.find((item) => {
    const temp_raw = JSON.parse(item.raw)
    if (temp_raw.object === 'subscription.' && temp_raw?.status === 'succeeded') {
      return true
    }
    return false
  })
  if (ifSubscribed) {
    return true
  } else {
    return false
  }
}

export const createSubscriptionPlan = async () => {
  //
  await postapiAwait(`stripescustom/createplans`, {})
  // console.log('plans: ', plans)
  return await getStripePlans()
}

export const getStripePlans = async () => {
  const {
    data: { plans },
  } = await postapiAwait(`stripescustom/getplans`, {})
  console.log('planslast: ', plans)
  return [
    plans.map((plan) => ({ value: parseInt(plan.amount / 100), label: `$${plan.amount / 100} per Unit per Month` })),
    plans.map((plan) => ({ price: plan.amount, priceId: plan.id })),
  ]
}

export const createSubscription = async (priceInfos, selectedSubscription, selectedTrackers) => {
  // const total_quantity = selectedTrackers.reduce((total, { quantity }) => {
  //   total += quantity
  //   return total
  // }, 0)
  const { amount } = JSON.parse(localStorage.getItem('trackers_quantity'))
  const { priceId } = priceInfos.find((price) => price.price === selectedSubscription.value * 100)
  const {
    data: { subscription, row_id },
  } = await postapiAwait('stripescustom/createsubscription', {
    priceId,
    email: user.email,
    quantity: amount,
    userId: user.id,
  })
  // localStorage.setItem('intent_key', JSON.stringify({ id: subscription.payment_intent.id }))
  return { subscription, row_id }
}

export const handlePaymentForFleetTrackers = async (stripeAccId, selectedTrackers, showMsg) => {
  const user = reactLocalStorage.getObject('user')
  const line_items = selectedTrackers.map(({ name, price, quantity }) => ({
    name,
    amount: Math.round(price * 100),
    currency: 'cad',
    quantity,
  }))
  console.log('line_items; ', line_items)
  if (stripeAccId && stripeAccId.length > 0) {
    const payload = {
      companyId: user.companyId,
      line_items: line_items,
      stripe_success_url:
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/fleet?action=stripe_success`
          : `http://${window.location.host}/admin/fleet?action=stripe_success`,
      stripe_cancel_url:
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/fleet?action=stripe_cancelled`
          : `http://${window.location.host}/admin/fleet?action=stripe_cancelled`,
    }
    let response = await postapiAwait(`stripescustom/createsession`, payload)
    console.log('companyInfocustom/getCompanyInfoById->resp2: ', response.data)
    // Initialize Stripe.js with the same connected account ID used when creating
    // the Checkout Session.
    const stripe = await loadStripe(config.stripe_publishable_key, { stripeAccount: `${stripeAccId}` })
    stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as argument here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: response.data.id,
    })
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  } else {
    showMsg('Please connect your Stripe account in Settings.', 'info')
  }
}

export const handlePaymentForDIDReservation = async (stripeAccId, notPaidTelephonyId, showMsg) => {
  const user = reactLocalStorage.getObject('user')
  const line_items = [{
    name: 'available_did',
    amount: Math.round(10 * 100),
    currency: 'cad',
    quantity: 1,
  }]
  console.log('line_items; ', line_items)
  if (stripeAccId && stripeAccId.length > 0) {
    const payload = {
      companyId: user.companyId,
      line_items: line_items,
      stripe_success_url:
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/telephony?action=${notPaidTelephonyId}`
          : `http://${window.location.host}/admin/telephony?action=${notPaidTelephonyId}`,
      stripe_cancel_url:
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/telephony?action=stripe_cancelled`
          : `http://${window.location.host}/admin/telephony?action=stripe_cancelled`,
    }
    let response = await postapiAwait(`stripescustom/createsession`, payload)
    console.log('companyInfocustom/getCompanyInfoById->resp2: ', response.data)
    // Initialize Stripe.js with the same connected account ID used when creating
    // the Checkout Session.
    const stripe = await loadStripe(config.stripe_publishable_key, { stripeAccount: `${stripeAccId}` })
    stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as argument here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: response.data.id,
    })
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  } else {
    showMsg('Please connect your Stripe account in Settings.', 'info')
  }

}

export const insertTrackerBuyInfo = async (payment_intent, payment_intent_client_secret) => {
  console.log('payment_intend: ', payment_intent, payment_intent_client_secret)
  const rawData = JSON.parse(localStorage.getItem('unit_PI'))
  localStorage.removeItem('unit_PI')
  console.log('sm rawData: ', rawData)
  if (rawData) {
    var response1 = await callextendedapisAwait('stripes/count', {});
    console.log("onStripeToken = (token) => {->response1.data: ", response1.data);
    const payload = {
      "userId": config.user.id,
      "customerId": config.user.id,
      "human_readable_id": response1.data.count + 1,
      "raw": JSON.stringify(rawData),
    };
    const res = await postapiAwait('stripescustom/saveunitbuyinfo', payload)
    console.log('sm stripescustom/saveunitbuyinfo res: ', res)
    if (res?.data?.success) {
      return true
    } else {
      return false
    }
  }
}