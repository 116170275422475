import { useEffect, useState } from 'react';
import { Button } from 'reactstrap'

import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe, 
  useElements
} from '@stripe/react-stripe-js';
import config from '../../config'
import { getapiAwait, postapiAwait } from '../../helper'

let stripePromise;
(async () => {
  stripePromise = loadStripe(config.stripe_publishable_key)
})()

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: process.env.REACT_APP_NODE_ENV === 'production'
          ? `https://${window.location.host}/admin/fleet` : `http://${window.location.host}/admin/fleet`
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button color='primary' disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

function Checkout({totalAmount}) {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    console.log('sm totalAmount: ', totalAmount)
    getStripeSecretKey()
  }, [])

  const options = {
    clientSecret,
  }

  const getStripeSecretKey = async () => {
    // Create PaymentIntent as soon as the page loads
    const {data : { paymentIntent }} = await postapiAwait('stripescustom/gpsunitcheckout', {amount: totalAmount * 100 * 50})
    console.log('paymentIntent: ', paymentIntent)
    setClientSecret(paymentIntent?.client_secret)
    if (paymentIntent.client_secret) {
      localStorage.setItem('unit_PI', JSON.stringify(paymentIntent))
    }
  }

  return (
    <main className='stripe-checkout'>
      <h1>Payment</h1>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </main>
  );
}

export default Checkout;