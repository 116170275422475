import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'
import { callapi, loginAwait } from '../../helper'
import Config from './../../config'
import { reactLocalStorage } from 'reactjs-localstorage'
import { Spinner } from 'reactstrap'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'

const Login = ({ history }) => {
  const [state, setState] = useState({})
  const [loadingSignUp, setLoadingSignUp] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)

  const [firstname, setFirstname] = useState(false)
  const [lastname, setLastname] = useState(false)
  const [userName, setUsername] = useState(false)
  const [password, setPassword] = useState(false)
  const [toast, setToast] = useState(false)
  const [toastHeader, setToastHToastHeader] = useState('')
  const [toastBody, setToastBoToastBody] = useState('')
  const [currentState, setCurrentState] = useState('login')

  useEffect(() => {
    setCurrentState('login')

    document.body.classList.toggle('login-page')
    return function cleanup() {
      document.body.classList.toggle('login-page')
    }
  })

  function toogleToast(type, header = '', body = '') {
    setToast(type)
    setToastHToastHeader(header)
    setToastBoToastBody(body)
    setTimeout(() => {
      setToast(false)
    }, 5000)
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (currentState == 'login') {
      setLoadingLogin(true)

      const postData = {
        email: userName,
        password: password,
      }

      console.log(postData)
      let data = null
      try {
        data = await loginAwait('users/login', postData)
      } catch (error) {
        console.log('error is: ', error)
        toogleToast(true, 'Error', 'Login failed')
        setLoadingLogin(false)
      }
      if (data == null) {
        console.log('data is null')
        return
      }
      data = data.data
      console.log('responseData: ', data)

      if (data.status === 'error') {
        // alert(data.msg);
        // message.error(data.msg);
        toogleToast(true, 'Error', data.msg)
        setLoadingLogin(false)
      } else {
        console.log('data', data)
        //alert(data);
        // data.id = data.token;
        reactLocalStorage.setObject('authkey', data.id)

        const getUser = callapi(
          'users/' + data.userId + '?',
          { ttl: 315360000000 },
          data.id
        )

        getUser.then((user) => {
          console.log('New user: ', user)
          reactLocalStorage.setObject('user', user)
          toogleToast(true, 'Success', 'Login Successfull')
          if (user.role === 'super admin') {
            history.push('/admin/super-admin')
          } else if (user.role === 'admin') {
            history.push('/admin/dashboard')
          } else {
            history.push('/user/dashboard')
          }
          //window.location.href = "/admin";
          // })
        })
      }
    }

    setCurrentState('login')
  }

  async function handleSubmitSignup(e) {
    // if (currentState == "signup") {
    //   setLoadingSignUp(true)
    // }
    // setCurrentState("signup")

    history.push('/auth/register')
  }

  return (
    <>
      <Toast
        isOpen={toast}
        style={{
          position: 'absolute',
          right: 12,
        }}
      >
        <div
          className='p-3 my-2 rounded ml-auto'
          style={{ backgroundColor: 'white', width: 300 }}
        >
          <ToastHeader>{toastHeader}</ToastHeader>
          <ToastBody>{toastBody}</ToastBody>
        </div>
      </Toast>
      <div className={`content`}>
        <Container>
          <Col
            className='ml-auto mr-auto'
            lg='10'
            md='10'
            style={{ backgroundColor: 'white', borderRadius: 30 }}
          >
            <Row>
              <Col
                className='ml-auto mr-auto'
                lg='6'
                md='6'
                style={{
                  backgroundColor: '#ff6c02',
                  borderRadius: '30px 0px 0px 30px',
                  padding: 20,
                  textAlign: 'center',
                  minHeight: 450,
                }}
              >
                <img
                  className='d-flex ml-2 mt-3'
                  alt='worklocker-logo'
                  src={require('assets/img/worklocker.png').default}
                  style={{ height: '35px' }}
                />
                <div className='' style={{ marginTop: 50 }}>
                  <h4 style={{ color: '#fff' }}>Nice to see you again</h4>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      fontSize: 40,
                      color: '#fff',
                      marginBottom: 10,
                      marginTop: 30,
                    }}
                  >
                    Let's get to work
                  </h2>
                  <img
                    className='d-flex m-auto'
                    alt='worklocker-logo'
                    src={
                      require('assets/img/horizontal-rule-white.png').default
                    }
                    style={{ width: 70, height: 5 }}
                  />
                  <div style={{ marginTop: 40 }}>
                    <p>
                      <strong
                        style={{ color: '#fff', cursor: 'pointer' }}
                        onClick={() => {
                          history.push('/auth/forgot-password')
                        }}
                      >
                        Forgot password?{' '}
                      </strong>
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                className='ml-auto mr-auto'
                lg='6'
                md='6'
                style={{
                  padding: 30,
                  textAlign: 'center',
                }}
              >
                <div style={{ marginTop: 70 }}>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      color: '#686868',
                      marginBottom: 10,
                    }}
                  >
                    {currentState == 'login'
                      ? 'Sign in to Account'
                      : 'Crete an Account'}
                  </h2>
                  <div className='mb-5'>
                    <img
                      className='d-flex m-auto'
                      alt='worklocker-logo'
                      src={require('assets/img/horizontal-rule.png').default}
                      style={{ width: 70, height: 5 }}
                    />
                  </div>
                  {currentState == 'login' && (
                    <div>
                      <InputGroup
                        className={classnames({
                          'input-group-focus': state.emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                            }}
                          >
                            <i className='tim-icons icon-email-85' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder='Enter Username'
                          type='text'
                          onFocus={(e) =>
                            setState({ ...state, emailFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, emailFocus: false })
                          }
                          style={{
                            border: 0,
                            borderBottom: '1px solid gray',
                            borderRadius: 0,
                            borderColor: '1px solid #d0d0d0',
                            color: 'black',
                          }}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          'input-group-focus': state.passFocus,
                        })}
                        style={{ marginTop: 20 }}
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                            }}
                          >
                            <i className='tim-icons icon-lock-circle' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onKeyPress={(e) => {
                            console.log('key: ', e.charCode)
                            if (e.charCode === 13) {
                              if (e.target.value.length > 0) {
                                handleSubmit(e)
                              }
                            }
                          }}
                          onChange={(e) => {
                            console.log('e.target: ', e.target)
                            setPassword(e.target.value)
                          }}
                          placeholder='Password'
                          type='password'
                          onFocus={(e) =>
                            setState({ ...state, passFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, passFocus: false })
                          }
                          style={{
                            border: 0,
                            borderBottom: '1px solid gray',
                            borderRadius: 0,
                            borderColor: '1px solid #d0d0d0',
                            color: 'black',
                          }}
                        />
                      </InputGroup>
                    </div>
                  )}

                  {/* {currentState == "signup" && <div>
                  <InputGroup
                      className={classnames({
                        "input-group-focus": state.firstnameFocus,
                      })}

                    >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0' }}>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        onFocus={(e) => setState({ ...state, firstnameFocus: true })}
                        onBlur={(e) => setState({ ...state, firstnameFocus: false })}
                        style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0', color: 'black' }}
                        onChange={e => setFirstname(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.lastnameFocus,
                      })}

                    >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0' }}>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        onFocus={(e) => setState({ ...state, lastnameFocus: true })}
                        onBlur={(e) => setState({ ...state, lastnameFocus: false })}
                        style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0', color: 'black' }}
                        onChange={e => setLastname(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.emailFocus,
                      })}

                    >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0' }}>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email Address"
                        type="text"
                        onFocus={(e) => setState({ ...state, emailFocus: true })}
                        onBlur={(e) => setState({ ...state, emailFocus: false })}
                        style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0', color: 'black' }}
                        onChange={e => setUsername(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.passFocus,
                      })}
                      style={{ marginTop: 20 }}
                    >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0' }}>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        style={{ border: 0, borderBottom: '1px solid gray', borderRadius: 0, borderColor: '1px solid #d0d0d0', color: 'black' }}
                      />
                    </InputGroup>
                  </div>} */}

                  <button
                    className='mb-2'
                    onClick={(e) => handleSubmit(e)}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      marginTop: 20,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    {loadingLogin && (
                      <Spinner color='white' size='sm' className='mr-2' />
                    )}
                    Login
                  </button>
                  <button
                    className='mb-3'
                    onClick={(e) => handleSubmitSignup(e)}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    {loadingSignUp && (
                      <Spinner color='white' size='sm' className='mr-2' />
                    )}
                    Signup
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </>
  )
}

export default Login
