import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import ReactTable from 'components/ReactTable/ReactTable.js'

import config from '../../config.js'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func } from 'prop-types'
import CreateUser from './CreateUser.js'
import NotificationAlert from 'react-notification-alert'

import {
  callapi,
  callextendedapisAwait,
  insertActivityLog,
  callExtendedApiPostAwait,
  delapi,
  getapiAwait,
  postapiAwait,
} from '../../helper.js'
import { reactLocalStorage } from 'reactjs-localstorage'
import UpcomingNotes from 'views/components/UpcomingNotes.js'

export default function Users() {
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [userForEdit, setUserForEdit] = useState({})
  const [data, setData] = React.useState([])
  const [user_id, setUser_id] = React.useState(null)
  const [view_state, setView_state] = React.useState('datatable')
  const [modal, setModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [upadeStatus, setUpdateStatus] = useState(null)

  const notificationAlertRef = React.useRef(null)

  async function fetchUsers() {
    setLoading(true)
    const user = reactLocalStorage.getObject('user')
    const getcustomers = await callextendedapisAwait(
      `userscustom/getUsersforCompany/${user.companyId}`,
      {}
    )
    console.log(
      'async getAllEstimates() {->dataTableDataArr: ',
      getcustomers.data
    )
    setLoading(false)
    setUserData(getcustomers.data)
    console.log('here is user root data ', getcustomers.data)
  }
  useEffect(() => {
    fetchUsers()
  }, [])
  useEffect(() => {
    const data = userData.map((prop, key) => {
      return {
        _id: key,
        id: prop.id,
        name: prop.first_name + ' ' + prop.last_name,
        email: prop.email,
        role: prop.role,
        userHumanReadableId: prop.human_readable_id,
        actions: (
          <div className='actions-right'>
            {/* use this button to remove the data row */}
            {prop.role !== 'admin' && (
              <Button
                disabled={prop.role === 'admin' && true}
                onClick={() => {
                  let obj = data.find((o) => o._id === key)
                  setDeleteId(obj.id)
                  setModal(!modal)
                  console.log('data is deleted id', obj.id)
                }}
                color='danger'
                size='sm'
                className={classNames('btn-icon btn-link like', {
                  'btn-neutral': key < 5,
                })}
              >
                <i className='tim-icons icon-simple-remove' />
              </Button>
            )}
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                editUser(obj.id)
              }}
              color='warning'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
            >
              <i className='tim-icons icon-pencil' />
            </Button>{' '}
          </div>
        ),
      }
    })
    setData(data)
  }, [userData])

  useEffect(() => {
    if (upadeStatus === 'updated' || upadeStatus === 'inserted')
      setTimeout(() => {
        showNotificationMsg('User ' + upadeStatus + ' successfully!', 'success')
        setUpdateStatus('')
      }, 500)
  }, [view_state])

  function editUser(user_id) {
    setUser_id(user_id)
    let getUserForEdit = userData.find((item) => item.id === user_id)
    setUserForEdit(getUserForEdit)
    console.log('here is user for edit ', getUserForEdit)
    setView_state('edit_user')
  }

  const toggle = () => setModal(!modal)

  function onOk() {
    setModal(!modal)
    var newdata = userData
    console.log('userData ', userData)

    if (deleteId) {
      const deleteuser = postapiAwait('userscustom/delete', { id: deleteId })

      // promise after called api and check for the api status
      deleteuser.then((myData) => {
        if (!myData) {
          showNotificationMsg('Sorry! Cannot delete user.', 'danger')
        } else {
          console.log(' my data ', myData)
          newdata.find((o, i) => {
            if (o.id === deleteId) {
              newdata.splice(i, 1)
              setDeleteId(null)
              return true
            }
            return false
          })
          showNotificationMsg('User has been deleted.', 'success')
          setUserData(newdata)
          fetchUsers()
        }
      })
    }
  }

  function prepareForEdit(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    }
    let readableUserId = parseInt(targetRow.firstChild.innerText)
    console.log('readableInvoiceId ', readableUserId)
    console.log('data ', data)

    let userItem = data.find(
      (item) =>
        readableUserId.toString() === item.userHumanReadableId.toString()
    )
    editUser(userItem.id)
  }

  function setMousePointer(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    } else {
      targetRow = e.target.parentElement.parentElement.parentElement
    }
    if (targetRow && !targetRow.style.cursor) {
      targetRow.style.cursor = 'pointer'
      targetRow.style.userSelect = 'none'
    }
  }

  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (
      notificationAlertRef !== null &&
      notificationAlertRef.current !== null
    ) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }

  if (loading) {
    return (
      <>
        <div
          className='content'
          style={{ textAlign: 'center', transition: 'none' }}
        >
          <Spinner color='white' size='sm' className='mr-2' />
        </div>
      </>
    )
  } else {
    if (view_state == 'datatable') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal
              isOpen={modal}
              fade={false}
              toggle={toggle}
              className={classNames}
            >
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this user?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the user information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div
            className='content user'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              <Col>{console.log('Data------', data)}</Col>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader style={{ textAlign: 'right' }}>
                    {/* <CardTitle tag="h4">React Table</CardTitle> */}
                    <Button
                      color='primary'
                      onClick={() => setView_state('create_user')}
                    >
                      + Add New
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div className='user-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Id',
                            accessor: 'userHumanReadableId',
                          },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Email',
                            accessor: 'email',
                          },
                          {
                            Header: 'Role',
                            accessor: 'role',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state == 'edit_user') {
      return (
        <div className='content'>
          <CreateUser
            mode='edit'
            item_id={user_id}
            setView_state={setView_state}
            userForEdit={userForEdit}
            fetchUsers={fetchUsers}
            setUpdateStatus={setUpdateStatus}
          />
        </div>
      )
    } else if (view_state == 'create_user') {
      return (
        <div className='content'>
          <CreateUser
            mode='new'
            setView_state={setView_state}
            fetchUsers={fetchUsers}
            setUpdateStatus={setUpdateStatus}
          />
        </div>
      )
    }
  }
}
