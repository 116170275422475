/*global google*/

import React from 'react'
import config from './../config'

const { compose, withProps, lifecycle, defaultProps } = require('recompose')

const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} = require('react-google-maps')

const MapWithADirectionsRenderer = compose(
  withProps({
    // googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places",
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapAPIKey}&v=3.exp&libraries=geometry,drawing,places`,
    dataType: 'jsonp',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new google.maps.DirectionsService()

      DirectionsService.route(
        {
          origin: new google.maps.LatLng(
            this.props.lat_lng.origin.lat,
            this.props.lat_lng.origin.lng
            // 41.85073,
            // -87.65126
          ),
          destination: new google.maps.LatLng(
            this.props.lat_lng.destination.lat,
            this.props.lat_lng.destination.lng
            // 41.85258,
            // -87.65141
          ),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log('status from google map direction: ', this)
          console.log('status from google map direction: ', status)
          console.log('status from google.maps.DirectionsStatus.OK: ', google.maps.DirectionsStatus.OK)
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result,
            })
            // this.props.directions = Object.assign({}, result)
            console.log(`result fetching directions: `, result)
          } else {
            console.error(`error fetching directions: `, result)
          }
        }
      )

      // DirectionsService.route({45.452464214069096, -73.88619364571792
      //     origin: new google.maps.LatLng(14.533593, 121.053128),
      //     destination: new google.maps.LatLng(14.550895, 121.025079),
      //     travelMode: google.maps.TravelMode.DRIVING,
      //     waypoints: [
      //         {
      //             location: new google.maps.LatLng(14.546748, 121.05455)
      //         },
      //         {
      //             location: new google.maps.LatLng(14.552444, 121.044488)
      //         }
      //     ]
      // }, (result, status) => {
      //     if (status === google.maps.DirectionsStatus.OK) {
      //         this.setState({
      //             directions: result,
      //         });
      //     } else {
      //         console.error(`error fetching directions ${result}`);
      //     }
      // });
    },
  })
)((props) => {
  console.log('props from google map: ', props)
  return (
    <GoogleMap
      defaultZoom={7}
      defaultCenter={new google.maps.LatLng(41.85073, -87.65126)}
    >
      {props.directions && <DirectionsRenderer directions={props.directions} />}
    </GoogleMap>
  )
})

export default MapWithADirectionsRenderer

// /*global google*/
// import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
// import {
//     GoogleMap,
//     DirectionsService,
//     DirectionsRenderer
// } from '@react-google-maps/api'

// const ExampleDirectionsPropTypes = {
//     styles: PropTypes.shape({
//         container: PropTypes.object.isRequired
//     }).isRequired
// }

// const center = {
//     lat: 0,
//     lng: -180
// }

// class ExampleDirections extends PureComponent {
//     static propTypes = ExampleDirectionsPropTypes

//     state = {
//         response: null,
//         travelMode: 'DRIVING',
//         origin: '',
//         destination: ''
//     }

//     directionsCallback = response => {
//         console.log(response)

//         if (response !== null) {
//             if (response.status === 'OK') {
//                 this.setState(
//                     () => ({
//                         response
//                     })
//                 )
//             } else {
//                 console.log('response: ', response)
//             }
//         }
//     }

//     checkDriving = ({ target: { checked } }) => {
//         checked &&
//             this.setState(
//                 () => ({
//                     travelMode: 'DRIVING'
//                 })
//             )
//     }

//     checkBicycling = ({ target: { checked } }) => {
//         checked &&
//             this.setState(
//                 () => ({
//                     travelMode: 'BICYCLING'
//                 })
//             )
//     }

//     checkTransit = ({ target: { checked } }) => {
//         checked &&
//             this.setState(
//                 () => ({
//                     travelMode: 'TRANSIT'
//                 })
//             )
//     }

//     checkWalking = ({ target: { checked } }) => {
//         checked &&
//             this.setState(
//                 () => ({
//                     travelMode: 'WALKING'
//                 })
//             )
//     }

//     getOrigin = ref => {
//         this.origin = ref
//     }

//     getDestination = ref => {
//         this.destination = ref
//     }

//     onClick = () => {
//         if (this.origin.value !== '' && this.destination.value !== '') {
//             this.setState(
//                 () => ({
//                     origin: this.origin.value,
//                     destination: this.destination.value
//                 })
//             )
//         }
//     }

//     onMapClick = (...args) => {
//         console.log('onClick args: ', args)
//     }

//     render = () => {
//         const self = this;
//         return (
//             <div className='map'>
//                 <div className='map-settings'>
//                     <hr className='mt-0 mb-3' />

//                     <div className='row'>
//                         <div className='col-md-6 col-lg-4'>
//                             <div className='form-group'>
//                                 <label htmlFor='ORIGIN'>Origin</label>
//                                 <br />
//                                 <input id='ORIGIN' className='form-control' type='text' ref={this.getOrigin} />
//                             </div>
//                         </div>

//                         <div className='col-md-6 col-lg-4'>
//                             <div className='form-group'>
//                                 <label htmlFor='DESTINATION'>Destination</label>
//                                 <br />
//                                 <input id='DESTINATION' className='form-control' type='text' ref={this.getDestination} />
//                             </div>
//                         </div>
//                     </div>

//                     <div className='d-flex flex-wrap'>
//                         <div className='form-group custom-control custom-radio mr-4'>
//                             <input
//                                 id='DRIVING'
//                                 className='custom-control-input'
//                                 name='travelMode'
//                                 type='radio'
//                                 checked={this.state.travelMode === 'DRIVING'}
//                                 onChange={this.checkDriving}
//                             />
//                             <label className='custom-control-label' htmlFor='DRIVING'>Driving</label>
//                         </div>

//                         <div className='form-group custom-control custom-radio mr-4'>
//                             <input
//                                 id='BICYCLING'
//                                 className='custom-control-input'
//                                 name='travelMode'
//                                 type='radio'
//                                 checked={this.state.travelMode === 'BICYCLING'}
//                                 onChange={this.checkBicycling}
//                             />
//                             <label className='custom-control-label' htmlFor='BICYCLING'>Bicycling</label>
//                         </div>

//                         <div className='form-group custom-control custom-radio mr-4'>
//                             <input
//                                 id='TRANSIT'
//                                 className='custom-control-input'
//                                 name='travelMode'
//                                 type='radio'
//                                 checked={this.state.travelMode === 'TRANSIT'}
//                                 onChange={this.checkTransit}
//                             />
//                             <label className='custom-control-label' htmlFor='TRANSIT'>Transit</label>
//                         </div>

//                         <div className='form-group custom-control custom-radio mr-4'>
//                             <input
//                                 id='WALKING'
//                                 className='custom-control-input'
//                                 name='travelMode'
//                                 type='radio'
//                                 checked={this.state.travelMode === 'WALKING'}
//                                 onChange={this.checkWalking}
//                             />
//                             <label className='custom-control-label' htmlFor='WALKING'>Walking</label>
//                         </div>
//                     </div>

//                     <button className='btn btn-primary' type='button' onClick={this.onClick}>
//                         Build Route
//         </button>
//                 </div>

//                 <div className='map-container'>
//                     <GoogleMap
//                         id='direction-example'
//                         //   mapContainerStyle={self.props.styles.container}
//                         zoom={2}
//                         center={center}
//                         onClick={this.onMapClick}
//                     >
//                         {
//                             (
//                                 this.state.destination !== '' &&
//                                 this.state.origin !== ''
//                             ) && (
//                                 <DirectionsService
//                                     options={{ // eslint-disable-line react-perf/jsx-no-new-object-as-prop
//                                         destination: this.state.destination,
//                                         origin: this.state.origin,
//                                         travelMode: this.state.travelMode
//                                     }}
//                                     callback={this.directionsCallback}
//                                 />
//                             )
//                         }

//                         {
//                             this.state.response !== null && (
//                                 <DirectionsRenderer
//                                     options={{ // eslint-disable-line react-perf/jsx-no-new-object-as-prop
//                                         directions: this.state.response
//                                     }}
//                                 />
//                             )
//                         }
//                     </GoogleMap>
//                 </div>
//             </div>
//         );
//     }
// }

// export default ExampleDirections
