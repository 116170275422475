import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import ReactTooltip from 'react-tooltip'

import ReactTable from 'components/ReactTable/ReactTable.js'

import { reactLocalStorage } from 'reactjs-localstorage'

import {
  callapi,
  callextendedapisAwait,
  insertActivityLog,
  callExtendedApiPostAwait,
  delapi,
  getapiAwait,
  postapiAwait,
} from '../../helper.js'
import config from '../../config.js'
import cloneDeep from 'lodash/cloneDeep'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func, resetWarningCache } from 'prop-types'
import ResProcess from './ResProcess.js'
import NotificationAlert from 'react-notification-alert'
import TinyMCEeditor from 'components/TinyMCEeditor/TinyMCEeditor.js'

export default function SuperAdmin() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = React.useState([])
  const [view_state, setView_state] = React.useState('overview')
  const [estimateCount, setEstimateCount] = React.useState(0)
  const [jobCount, setJobCount] = React.useState(0)
  const [invoiceCount, setInvoiceCount] = React.useState(0)
  const [customerCount, setCustomerCount] = React.useState(0)
  const [userCount, setUserCount] = React.useState(0)
  const [companyCount, setCompanyCount] = React.useState(0)
  const [customersData, setCustomersData] = React.useState([])
  const [usersData, setUsersData] = React.useState([])
  const [companiesData, setConpaniesData] = React.useState([])
  const [deleteId, setDeleteId] = React.useState([])

  const [modal, setModal] = React.useState(false)
  const [noteModal, setNoteModal] = React.useState(false)

  const [note, setNote] = React.useState(null)

  const notificationAlertRef = React.useRef(null)

  const user = reactLocalStorage.getObject('user')
  // console.log('user: ', user)

  async function setAllNotes() {
    const getAllNotes = await callextendedapisAwait(
      'companyInfocustom/upcomingnotecustom/getallnotes',
      {}
    )
    getAllNotes.data.forEach((noteItem) => {
      if (noteItem.name === 'estimate') setNote(noteItem.note)
    })
  }

  async function fetchData() {
    setLoading(true)

    setAllNotes()

    // for all customers
    const getAllCustomers = await callextendedapisAwait(
      'customers/allCustomers',
      {}
    )

    // for all users
    const getAllUsers = await callextendedapisAwait(
      `userscustom/getAllUsers`,
      {}
    )

    // for all companies
    const getAllCompanies = await callextendedapisAwait(
      `companyInfocustom/getAllCompanies`,
      {}
    )

    //count

    const estimateCountRes = await callextendedapisAwait(
      `estimatescustom/countAll`,
      {}
    )
    if (estimateCountRes && estimateCountRes.data && estimateCountRes.data[0]) {
      setEstimateCount(estimateCountRes.data[0].estimate_count)
    }

    const jobCountRes = await callextendedapisAwait(`jobscustom/countAll`, {})
    if (jobCountRes && jobCountRes.data && jobCountRes.data[0]) {
      setJobCount(jobCountRes.data[0].job_count)
    }

    const invoiceCountRes = await callextendedapisAwait(
      `invoicescustom/countAll`,
      {}
    )
    if (invoiceCountRes && invoiceCountRes.data && invoiceCountRes.data[0]) {
      setInvoiceCount(invoiceCountRes.data[0].invoice_count)
    }

    if (
      !getAllCustomers.data.error ||
      !getAllUsers.data.error ||
      !getAllCompanies.data.error
    ) {
      setCustomerCount(getAllCustomers.data.length)
      setUserCount(getAllUsers.data.length)
      setCompanyCount(getAllCompanies.data.length)
      loadTableData(
        getAllCustomers.data,
        getAllUsers.data,
        getAllCompanies.data
      )
      // console.log('getAllCustomers: ', getAllCustomers.data)
      // console.log('getAllUsers: ', getAllUsers.data)
      // console.log('getAllCompanies: ', getAllCompanies.data)
    } else {
      console.log('here some error to fetch data.')
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {}, [customersData, usersData])

  const loadCustomerTableData = (allCustomers) => {
    let tableData = []

    tableData = allCustomers.map((prop, key) => {
      return {
        _id: key,
        id: prop.id,
        name: prop.first_name + ' ' + prop.last_name,
        company: prop.company,
        email: prop.primary_email,
        mobile: prop.mobile_phone,
        actions: (
          <div className='actions-right'>
            <Button
              onClick={() => {
                // let obj = customersData.find((o) => o._id === key)
                setDeleteId(prop.id)
                setModal(!modal)
                // console.log('prop from button onclick: ', prop)
                // console.log('obj from button onclick: ', key, obj)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-delete'
              data-tip='Delete'
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-delete'
              className='tooltip-for-delete'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
          </div>
        ),
      }
    })
    setCustomersData(tableData)
  }

  const loadUserTableData = (allUsers) => {
    let tableData = []

    tableData = allUsers.map((prop, key) => {
      return {
        _id: key,
        id: prop.id,
        name: prop.first_name + ' ' + prop.last_name,
        email: prop.email,
        role: prop.role,
        userHumanReadableId: prop.human_readable_id,
        actions: (
          <div className='actions-right'>
            <Button
              onClick={() => {
                // let obj = usersData.find((o) => o._id === key)
                setDeleteId(prop.id)
                setModal(!modal)
                // console.log('data is deleted id', obj.id)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-delete'
              data-tip='Delete'
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-delete'
              className='tooltip-for-delete'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
          </div>
        ),
      }
    })
    setUsersData(tableData)
  }

  const loadCompanyTableData = (allCompanies) => {
    let tableData = []
    tableData = allCompanies.map((prop, key) => {
      return {
        _id: key,
        id: prop.id,
        name: prop.companyName,
        email: prop.companyEmail,
        website: prop.website,
        actions: (
          <div className='actions-right'>
            <Button
              onClick={() => {
                // let obj = companiesData.find((o) => o._id === key)
                setDeleteId(prop.id)
                setModal(!modal)
                // console.log('data is deleted id', obj.id)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
              data-for='tooltip-for-delete'
              data-tip='Delete'
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>{' '}
            <ReactTooltip
              id='tooltip-for-delete'
              className='tooltip-for-delete'
              delayHide={500}
              textColor='#F8F8DF'
              backgroundColor='#1E1E2B'
              effect='solid'
            />
          </div>
        ),
      }
    })
    setConpaniesData(tableData)
  }

  function loadTableData(allCustomers, allUsers, allCompanies) {
    loadCustomerTableData(allCustomers)
    loadUserTableData(allUsers)
    loadCompanyTableData(allCompanies)
    setLoading(false)
  }

  async function updateUpcomingNote() {
    const postData = {
      name: 'estimate',
      note: note,
    }
    console.log('post note update data : ', postData)
    const res = await postapiAwait(
      'companyInfocustom/upcomingnotecustom/updatenote',
      postData
    )
    showNotificationMsg(`Upcoming note updated successfully`, 'success')
    setAllNotes()
    console.log('updateUpcomingNote: ', res)
  }

  const toggle = () => setModal(false)
  const noteModalToggle = () => setNoteModal(false)
  const onOk = () => {
    setModal(!modal)
    if (view_state === 'customers') {
      const delCustomer = delapi('customers/' + deleteId + '?')
      delCustomer.then((myData) => {
        if (myData.count === '0') {
          showNotificationMsg('Sorry! Cannot delete the customer.', 'warning')
        } else {
          showNotificationMsg('Customer has been deleted.', 'success')
          setCustomersData(customersData.filter((o) => o.id !== deleteId))
          setDeleteId(null)
        }
      })
    } else if (view_state === 'users') {
      const deleteuser = postapiAwait('userscustom/delete', { id: deleteId })
      deleteuser.then((myData) => {
        if (!myData) {
          showNotificationMsg('Sorry! Cannot delete user.', 'danger')
        } else {
          showNotificationMsg('User has been deleted.', 'success')
          setUsersData(usersData.filter((o) => o.id !== deleteId))
          setDeleteId(null)
        }
      })
    }
  }
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }

  return (
    <>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div>
        <Modal isOpen={modal} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <i className='tim-icons icon-alert-circle-exc' /> Do you want to
            delete this estimate?
          </ModalHeader>
          <ModalBody>
            You cannot retrieve the estimate information once you deleted.
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={onOk}>
              Ok
            </Button>{' '}
            <Button
              color='secondary'
              onClick={() => {
                setDeleteId(null)
                setModal(!modal)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={noteModal}
          fade={false}
          toggle={noteModalToggle}
          className='mce-editor'
        >
          <ModalHeader toggle={noteModalToggle}>
            <i className='tim-icons icon-alert-circle-exc' /> Please type
            upcoming note
          </ModalHeader>
          <ModalBody>
            <TinyMCEeditor initialValue={note} setNote={setNote} />
            {/* <Form>
              <FormGroup>
                <Label for='exampleText'>Enter Note</Label>
                <Input
                  type='textarea'
                  name='text'
                  id='exampleText'
                  value={note}
                  style={{
                    color: '#000',
                    fontSize: '14px',
                  }}
                  onChange={(e) => {
                    setNote(e.target.value)
                  }}
                />
              </FormGroup>
            </Form> */}
          </ModalBody>
          <ModalFooter>
            <Button
              color='secondary'
              onClick={() => {
                setNoteModal(false)
              }}
            >
              Cancel
            </Button>{' '}
            <Button
              color='primary'
              onClick={(e) => {
                updateUpcomingNote()
                setNoteModal(false)
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div className='content super-admin'>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader style={{ textAlign: 'right' }}>
                <Nav className='nav-pills-info mt-2' pills>
                  <NavItem>
                    <NavLink
                      data-toggle='tab'
                      href='#pablo'
                      className={view_state === 'overview' ? 'active' : ''}
                      onClick={(e) => {
                        e.preventDefault()
                        setView_state('overview')
                        // fetchData()
                      }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle='tab'
                      href='#pablo'
                      className={view_state === 'customers' ? 'active' : ''}
                      onClick={(e) => {
                        e.preventDefault()
                        setView_state('customers')
                        // fetchData()
                      }}
                    >
                      Customers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle='tab'
                      href='#pablo'
                      className={view_state === 'users' ? 'active' : ''}
                      onClick={(e) => {
                        e.preventDefault()
                        setView_state('users')
                        // fetchData()
                      }}
                    >
                      Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle='tab'
                      href='#pablo'
                      className={view_state === 'companies' ? 'active' : ''}
                      onClick={(e) => {
                        e.preventDefault()
                        setView_state('companies')
                        // fetchData()
                      }}
                    >
                      Companies
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ marginLeft: 'auto' }}>
                    <Row>
                      <Col>
                        <Button
                          className='addnotebtn'
                          type='button'
                          icon='file-add'
                          onClick={() => {
                            setNoteModal(true)
                          }}
                          style={{ width: '190px', padding: '11px ' }}
                        >
                          {note && note !== ''
                            ? 'Edit Upcoming Note'
                            : '+ Add Upcoming note'}
                        </Button>
                        {/* {note && note !== '' && (
                          <Button
                            className='addnotebtn'
                            type='button'
                            icon='file-add'
                            onClick={() => {
                              updateUpcomingNote('delete', 'customer')
                            }}
                            style={{ width: '145px', padding: '11px ' }}
                          >
                            Delete Note
                          </Button>
                        )} */}
                      </Col>
                    </Row>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
            {loading ? (
              <>
                <div
                  className='content'
                  style={{ textAlign: 'center', transition: 'none' }}
                >
                  <Spinner
                    color='white'
                    size='sm'
                    className='mr-2'
                    style={{ color: '#6d6d6d' }}
                  />
                </div>
              </>
            ) : view_state === 'overview' ? (
              <Row>
                <Col lg='4' md='6'>
                  <Card className='card-stats'>
                    <CardBody>
                      <Row>
                        <Col xs='5'>
                          <div style={{ fontSize: '1.8em' }}>
                            <i className='tim-icons icon-single-02' />
                          </div>
                        </Col>
                        <Col xs='7'>
                          <div className='numbers'>
                            <p className='card-category'>Customers</p>
                            <CardTitle tag='h3'>{customerCount}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='4' md='6'>
                  <Card className='card-stats'>
                    <CardBody>
                      <Row>
                        <Col xs='5'>
                          <div style={{ fontSize: '1.8em' }}>
                            <i className='tim-icons icon-badge' />
                          </div>
                        </Col>
                        <Col xs='7'>
                          <div className='numbers'>
                            <p className='card-category'>Users</p>
                            <CardTitle tag='h3'>{userCount}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='4' md='6'>
                  <Card className='card-stats'>
                    <CardBody>
                      <Row>
                        <Col xs='5'>
                          <div style={{ fontSize: '1.8em' }}>
                            <i className='tim-icons icon-components' />
                          </div>
                        </Col>
                        <Col xs='7'>
                          <div className='numbers'>
                            <p className='card-category'>Companies</p>
                            <CardTitle tag='h3'>{companyCount}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='4' md='6'>
                  <Card className='card-stats'>
                    <CardBody>
                      <Row>
                        <Col xs='5'>
                          <div style={{ fontSize: '1.8em' }}>
                            <i className='tim-icons icon-notes' />
                          </div>
                        </Col>
                        <Col xs='7'>
                          <div className='numbers'>
                            <p className='card-category'>Estimates</p>
                            <CardTitle tag='h3'>{estimateCount}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='4' md='6'>
                  <Card className='card-stats'>
                    <CardBody>
                      <Row>
                        <Col xs='5'>
                          <div style={{ fontSize: '1.8em' }}>
                            <i className='tim-icons icon-bullet-list-67' />
                          </div>
                        </Col>
                        <Col xs='7'>
                          <div className='numbers'>
                            <p className='card-category'>Jobs</p>
                            <CardTitle tag='h3'>{jobCount}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='4' md='6'>
                  <Card className='card-stats'>
                    <CardBody>
                      <Row>
                        <Col xs='5'>
                          <div style={{ fontSize: '1.8em' }}>
                            <i className='tim-icons icon-paper' />
                          </div>
                        </Col>
                        <Col xs='7'>
                          <div className='numbers'>
                            <p className='card-category'>Invoices</p>
                            <CardTitle tag='h3'>{invoiceCount}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : view_state === 'customers' ? (
              <div className='customer-table'>
                <ReactTable
                  data={customersData}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Customer Name',
                      accessor: 'name',
                    },
                    {
                      Header: 'Company',
                      accessor: 'company',
                    },
                    {
                      Header: 'Email',
                      accessor: 'email',
                    },
                    {
                      Header: 'Mobile No',
                      accessor: 'mobile',
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={25}
                  showPaginationTop
                  showPaginationBottom={false}
                  className='-striped -highlight'
                />
              </div>
            ) : view_state === 'users' ? (
              <div className='users-table'>
                <ReactTable
                  data={usersData}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Id',
                      accessor: 'userHumanReadableId',
                    },
                    {
                      Header: 'User Name',
                      accessor: 'name',
                    },
                    {
                      Header: 'Email',
                      accessor: 'email',
                    },
                    {
                      Header: 'Role',
                      accessor: 'role',
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={25}
                  showPaginationTop
                  showPaginationBottom={false}
                  className='-striped -highlight'
                />
              </div>
            ) : (
              <div className='users-table'>
                <ReactTable
                  data={companiesData}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Company Name',
                      accessor: 'name',
                    },
                    {
                      Header: 'Website',
                      accessor: 'website',
                    },
                    {
                      Header: 'Email',
                      accessor: 'email',
                    },
                  ]}
                  defaultPageSize={25}
                  showPaginationTop
                  showPaginationBottom={false}
                  className='-striped -highlight'
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}
