import React, { PureComponent } from 'react'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

import STRIPE_PUBLISHABLE from './constants/stripe';
import PAYMENT_SERVER_URL from './constants/server';

import { callextendedapisAwait, postapiAwait, callExtendedApiPatchAwait, insertActivityLog } from './helper';
import config from './config';

import {
    Row, Col, Input, Table, Button, Drawer,
    Form, DatePicker, Modal, Card, Icon,
    List, InputNumber, Steps, Checkbox, TimePicker, message
    , Divider, Tooltip, Select
} from 'antd';

const CURRENCY = 'USD';

const fromDollarToCent = amount => parseInt(amount * 100);

export default class Checkout extends PureComponent {
    constructor(props) {
        super(props);
    }
    markAsPaid = async () => {
        var response1 = await callExtendedApiPatchAwait('invoicescustom/markAsPaid', { id: this.props.item_id });
        response1 = response1.data;

        message.success("Successfully marked as paid.");

        insertActivityLog('invoice', this.props.item_id, "Invoice marked as paid.");

        this.props.componentDidMount();
    }

    successPayment = async (data, customerId) => {

        console.log("successPayment = async (data, customerId) => {->data: ", data);
        const dataTemp = {
            id: 'ch_1F8tirK9g8O7floSwrtcpuXX',
            object: 'charge',
            amount: 217500,
            amount_refunded: 0,
            application: null,
            application_fee: null,
            application_fee_amount: null,
            balance_transaction: 'txn_1F8tisK9g8O7floSgwiD4wAT',
            billing_details: {
                address: {
                    city: null,
                    country: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: null
                },
                email: null,
                name: 'alamrezoanul@gmail.com',
                phone: null
            },
            captured: true,
            created: 1566155245,
            currency: 'usd',
            customer: null,
            description: 'Pay Invoice.',
            destination: null,
            dispute: null,
            failure_code: null,
            failure_message: null,
            fraud_details: {},
            invoice: null,
            livemode: false,
            metadata: {},
            on_behalf_of: null,
            order: null,
            outcome: {
                network_status: 'approved_by_network',
                reason: null,
                risk_level: 'normal',
                risk_score: 64,
                seller_message: 'Payment complete.',
                type: 'authorized'
            },
            paid: true,
            payment_intent: null,
            payment_method: 'card_1F8tinK9g8O7floS6rCoOvDy',
            payment_method_details: {
                card: {
                    brand: 'discover',
                    checks: [Object],
                    country: 'US',
                    exp_month: 2,
                    exp_year: 2020,
                    fingerprint: '9r0GAhYIWxH9yTpt',
                    funding: 'credit',
                    last4: '1117',
                    three_d_secure: null,
                    wallet: null
                },
                type: 'card'
            },
            receipt_email: null,
            receipt_number: null,
            receipt_url: 'https://pay.stripe.com/receipts/acct_1DnTz2K9g8O7floS/ch_1F8tirK9g8O7floSwrtcpuXX/rcpt_FeC7mfU9pNK1OkJN3qz3WkbFopjTADH',
            refunded: false,
            refunds: {
                object: 'list',
                data: [],
                has_more: false,
                total_count: 0,
                url: '/v1/charges/ch_1F8tirK9g8O7floSwrtcpuXX/refunds'
            },
            review: null,
            shipping: null,
            source: {
                id: 'card_1F8tinK9g8O7floS6rCoOvDy',
                object: 'card',
                address_city: null,
                address_country: null,
                address_line1: null,
                address_line1_check: null,
                address_line2: null,
                address_state: null,
                address_zip: null,
                address_zip_check: null,
                brand: 'Discover',
                country: 'US',
                customer: null,
                cvc_check: null,
                dynamic_last4: null,
                exp_month: 2,
                exp_year: 2020,
                fingerprint: '9r0GAhYIWxH9yTpt',
                funding: 'credit',
                last4: '1117',
                metadata: {},
                name: 'alamrezoanul@gmail.com',
                tokenization_method: null
            },
            source_transfer: null,
            statement_descriptor: null,
            statement_descriptor_suffix: null,
            status: 'succeeded',
            transfer_data: null,
            transfer_group: null
        };

        console.log("stripe token: ", data.id);
        var response1 = await callextendedapisAwait('stripes/count', {});
        console.log("onStripeToken = (token) => {->response1.data: ", response1.data);
        const payload = {
            "userId": config.user.id,
            "customerId": customerId,
            "human_readable_id": response1.data.count + 1,
            "total": data.amount,
            "stripe_token": data.id,
            "raw": JSON.stringify(data),
        };

        console.log("successPayment = async (data, customerId) => {->payload: ", payload);

        var response2 = await postapiAwait('stripescustom/savetodb', payload);
        console.log("onStripeToken = (token) => {->response2.data: ", response2.data);

        message.success("Successfully Paid.");
        insertActivityLog('invoice', this.props.item_id, "Invoice paid. amount: " + data.amount);

        this.markAsPaid();


        message.success('Payment Successful.');
    };

    errorPayment = data => {
        message.error('Payment Error.');
    };

    onToken = (amount, description, customerId) => async token => {
        try {
            let data = {
                description,
                source: token.id,
                currency: CURRENCY,
                amount: fromDollarToCent(amount)
            };
            let response1 = await postapiAwait(`stripescustom/charge`, data);

            console.log("onToken = (amount, description, customerId) => token => {->response1: ", response1);

            this.successPayment(response1.data.success, customerId);
        }
        catch (error) {
            this.errorPayment(error);
        }
    }

    render() {
        return (
            <StripeCheckout
                name={this.props.name}
                description={this.props.description}
                amount={fromDollarToCent(this.props.amount)}
                token={this.onToken(this.props.amount, this.props.description, this.props.customerId)}
                currency={CURRENCY}
                stripeKey={STRIPE_PUBLISHABLE}
                zipCode
                allowRememberMe

                label="Pay" //Component button text
                // name="worklocker Inc." //Modal Header
                // description="Pay Invoice."
                panelLabel="Pay" //Submit button in modal
                // amount={this.state.total * 100} //Amount in cents $9.99
                // token={this.onStripeToken}
                // stripeKey={`pk_test_dv1tJa4kvFLSJvVduQUcw3d9`}
                // image="https://www.vidhub.co" //Pop-in header image
                billingAddress={false}
                email={this.props.email}
            />
        );
    }
}
