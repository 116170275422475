import React, { PureComponent, useState } from 'react';
import Select from "react-select";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import config from "./../../config";
import {
    callapi,
    callextendedapisAwait,
    postapiAwait,
    insertActivityLog,
    getapiAwait,
    patchApiAwait,
    callExtendedApiPatchAwait,
} from "./../../helper";

const { Option } = Select;

const AddEditEstimate = ({cmode,cfor}) => {
    
    const [customerSelected, setCustomerSelected] = React.useState(null);

    React.useEffect(() => {
        const _this = this;
        const self = this;

        this.setState(
        {
            loading: true,
        },
        async () => {

            var api_for = "";
            if (cfor === "estimate") {
                api_for = "estimates";
            } else if (cfor === "job") {
                api_for = "jobs";
            } else {
                api_for = "invoices";
            }

            var response1 = await getapiAwait("customers", {});
            console.log("respnose1: ", response1.data);
        });

    });

    return( 
        <div className="content">                 
            <Row>
                <Col md="8">
                    <Card>
                        <CardHeader>
                            <h5 className="title title-cap">{cmode} {cfor}</h5>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col className="pr-md-1" md="5">
                                        <FormGroup>
                                            <label>Select a Customer </label>
                                            <Select
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                value={customerSelected}
                                                onChange={(value) => setCustomerSelected(value)}
                                                options={[
                                                    {
                                                    value: "",
                                                    label: "Single Option",
                                                    isDisabled: true,
                                                    },
                                                    { value: "2", label: "Foobar" },
                                                    { value: "3", label: "Is great" },
                                                ]}
                                                placeholder="Select a Customer"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>    
                    </Card>
                </Col>
            </Row>            
        </div> 
    )
    

};
export default AddEditEstimate;