import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'

import { callextendedapisAwait } from '../../helper.js'

const UpcomingNotes = () => {
  const [note, setNote] = React.useState(null)

  async function getNote() {
    const getAllNotes = await callextendedapisAwait(
      'companyInfocustom/upcomingnotecustom/getallnotes',
      {}
    )
    const tempNotes = !getAllNotes.data.error ? getAllNotes.data : []
    console.log('sm tempNotes: ', tempNotes)
    tempNotes.forEach((noteItem) => {
      if (noteItem.name === 'estimate') setNote(noteItem.note)
    })
  }
  React.useEffect(() => {
    getNote();
  }, [])
  return (
    <div className='upcoming-note'>
      {note && note !== '' && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div dangerouslySetInnerHTML={{ __html: note }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default UpcomingNotes
