import React from 'react'
import config from './../config'
import { isEmpty } from 'lodash'

const { compose, withProps } = require('recompose')
// const FaAnchor = require("react-icons/lib/fa/anchor");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  StreetViewPanorama,
  OverlayView,
} = require('react-google-maps')

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
})

const StreetViewPanormaWithAnOverlayView = compose(
  withProps({
    // googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places",
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapAPIKey}&v=3.exp&libraries=geometry,drawing,places`,
    dataType: 'jsonp',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    // center: { lat: 49.2853171, lng: -123.1119202 },
    center: { lat: 23.781093129358634, lng: 90.41480901531578 },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  let defaultPos = props.center
  console.log('destination from line_items is: ', props.destination)
  if (!isEmpty(props.destination)) {
    defaultPos = props.destination
  }
  return (
    <GoogleMap defaultZoom={8} defaultCenter={props.center}>
      <StreetViewPanorama defaultPosition={defaultPos} visible>
        <OverlayView
          position={defaultPos}
          mapPaneName={OverlayView.OVERLAY_LAYER}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          {/* <div style={{ background: `red`, color: `white`, padding: 5, borderRadius: `50%` }}>
                        OverlayView
                </div> */}
        </OverlayView>
      </StreetViewPanorama>
    </GoogleMap>
  )
})

export default StreetViewPanormaWithAnOverlayView
