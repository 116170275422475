import axios from 'axios'
import config from './config'
import { reactLocalStorage } from 'reactjs-localstorage'

export const callapi = (url, params, key = false, full) => {
  let apiext = key
    ? key
    : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  full = full || 0
  return axios
    .get(`${config.apibase}${url}access_token=${apiext}`, {
      headers: {
        'Content-Type': 'application/json',
        params: params ? params : {},
        Authorization: `Bearer ${apiext}`,
      },
    })
    .then(function (response) {
      if (full) {
        return response
      } else {
        return response.data
      }
    })
    .catch(function (error) {
      return error
    })
}

export const delapi = (url, params, key = false, full) => {
  let apiext = key
    ? key
    : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  full = full || 0
  return axios
    .delete(`${config.apibase}${url}access_token=${apiext}`, {
      headers: {
        'Content-Type': 'application/json',
        params: params ? params : {},
      },
    })
    .then(function (response) {
      if (full) {
        return response
      } else {
        return response.data
      }
    })
    .catch(function (error) {
      return error
    })
}

export const postapi = (url, data, key = false) => {
  let apiext = key
    ? key
    : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  let urlext = apiext ? `?access_token=${apiext}` : ''
  return axios
    .post(`${config.apibase}${url}${urlext}`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(
        'export const postapi = (url, data, key = false) => {->error: ',
        error
      )
      return error
    })
}

export const getAnyApiAwait = async (url, dataLocal, keyName = 'key', key) => {
  let token = key ? key : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  // console.log("apitext: ", apiext);

  // let urlext = '';
  // if (apiext !== undefined && apiext !== 'undefiend' && apiext && apiext.length > 0) {
  //     urlext = `?access_token=${apiext}`
  // }
  var urlapps = url + `?${keyName}=${token}`

  Object.keys(dataLocal).forEach(function (key, i) {
    urlapps += '&' + key + '=' + dataLocal[key] //encodeURIComponent(dataLocal[key]);
  })
  console.log(
    'export const getapiAwait = async (path, dataLocal, key = false) => {->urlapps: ',
    urlapps
  )

  var authOptions = {
    method: 'GET',
    url: urlapps,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    json: true,
  }
  return await axios(authOptions)
}

export const getapiAwait = async (path, dataLocal, key = false) => {
  let token = key ? key : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  // console.log("apitext: ", apiext);

  // let urlext = '';
  // if (apiext !== undefined && apiext !== 'undefiend' && apiext && apiext.length > 0) {
  //     urlext = `?access_token=${apiext}`
  // }
  var urlapps = config.apibase + path + `?access_token=${token}`
  console.log(
    'export const getapiAwait = async (path, dataLocal, key = false) => {->urlapps: ',
    urlapps
  )

  Object.keys(dataLocal).forEach(function (key, i) {
    urlapps += '&' + key + '=' + encodeURIComponent(dataLocal[key])
  })

  var authOptions = {
    method: 'GET',
    url: urlapps,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    json: true,
  }

  console.log('getapiAwait->authOptions: ', authOptions)
  return await axios(authOptions)
}
export const postapiAwait = async (url, data, key = false) => {
  console.log(
    "reactLocalStorage.get('authkey'): ",
    reactLocalStorage.get('authkey')
  )
  let apiext = key
    ? key
    : reactLocalStorage.get('authkey')
      ? reactLocalStorage.get('authkey').replace(/['"]+/g, '')
      : ''
  console.log('apitext: ', apiext)

  let urlext = ''
  if (
    apiext !== undefined &&
    apiext !== 'undefiend' &&
    apiext &&
    apiext.length > 0
  ) {
    urlext = `?access_token=${apiext}`
  }

  console.log('data : ', data)

  return await axios.post(
    `${config.apibase}${url}${urlext}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiext}`,
      },
    }
  )
}
export const patchApiAwait = async (url, data, key = false) => {
  let apiext = key
    ? key
    : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  console.log('apitext: ', apiext)

  let urlext = ''
  if (
    apiext !== undefined &&
    apiext !== 'undefiend' &&
    apiext &&
    apiext.length > 0
  ) {
    urlext = `?access_token=${apiext}`
  }

  return await axios.patch(
    `${config.apibase}${url}${urlext}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
export const putApiAwait = async (url, data, key = false) => {
  let apiext = key
    ? key
    : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  console.log('apitext: ', apiext)

  let urlext = ''
  if (
    apiext !== undefined &&
    apiext !== 'undefiend' &&
    apiext &&
    apiext.length > 0
  ) {
    urlext = `?access_token=${apiext}`
  }

  return await axios.put(
    `${config.apibase}${url}${urlext}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
export const loginAwait = async (url, data) => {
  return await axios.post(`${config.apibase}${url}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
export const callextendedapisAwait = async (
  path,
  dataLocal,
  key = false,
  full
) => {
  let token = key
    ? key
    : reactLocalStorage.get('authkey') &&
    reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  console.log(
    'callapiforextendedapisAwait: function (path, dataLocal, key) {->token: ',
    token
  )

  var urlapps = config.apibase + path + `?access_token=${token}`
  Object.keys(dataLocal).forEach(function (key, i) {
    urlapps += '&' + key + '=' + encodeURIComponent(dataLocal[key])
  })

  console.log(
    'callapiforextendedapisAwait: function (path, dataLocal, key) {->urlapps: ',
    urlapps
  )

  var authOptions = {
    method: 'GET',
    url: urlapps,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    json: true,
  }
  return await axios(authOptions)
}
export const callExtendedApiPostAwait = async (
  path,
  dataLocal,
  key = false,
  full
) => {
  let token = key ? key : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  console.log(
    'callExtendedApiPatchAwait: function (path, dataLocal, key) {->token: ',
    token
  )

  var urlapps = config.apibase + path + `?access_token=${token}`
  // Object.keys(dataLocal).forEach(function (key, i) {
  //     urlapps += "&" + key + "=" + encodeURIComponent(dataLocal[key]);
  // });

  console.log(
    'callExtendedApiPatchAwait: function (path, dataLocal, key) {->urlapps: ',
    urlapps
  )

  var authOptions = {
    method: 'POST',
    url: urlapps,
    data: JSON.stringify(dataLocal),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    json: true,
  }
  return await axios(authOptions)
}
export const callExtendedApiPatchAwait = async (
  path,
  dataLocal,
  key = false,
  full
) => {
  let token = key ? key : reactLocalStorage.get('authkey') ? reactLocalStorage.get('authkey').replace(/['"]+/g, '') : "";
  console.log(
    'callExtendedApiPatchAwait: function (path, dataLocal, key) {->token: ',
    token
  )


  var urlapps = config.apibase + path

  if (token.length > 0) {
    urlapps += `?access_token=${token}`
  }
  console.log(
    'callExtendedApiPatchAwait: function (path, dataLocal, key) {->urlapps: ',
    urlapps
  )

  var authOptions = {
    method: 'PATCH',
    url: urlapps,
    data: JSON.stringify(dataLocal),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    json: true,
  }
  return await axios(authOptions)
}
export const insertActivityLog = async (type, item_id, action, key = false) => {
  let token = key ? key : reactLocalStorage.get('authkey').replace(/['"]+/g, '')
  console.log(
    'insertActivityLog: function (path, dataLocal, key) {->token: ',
    token
  )

  var urlapps =
    config.apibase + `activityfeedscustom/insert?access_token=${token}`

  const data = {
    type: type,
    item_id: item_id,
    action: action,
    userId: config.user.id,
  }
  return await axios.post(urlapps, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
// url?token=authtoken
// export const postapi = (url, data) => {
//     return axios.post(`${config.apibase}${url}`, JSON.stringify(data), {
//         header: {
//             "Bearer": "Token"
//         }
//     })
//         .then(function (response) {
//             return response.data.results;
//         })
//         .catch(function (error) {
//             return error;
//         });
// }

export const BackArrowIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"></path>
</svg>

export const SaveIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path></svg>

export const DownIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"></path></svg>

export const AddFileIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z"></path><path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"></path></svg>

export const ClockIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path></svg>

export const CopyIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path></svg>

export const EmailIcon = () => <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H6c-1.103 0-2 .897-2 2v5h2V8l6.4 4.8a1.001 1.001 0 0 0 1.2 0L20 8v9h-8v2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-7 6.75L6.666 6h12.668L13 10.75z"></path><path d="M2 12h7v2H2zm2 3h6v2H4zm3 3h4v2H7z"></path></svg>