import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'
import { callapi, postapiAwait, callextendedapisAwait } from '../../helper'
import Config from './../../config'
import { reactLocalStorage } from 'reactjs-localstorage'
import { Spinner } from 'reactstrap'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'

const Register = ({ history }) => {
  const [state, setState] = useState({})
  const [loadingSignUp, setLoadingSignUp] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)

  const [firstname, setFirstname] = useState(false)
  const [lastname, setLastname] = useState(false)
  const [userEmail, setUserEmail] = useState(false)
  const [password, setPassword] = useState(false)
  const [toast, setToast] = useState(false)
  const [toastHeader, setToastHToastHeader] = useState('')
  const [toastBody, setToastBoToastBody] = useState('')
  const [currentState, setCurrentState] = useState('login')

  useEffect(() => {
    setCurrentState('signup')

    document.body.classList.toggle('login-page')
    return function cleanup() {
      document.body.classList.toggle('login-page')
    }
  })

  function toogleToast(type, header = '', body = '') {
    setToast(type)
    setToastHToastHeader(header)
    setToastBoToastBody(body)
    setTimeout(() => {
      setToast(false)
    }, 4000)
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (currentState == 'signup') {
      if (!firstname) {
        toogleToast(true, 'Error', 'Enter First Name')
      } else if (!lastname) {
        toogleToast(true, 'Error', 'Enter Last Name')
      } else if (!password) {
        toogleToast(true, 'Error', 'Enter Password')
      } else {
        setLoadingSignUp(true)

        let postData = {
          role: 'admin',
          first_name: firstname,
          last_name: lastname,
          email: userEmail,
          password: password,
          companyId: -1,
          human_readable_id: 0,
        }

        console.log(postData)
        let emailIsValid = validateEmail(postData.email)
        if (!emailIsValid) {
          toogleToast(true, 'Error', 'Enter valid Email')
          setLoadingSignUp(false)
        } else {
          let data = null

          try {
            // var response1 = await callextendedapisAwait('users/count', {})
            // console.log(
            //   'Register->handleSubmit = (e) => {->response1.data: ',
            //   response1.data
            // )
            postData.human_readable_id = -1

            console.log('handleSubmit->postData: ', postData)
            data = await postapiAwait('users/sign-up', postData)
          } catch (error) {
            console.log('error is: ', error)

            // if (error.code == 'ER_DUP_ENTRY:') {
            //   toogleToast(true, 'Error', 'Email already exist');
            // }
            // else {
            toogleToast(
              true,
              'Error',
              'There was an error - please contact support'
            )
            // }
            setLoadingSignUp(false)
          }
          if (data == null) {
            console.log('data is null')
            return
          }
          data = data.data
          console.log('responseData: ', data)

          if (data.status === false && data.msg.includes('uniqueEmail')) {
            // alert(data.msg);
            // message.error(data.msg);
            toogleToast(true, 'Error', 'Email already exist')
            setLoadingSignUp(false)
          } else {
            console.log('data', data)
            //alert(data);
            // data.id = data.token;
            reactLocalStorage.setObject('authkey', data.id)

            const getUser = callapi(
              'users/' + data.userId + '?',
              { ttl: 315360000000 },
              data.id
            )

            getUser.then((user) => {
              console.log('user: ', user)
              reactLocalStorage.setObject('user', user)
              toogleToast(true, 'Success', 'Register Successfull')
              // if (user.role === "admin") {
              //   history.push("/admin/dashboard");
              // } else {
              //   history.push("/user/dashboard");
              // }

              history.push('/auth/newcompanysetup?uid=' + user.id)
            })
          }
        }
      }
    }

    // setCurrentState('login')
  }

  async function handleSubmitLogin(e) {
    history.push('/auth/login')
  }

  return (
    <>
      <Toast
        isOpen={toast}
        style={{
          position: 'absolute',
          right: 12,
        }}
      >
        <div
          className='p-3 my-2 rounded ml-auto'
          style={{ backgroundColor: 'white', width: 300 }}
        >
          <ToastHeader>{toastHeader}</ToastHeader>
          <ToastBody>{toastBody}</ToastBody>
        </div>
      </Toast>
      <div className={`content`}>
        <Container>
          <Col
            className='ml-auto mr-auto'
            lg='10'
            md='10'
            style={{ backgroundColor: 'white', borderRadius: 30 }}
          >
            <Row>
              <Col
                className='ml-auto mr-auto'
                lg='6'
                md='6'
                style={{
                  backgroundColor: '#ff6c02',
                  borderRadius: '30px 0px 0px 30px',
                  padding: 20,
                  textAlign: 'center',
                  minHeight: 450,
                }}
              >
                <img
                  className='d-flex ml-2 mt-3'
                  alt='worklocker-logo'
                  src={require('assets/img/worklocker.png').default}
                />
                <div className='' style={{ marginTop: 50 }}>
                  <h4>Welcome</h4>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      fontSize: 40,
                      color: '#fff',
                      marginBottom: 10,
                      marginTop: 30,
                    }}
                  >
                    Let's get started
                  </h2>
                  <img
                    className='d-flex m-auto'
                    alt='worklocker-logo'
                    src={
                      require('assets/img/horizontal-rule-white.png').default
                    }
                    style={{ width: 70, height: 5 }}
                  />
                  <div style={{ marginTop: 60 }}>
                    <p>No credit card required!</p>
                    <p>Create a free account to get started.</p>
                    <p>Upgrade anytime or add add-ons.</p>
                  </div>
                </div>
              </Col>
              <Col
                className='ml-auto mr-auto'
                lg='6'
                md='6'
                style={{
                  padding: 30,
                  textAlign: 'center',
                }}
              >
                <div style={{ marginTop: 70 }}>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      color: '#686868',
                      marginBottom: 10,
                    }}
                  >
                    {currentState == 'login'
                      ? 'Sign in to Account'
                      : 'Crete an Account'}
                  </h2>
                  <div className='mb-5'>
                    <img
                      className='d-flex m-auto'
                      alt='worklocker-logo'
                      src={require('assets/img/horizontal-rule.png').default}
                      style={{ width: 70, height: 5 }}
                    />
                  </div>

                  {currentState == 'signup' && (
                    <div>
                      <InputGroup
                        className={classnames({
                          'input-group-focus': state.firstnameFocus,
                        })}
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                            }}
                          >
                            <i className='tim-icons icon-email-85' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder='First Name'
                          type='text'
                          onFocus={(e) =>
                            setState({ ...state, firstnameFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, firstnameFocus: false })
                          }
                          style={{
                            border: 0,
                            borderBottom: '1px solid gray',
                            borderRadius: 0,
                            borderColor: '1px solid #d0d0d0',
                            color: 'black',
                          }}
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          'input-group-focus': state.lastnameFocus,
                        })}
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                            }}
                          >
                            <i className='tim-icons icon-email-85' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder='Last Name'
                          type='text'
                          onFocus={(e) =>
                            setState({ ...state, lastnameFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, lastnameFocus: false })
                          }
                          style={{
                            border: 0,
                            borderBottom: '1px solid gray',
                            borderRadius: 0,
                            borderColor: '1px solid #d0d0d0',
                            color: 'black',
                          }}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          'input-group-focus': state.emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                            }}
                          >
                            <i className='tim-icons icon-email-85' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder='Email Address'
                          type='text'
                          onFocus={(e) =>
                            setState({ ...state, emailFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, emailFocus: false })
                          }
                          style={{
                            border: 0,
                            borderBottom: '1px solid gray',
                            borderRadius: 0,
                            borderColor: '1px solid #d0d0d0',
                            color: 'black',
                          }}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          'input-group-focus': state.passFocus,
                        })}
                        style={{ marginTop: 20 }}
                      >
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                            }}
                          >
                            <i className='tim-icons icon-lock-circle' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder='Password'
                          type='password'
                          onFocus={(e) =>
                            setState({ ...state, passFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, passFocus: false })
                          }
                          style={{
                            border: 0,
                            borderBottom: '1px solid gray',
                            borderRadius: 0,
                            borderColor: '1px solid #d0d0d0',
                            color: 'black',
                          }}
                        />
                      </InputGroup>
                    </div>
                  )}

                  <button
                    className='mb-2'
                    onClick={(e) => handleSubmit(e)}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      marginTop: 20,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    {loadingSignUp && (
                      <Spinner color='white' size='sm' className='mr-2' />
                    )}
                    Register
                  </button>
                  <button
                    className='mb-3'
                    onClick={(e) => handleSubmitLogin(e)}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    Login Instead
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </>
  )
}

export default Register
