import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import ReactTable from 'components/ReactTable/ReactTable.js'

import { callapi, callextendedapisAwait, delapi } from '../../helper.js'
import config from '../../config.js'
import { Spinner } from 'reactstrap'
import CreateCustomer from './CreateCustomer'

import { func } from 'prop-types'
import { reactLocalStorage } from 'reactjs-localstorage'

import NotificationAlert from 'react-notification-alert'
import UpcomingNotes from 'views/components/UpcomingNotes.js'

export default function Customers() {
  const [loading, setLoading] = useState(false)
  const [customerData, setCustomerData] = useState([])
  const [data, setData] = React.useState([])
  const [customer_id, setCustomer_id] = React.useState(null)
  const [view_state, setView_state] = React.useState('datatable')
  const [modal, setModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const notificationAlertRef = React.useRef(null)

  function redirectToParentCustomer() {
    const parentCustomer = reactLocalStorage.getObject('parentCustomer')
    console.log('parentCustomer: ', parentCustomer)
    if (parentCustomer && parentCustomer.length && parentCustomer.length > 0) {
      setCustomer_id(parentCustomer)
      setView_state('edit_customer')
      reactLocalStorage.setObject('parentCustomer', '')
    }
  }

  async function fetchCustomers() {
    setLoading(true)
    const user = reactLocalStorage.getObject('user')
    const getcustomers = await callextendedapisAwait(
      'customers/allCustomersByCompany',
      { companyId: user.companyId }
    )

    console.log(
      'async getAllEstimates() {->dataTableDataArr: ',
      getcustomers.data
    )
    setLoading(false)
    setCustomerData(getcustomers.data)
  }
  useEffect(() => {
    fetchCustomers()
  }, [])
  useEffect(() => {
    const data = customerData.map((prop, key) => {
      // console.log('customer data: ', prop)
      // console.log('customer key: ', key)
      return {
        _id: key,
        id: prop.id,
        name: `${prop.first_name} ${prop.last_name}`,
        email: prop.primary_email,
        company: prop.company,
        homePhone: prop.home_phone,
        // customerHumanReadableId: prop.human_readable_id,
        actions: (
          <div className='actions-right'>
            <Button
              data-id={prop.id}
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                editCustomer(obj.id)
              }}
              color='warning'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
            >
              <i className='tim-icons icon-pencil' />
            </Button>{' '}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o._id === key)
                setDeleteId(obj.id)
                setModal(!modal)
                console.log('data is deleted id', obj.id)
              }}
              color='danger'
              size='sm'
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 5,
              })}
            >
              <i className='tim-icons icon-simple-remove' />
            </Button>{' '}
          </div>
        ),
      }
    })
    setData(data)
    redirectToParentCustomer()
  }, [customerData])

  // useEffect(() => {
  //   console.log('this message shown from useEffect')
  //   let testSomeElement = document.querySelector(
  //     'div.content > .row > .col-12.col-md-12 > .card > .card-body > .ReactTable > .pagination-top > div > .flex-nowrap > div.react-select:first-child  input'
  //   )
  //   console.log('her is the element', testSomeElement)
  //   testSomeElement.addEventListener('change', (e) => {
  //     console.log(e.target.value)
  //   })
  // }, [])

  function editCustomer(customer_id) {
    setCustomer_id(customer_id)
    setView_state('edit_customer')
  }

  const toggle = () => setModal(!modal)

  function setMousePointer(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    } else {
      targetRow = e.target.parentElement.parentElement.parentElement
    }
    if (targetRow && !targetRow.style.cursor) {
      targetRow.style.cursor = 'pointer'
      targetRow.style.userSelect = 'none'
    }
  }

  function prepareForEdit(e) {
    let targetRow = null
    if (e.target.tagName === 'TR') {
      targetRow = e.target
    } else if (e.target.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement
    } else if (e.target.parentElement.parentElement.tagName === 'TR') {
      targetRow = e.target.parentElement.parentElement
    }

    let customerId =
      targetRow.lastChild.firstChild.firstChild.getAttribute('data-id')
    console.log(customerId)
    editCustomer(customerId)
  }
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    notificationAlertRef.current.notificationAlert(options)
  }

  function onOk() {
    setModal(!modal)
    var newdata = customerData

    console.log('delete id', deleteId)

    // call appi to delete plan
    const delEstimate = delapi('customers/' + deleteId + '?')

    // promise after called api and check for the api status
    delEstimate.then((myData) => {
      if (myData.count === '0') {
        showNotificationMsg('Sorry! Cannot delete the customer.', 'warning')
      } else {
        showNotificationMsg('Customer has been deleted.', 'success')
        var newdata = data
        newdata.find((o, i) => {
          if (o.id === deleteId) {
            newdata.splice(i, 1)
            setDeleteId(null)
            return true
          }
          return false
        })
        console.log('data is n', data)
        console.log('dataNew is n', newdata)
        setData(newdata)
      }
    })
  }

  if (loading) {
    return (
      <>
        <div
          className='content'
          style={{ textAlign: 'center', transition: 'none' }}
        >
          <Spinner color='white' size='sm' className='mr-2' />
        </div>
      </>
    )
  } else {
    if (view_state == 'datatable') {
      return (
        <>
          <div className='rna-container'>
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <div>
            <Modal
              isOpen={modal}
              fade={false}
              toggle={toggle}
              className={classNames}
            >
              <ModalHeader toggle={toggle}>
                <i className='tim-icons icon-alert-circle-exc' /> Do you want to
                delete this customer?
              </ModalHeader>
              <ModalBody>
                You cannot retrieve the customer information once you deleted.
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={onOk}>
                  Ok
                </Button>{' '}
                <Button
                  color='secondary'
                  onClick={() => {
                    setDeleteId(null)
                    setModal(!modal)
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div
            className='content customer'
            onClick={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  prepareForEdit(e)
                }
              }
            }}
            onMouseOver={(e) => {
              if (
                e.target.tagName === 'TR' ||
                e.target.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.tagName === 'TR' ||
                e.target.parentElement.parentElement.parentElement.tagName ===
                  'TR'
              ) {
                if (
                  e.target.tagName === 'TBODY' ||
                  e.target.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.tagName === 'TBODY' ||
                  e.target.parentElement.parentElement.parentElement.tagName ===
                    'TBODY'
                ) {
                  setMousePointer(e)
                }
              }
            }}
          >
            <Row>
              {/* <Col>{console.log('Data------', data)}</Col> */}
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader style={{ textAlign: 'right' }}>
                    {/* <CardTitle tag="h4">React Table</CardTitle> */}
                    <Button
                      color='primary'
                      onClick={() => setView_state('create_customer')}
                    >
                      + Add New
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div className='customer-table'>
                      <ReactTable
                        data={data}
                        filterable
                        resizable={false}
                        columns={[
                          // {
                          //     Header: "Id",
                          //     accessor: "customerHumanReadableId",
                          // },
                          {
                            Header: 'Customer Name',
                            accessor: 'name',
                          },
                          {
                            Header: 'Company',
                            accessor: 'company',
                          },
                          {
                            Header: 'Home Phone',
                            accessor: 'homePhone',
                          },
                          {
                            Header: 'Primary Email',
                            accessor: 'email',
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!loading && <UpcomingNotes />}
          </div>
        </>
      )
    } else if (view_state == 'edit_customer') {
      return (
        <div className='content'>
          <CreateCustomer
            mode='edit'
            for='customer'
            item_id={customer_id}
            setView_state={setView_state}
            customers={data}
            fetchCustomers={fetchCustomers}
          />
        </div>
      )
    } else if (view_state == 'create_customer') {
      return (
        <div className='content'>
          <CreateCustomer
            mode='new'
            for='customer'
            setView_state={setView_state}
            fetchCustomers={fetchCustomers}
          />
        </div>
      )
    }
  }
}
