import { reactLocalStorage } from 'reactjs-localstorage'

//reactLocalStorage.clear()
console.log('process.env.REACT_APP_NODE_ENV:', process.env.REACT_APP_NODE_ENV)
const config = {
  apibase:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'https://app.worklocker.net/api/'
      : process.env.REACT_APP_NODE_ENV === 'staging'
      ? 'https://api.demo12.worklocker.net/api/'
      : 'http://localhost:5000/api/',
  hasSidebar: true,
  user: reactLocalStorage.get('user') ? JSON.parse(reactLocalStorage.get('user')) : false,
  isLoggedin: reactLocalStorage.get('user') ? true : false,
  siteName: 'worklocker',
  description: 'worklocker',
  keywords: 'worklocker',
  sitelogo: 'assets/img/worklocker.png',
  copyright: '© Copyright worklocker ' + new Date().getFullYear(),
  mbxtoken: 'pk.eyJ1IjoidGFyZXFheml6MDA2NSIsImEiOiJjamNvbjQ3cnAyNXgyMzNybnlmN3p5NGFkIn0.zbs39bVfUf9ztz3AxnNTDg',
  // googleMapAPIKey: 'AIzaSyCx93o91j9Zhug_FoM4ytGgXidDAH2W4v8',
  googleMapAPIKey: 'AIzaSyB_XgrCVA-ys3uNzvfsuutiWAmiBJgMGnk',
  stripe_publishable_key: `pk_test_51J2JoeDrGhUhOQG1VwyKknrnDVbfghnZG3VkfXY7cHFjdLYA8B7UOZCXmgq1RuSWLBRX8MWaRyPrJhECd7ztq9ml00R18Z9dRh`,
  // stripe_publishable_key: `pk_test_51J2C4UIH18C69eBXQrerVpYsRM8HoLSmRL6VDeEA1nochPi3wnLaE9NHFOqgZmBVtMTvG84orhNNcRj1DfmkCLwx00lSI90Mhv`,//sujan
  
}

console.log(config.user)

export default config
