import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'
import { reactLocalStorage } from 'reactjs-localstorage'
import { Spinner } from 'reactstrap'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'
import { callExtendedApiPatchAwait } from 'helper'

const ResetPassword = ({ history }) => {
  const [state, setState] = useState({})

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [toast, setToast] = useState(false)
  const [toastHeader, setToastHToastHeader] = useState('')
  const [toastBody, setToastBoToastBody] = useState('')
  const [currentState, setCurrentState] = useState('reset-password')

  useEffect(() => {
    setCurrentState('reset-password')

    document.body.classList.toggle('login-page')
    return function cleanup() {
      document.body.classList.toggle('login-page')
    }
  })

  function toogleToast(type, header = '', body = '') {
    setToast(type)
    setToastHToastHeader(header)
    setToastBoToastBody(body)
    setTimeout(() => {
      setToast(false)
    }, 5000)
  }

  const handleResetPasswordClick = async () => {
    if (password.length < 1 || confirmPassword.length < 1) {
      toogleToast(
        'error',
        'Error Occured',
        `Password and Confirm Password are required fields.`
      )
      return
    }
    if (password !== confirmPassword) {
      toogleToast(
        'error',
        'Error Occured',
        `Password and Confirm Password must match.`
      )
      return
    }
    // console.log("inputted email: ", email);

    const urlparams = new URLSearchParams(window.location.search)
    const response = await callExtendedApiPatchAwait(
      'userscustom/resetpassword',
      {
        resetCode: urlparams.get('reset-code'),
        password: password,
      }
    )

    if (response.data.status) {
      // toogleToast('success', 'Success', response.data.msg)
      toogleToast(
        'success',
        'Success',
        'Password updated. You will be automatically redirected to the login page.'
      )

      setTimeout(() => {
        history.push('/auth/login')
      }, 5000)
    }
  }
  return (
    <>
      <Toast
        isOpen={toast}
        style={{
          position: 'absolute',
          right: 12,
        }}
      >
        <div
          className='p-3 my-2 rounded ml-auto'
          style={{ backgroundColor: 'white', width: 300 }}
        >
          <ToastHeader>{toastHeader}</ToastHeader>
          <ToastBody>{toastBody}</ToastBody>
        </div>
      </Toast>
      <div className={`content`}>
        <Container>
          <Col
            className='ml-auto mr-auto'
            lg='10'
            md='10'
            style={{ backgroundColor: 'white', borderRadius: 30 }}
          >
            <Row>
              <Col
                className='ml-auto mr-auto'
                lg='6'
                md='6'
                style={{
                  backgroundColor: '#ff6c02',
                  borderRadius: '30px 0px 0px 30px',
                  padding: 20,
                  textAlign: 'center',
                  minHeight: 450,
                }}
              >
                <img
                  className='d-flex ml-2 mt-3'
                  alt='worklocker-logo'
                  src={require('assets/img/worklocker.png').default}
                  style={{ height: '35px' }}
                />
                <div className='' style={{ marginTop: 50 }}>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      fontSize: 40,
                      color: '#fff',
                      marginBottom: 10,
                      marginTop: 30,
                    }}
                  >
                    Reset Password
                  </h2>
                  <img
                    className='d-flex m-auto'
                    alt='worklocker-logo'
                    src={
                      require('assets/img/horizontal-rule-white.png').default
                    }
                    style={{ width: 70, height: 5 }}
                  />
                </div>
              </Col>
              <Col
                className='ml-auto mr-auto'
                lg='6'
                md='6'
                style={{
                  padding: 30,
                  textAlign: 'center',
                }}
              >
                <div style={{ marginTop: 70 }}>
                  <h2
                    style={{
                      fontWeight: 'bold',
                      color: '#686868',
                      marginBottom: 10,
                    }}
                  >
                    Enter New Password
                  </h2>
                  <div className='mb-5'>
                    <img
                      className='d-flex m-auto'
                      alt='worklocker-logo'
                      src={require('assets/img/horizontal-rule.png').default}
                      style={{ width: 70, height: 5 }}
                    />
                  </div>
                  {currentState == 'reset-password' && (
                    <>
                      <div>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': state.emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText
                              style={{
                                border: 0,
                                borderBottom: '1px solid gray',
                                borderRadius: 0,
                                borderColor: '1px solid #d0d0d0',
                              }}
                            >
                              <i className='tim-icons icon-lock-circle' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Password'
                            type='password'
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                              color: 'black',
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': state.emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText
                              style={{
                                border: 0,
                                borderBottom: '1px solid gray',
                                borderRadius: 0,
                                borderColor: '1px solid #d0d0d0',
                              }}
                            >
                              <i className='tim-icons icon-lock-circle' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Confirm Password'
                            type='password'
                            style={{
                              border: 0,
                              borderBottom: '1px solid gray',
                              borderRadius: 0,
                              borderColor: '1px solid #d0d0d0',
                              color: 'black',
                            }}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </>
                  )}

                  <button
                    className='mb-2'
                    onClick={(e) => {
                      e.preventDefault()
                      handleResetPasswordClick()
                    }}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      marginTop: 20,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    Reset Password
                  </button>
                  <p>
                    <small>or</small>
                  </p>
                  <button
                    className='mb-2'
                    onClick={(e) => {
                      history.push('/auth/login')
                    }}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      marginTop: 20,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    Login
                  </button>
                  <button
                    className='mb-3'
                    onClick={(e) => {
                      history.push('/auth/register')
                    }}
                    size='lg'
                    style={{
                      backgroundColor: '#ff6c02',
                      width: '80%',
                      border: 0,
                      padding: 10,
                      color: 'white',
                      borderRadius: 30,
                    }}
                  >
                    Signup
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </>
  )
}

export default ResetPassword
