import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
} from 'reactstrap'
import Select from 'react-select'
import ReactTable from 'components/ReactTable/ReactTable.js'

import PlacesAutocomplete from 'react-places-autocomplete'

import {
  callapi,
  postapi,
  callextendedapisAwait,
  patchApiAwait,
  insertActivityLog,
  postapiAwait,
} from '../../helper.js'
import config from '../../config.js'
import { Spinner } from 'reactstrap'
import LineItemsForm from './LineItems.js'
import { func } from 'prop-types'
import { isConstructorDeclaration } from 'typescript'
import NotificationAlert from 'react-notification-alert'
import { reactLocalStorage } from 'reactjs-localstorage'
import UpcomingNotes from 'views/components/UpcomingNotes.js'

export default function Profile(props) {
  const [loading, setLoading] = useState(false)
  const [fname, setFname] = useState(
    props.userForEdit ? props.userForEdit.first_name : null
  )
  const [lname, setLname] = useState(
    props.userForEdit ? props.userForEdit.last_name : null
  )
  const [email, setEmail] = useState(
    props.userForEdit ? props.userForEdit.email : null
  )
  const [password, setPassword] = useState(null)
  const [role, setRole] = useState(
    props.userForEdit ? props.userForEdit.role : null
  )
  const [address, setAddress] = useState(
    'Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09'
  )

  const [isFnameFocused, setIsFnameFocused] = useState(false)

  const notificationAlertRef = React.useRef(null)
  const fnameInputRef = React.useRef(null)

  useEffect(async () => {
    console.log('isnameautofocused ', isFnameFocused)

    const user = reactLocalStorage.getObject('user')
    const response = await callextendedapisAwait('users/me', {})

    console.log('response.data: ', response.data)

    setFname(response.data.first_name)
    setLname(response.data.last_name)
    setEmail(response.data.email)
    setRole(response.data.role)
    setAddress(response.data.address)
  }, [isFnameFocused])

  async function onSaveUser() {
    // event.preventDefault();
    if (props.userForEdit) {
    }
    if (!fname) {
      showNotificationMsg('Please enter the First name!', 'warning')
      setIsFnameFocused(!isFnameFocused)
    } else if (!lname) {
      showNotificationMsg('Please enter the Last name!', 'warning')
    } else if (!email) {
      showNotificationMsg('Please enter the email!', 'warning')
    } else if (!password) {
      showNotificationMsg('Please enter the password!', 'warning')
    } else if (!role) {
      showNotificationMsg('Please select the role!', 'warning')
    } else if (!address) {
      showNotificationMsg('Please enter the address!', 'warning')
    } else if (password.length < 8) {
      showNotificationMsg('Password should be 8 characters long', 'warning')
    } else {
      console.log(
        'all input data is here ',
        fname,
        lname,
        email,
        password,
        role,
        address
      )

      const user = reactLocalStorage.getObject('user')

      console.log('edit User-> postdata: address ', address)
      console.log('user id ', user.id)
      var postdata = {
        first_name: fname,
        last_name: lname,
        email: email,
        role: role,
        address: address,
        password: password,
        emailVerified: true,
      }

      console.log('edit User-> postdata: ', postdata)

      let response1 = await patchApiAwait(
        `userscustom/${user.id}/replace`,
        postdata
      )

      showNotificationMsg('Successfully updated profile.', 'success')
      insertActivityLog('user', user.id, 'Profile Updated.')

      console.log('edit User-> response1: ', response1)
      // props.setUpdateStatus('updated')
      // props.setView_state('datatable')
      // props.fetchUsers()
    }
  }
  const handleonChangeforRole = (selectedOptions) => {
    setRole(selectedOptions.value)
    // console.log('Role value is here---', selectedOptions)
  }
  const showNotificationMsg = (notifyMessage, type) => {
    let options = {}
    options = {
      place: 'tr',
      message: notifyMessage,
      type: type,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    }
    if (
      notificationAlertRef !== null &&
      notificationAlertRef.current !== null
    ) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }
  const options = [
    { value: 'admin', label: 'Admin' },
    { value: 'employee', label: 'Employee' },
  ]
  return (
    <div className='content'>
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Row>
        <Col>
          <Card>
            <Container>
              <CardHeader style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <div>
                  <span>
                    <h4
                      className='title'
                      style={{
                        marginTop: 30,
                        marginBottom: 0,
                      }}
                    >
                      Edit Profile
                    </h4>
                  </span>
                  {/* <Button
                  id='ant-btn-back'
                  type='button'
                  className={`ant-btn-primary ant-btn-back`}
                  icon='back'
                  size='default'
                  style={{ marginLeft: 'calc(100% - 130px)' }}
                  onClick={(e) => {
                    props.setView_state('datatable')
                    // props.fetchCustomers()
                  }}
                >
                  <span>Back</span>
                </Button> */}
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>
                          First Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          onChange={(e) => setFname(e.target.value)}
                          placeholder='First Name'
                          defaultValue={fname}
                          type='text'
                          ref={fnameInputRef}
                          autoFocus={isFnameFocused}
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md='6'>
                      <FormGroup>
                        <label>
                          Last Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          required
                          onChange={(e) => setLname(e.target.value)}
                          placeholder='Last Name'
                          defaultValue={lname}
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>
                          Email address <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          readOnly
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder='mike@email.com'
                          defaultValue={email}
                          type='email'
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md='6'>
                      <FormGroup>
                        <label>
                          Password <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Input
                          onChange={(e) => setPassword(e.target.value)}
                          type='password'
                          placeholder='********'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <Label for='exampleSelect'>
                          Select a Role <span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Select
                          value={options.filter(function (option) {
                            return option.value === role
                          })}
                          onChange={handleonChangeforRole}
                          options={options}
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md='6'>
                      <FormGroup>
                        <label>
                          Address <span style={{ color: 'red' }}>*</span>
                        </label>
                        {/* <Input
                        onChange={(e) => setAddress(e.target.value)}
                        defaultValue='Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09'
                        value={address}
                        placeholder='Home Address'
                        type='text'
                      /> */}
                        <PlacesAutocomplete
                          value={address}
                          onChange={(addr) => setAddress(addr)}
                          onSelect={(addr) => setAddress(addr)}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <Input
                                {...getInputProps({
                                  placeholder: 'Address',
                                  className: 'location-search-input1',
                                })}
                              />
                              <div className='autocomplete-dropdown-container1'>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item'
                                  return (
                                    <div
                                      className='input-suggestion'
                                      {...getSuggestionItemProps(
                                        suggestion,
                                        {}
                                      )}
                                    >
                                      <i class='fas fa-map-marker-alt'></i>{' '}
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter style={{ textAlign: 'center' }}>
                <Button
                  className='btn-fill'
                  color='primary'
                  type='submit'
                  onClick={(e) => {
                    onSaveUser()
                  }}
                >
                  Save
                </Button>
              </CardFooter>
            </Container>
          </Card>
        </Col>
      </Row>
      {!loading && <UpcomingNotes />}
    </div>
  )
}
